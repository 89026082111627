import React, { useState, useEffect, useCallback } from 'react';
import {
  Modal,
  Row,
  Col,
  Container,
  Button,
  CardBody,
  Card,
  CardHeader,
  CardImg,
  CardText,
  CardTitle,
  Badge,
} from 'reactstrap';
import TagManager from 'react-gtm-module';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setCustomOverlay } from '../../_actions/effect.action';
import CartItem from 'components/cart/CartItem';
import { ORDER_USER, USER_LOGIN, PAYMENT, SELECTED_TIME } from '_constants';
import { Input, message, Popconfirm, Spin, Image } from 'antd';
import {
  GET_VOUCHERS,
  GET_ORDERS,
  GET_PROMO_CODE,
  GET_VENUES,
  GET_PAYMENT_INTENT_STATUS,
} from '../../api/APIs';
import PerfectScrollbar from 'react-perfect-scrollbar';
import StepWizard from 'react-step-wizard';
import moment from 'moment';
import {
  setOrderUserItems,
  setProcessingPayment,
  setShowPopupTime,
} from '../../_actions/global.action';
import CreditStripeCheckoutForm from '../order/CreditStripeCheckoutForm';
import axios from 'axios';
import { GET_PAYMENT_INTENT } from 'api/APIs';
import FXBankStripeCheckoutForm from '../order/FXBankStripeCheckoutForm';
import { useStripe } from '@stripe/react-stripe-js';
import { readAndParseStorage, showError } from '../../_helper/index';
import { COMMON_ERROR_MESSAGE } from '../../_constants/index';

let timeoutCall;
let timeoutInstruction;

const PAYMENT_TYPE = {
  socialCredit: 'credits',
  creditCardStripe: 'creditCardStripe',
  FPXStripe: 'FPXStripe',
};

const tagManagerArgs = {
  dataLayer: {
    page: 'Home',
  },
  dataLayerName: 'PageDataLayer',
};

const ReviewOrder = () => {
  TagManager.dataLayer(tagManagerArgs);
  const [orderUser, setOrderUser] = useState(null);
  const [userLogin, setUserLogin] = useState(null);
  const [cutleries, setCutleries] = useState(false);
  const [promoCodeModal, setPromoCodeModal] = useState(false);
  const [loadingPay, setLoadingPay] = useState(false);
  const [paymentModal, setPaymentModal] = useState(false);
  const [instruction, setInstruction] = useState('');
  const [valuePromoCode, setValuePromoCode] = useState('');
  const [donate, setDonate] = useState(null);
  const [payment, setPayment] = useState(null);
  const [listVoucher, setListVoucher] = useState([]);
  const [detailVoucer, setDetailVoucer] = useState(null);
  const [creditCardStripeModal, setCreditCardStripeModal] = useState(false);
  const [PFXStripeModal, setPFXStripeModal] = useState(false);
  const [intent, setIntent] = useState(null);
  const [apiHeader, setApiHeader] = useState(null);

  const globalSetting = useSelector((state) => state.global.globalSetting);
  const processingPayment = useSelector(
    (state) => state.global.processingPayment,
  );

  const orderUserItems = useSelector((state) => state.global.orderUserItems);

  const selectedRestaurant = useSelector(
    (state) => state.global.selectedRestaurant,
  );

  const selectedAddress = useSelector((state) => state.global.selectedAddress);

  const selectedVenue = useSelector((state) => state.global.selectedVenue);

  const selectedTime = useSelector((state) => state.global.selectedTime);

  const dispatch = useDispatch();
  const history = useHistory();
  const stripe = useStripe();

  const parseQuerystring = useCallback(() => {
    var href = window.location.href.split('?')[1]
      ? window.location.href.split('?')[1].split('#')[0].split('&')
      : [];
    var dict = {};
    var elem = [];
    for (var i = href.length - 1; i >= 0; i--) {
      elem = href[i].split('=');
      dict[elem[0]] = elem[1];
    }
    return dict;
  }, []);

  const onStripeSuccessfulCheckout = useCallback(() => {
    let odUser = readAndParseStorage(ORDER_USER);
    if (odUser) {
      axios(
        GET_PAYMENT_INTENT_STATUS +
          odUser.id +
          '&received_intent_status=succeeded',
        {
          method: 'get',
          headers: apiHeader,
        },
      )
        .then((response) => {
          // console.log(response);
          localStorage.removeItem(ORDER_USER);
          localStorage.removeItem(SELECTED_TIME);
          dispatch(setOrderUserItems([]));
          dispatch(setProcessingPayment(false));
          message.destroy();
          message.success('Pay Success, Thank you & Enjoy your food', 3000);
          setLoadingPay(false);
          setCreditCardStripeModal(false);
          setPFXStripeModal(false);
          setTimeout(() => {
            window.location.href = '/';
          }, 10000);
        })
        .catch((err) => {
          // console.log(err)
          dispatch(setProcessingPayment(false));
          if (err.response?.data?.error_message) {
            message.error(
              err.response?.data?.error_message
            );
          } else {
            message.error(
              'Sorry, Can;t not checkout, please contact us or try again',
            );
          }
        });
    }
  }, [dispatch, apiHeader]);

  const checkStripeStatus = useCallback(() => {
    const param = parseQuerystring();
    if (stripe && param && param.payment_intent_client_secret) {
      stripe
        .retrievePaymentIntent(param.payment_intent_client_secret)
        .then(function (response) {
          if (
            response.paymentIntent &&
            response.paymentIntent.status === 'succeeded'
          ) {
            onStripeSuccessfulCheckout();
          } else {
            if (
              response.paymentIntent &&
              response.paymentIntent.status !== 'canceled'
            ) {
              message.destroy();
              message.error(
                response.paymentIntent &&
                  response.paymentIntent.last_payment_error &&
                  response.paymentIntent.last_payment_error
                  ? response.paymentIntent.last_payment_error.message
                  : 'There is an error, please try again',
                5,
              );
            }
            // console.log(response.paymentIntent)
            // Handle unsuccessful, processing, or canceled payments and API errors here
          }
        });
    }
  }, [onStripeSuccessfulCheckout, parseQuerystring, stripe]);

  useEffect(() => {
    // console.log("render")
    checkStripeStatus();
  }, [checkStripeStatus]);

  useEffect(() => {
    let odUser = readAndParseStorage(ORDER_USER);
    let pay = localStorage.getItem(PAYMENT);
    let userLog = readAndParseStorage(USER_LOGIN);

    if (odUser) {
      setDonate(odUser.donate);
      setOrderUser(odUser);
      setPayment(pay);
      setInstruction(odUser.special_instructions);
      if (odUser.request_cutleries === 'yes') {
        setCutleries(true);
      }
      if (PAYMENT_TYPE.socialCredit === pay) {
        if (parseInt(odUser.total) > parseInt(userLog.credits_balance)) {
          setPayment(null);
        } else {
          setPayment(pay);
        }
      } else {
        setPayment(pay);
      }
    }
    setUserLogin(userLog);
    setApiHeader({
      'Content-Type': 'application/json',
      'X-Customer-Id': userLog ? userLog.id : '',
      'X-Customer-Api-Key': userLog ? userLog.api_key : '',
      'X-Venue-Id': selectedVenue && selectedVenue.id,
      'X-Brand-Id': selectedRestaurant && selectedRestaurant.id,
      'X-Address-Id': selectedAddress && selectedAddress.id,
    });
  }, [orderUserItems, selectedVenue, selectedRestaurant, selectedAddress]);

  const changeInstruction = (e) => {
    let valueInput = e.target.value;
    setInstruction(valueInput);
    clearTimeout(timeoutInstruction);
    if (orderUser) {
      timeoutInstruction = setTimeout(() => {
        fetch(GET_ORDERS + orderUser.id, {
          method: 'put',
          headers: {
            'Content-Type': 'application/json',
            'X-Customer-Id': userLogin ? userLogin.id : '',
            'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
            'X-Venue-Id': selectedVenue && selectedVenue.id,
            'X-Brand-Id': selectedRestaurant && selectedRestaurant.id,
            'X-Address-Id': selectedAddress && selectedAddress.id,
          },
          body: JSON.stringify({
            ...orderUser,
            special_instructions: valueInput,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((results) => {
            if (results.id) {
              localStorage.setItem(ORDER_USER, JSON.stringify(results));
              setOrderUser(results);
            } else {
              if (results.error_message) {
                message.error(results.error_message);
              }
            }
          })
          .catch((err) => {
            showError(err);
          });
      }, 1200);
    }
  };

  const changeInputPromoCode = (e) => {
    let valueInput = e.target.value;

    clearTimeout(timeoutCall);
    timeoutCall = setTimeout(() => {
      if (valueInput.length > 0) {
        fetch(GET_PROMO_CODE + `?code=${valueInput}&channel=online&available`, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'API-KEY': userLogin ? userLogin.api_key : '',
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((results) => {
            setValuePromoCode(valueInput);
            if (results.body) {
              setListVoucher(results.body);
            } else {
              setListVoucher([]);
            }
          })
          .catch((error) => {
            if (error) {
              showError({ message: COMMON_ERROR_MESSAGE });
            }
          });
      } else {
        fetch(GET_VOUCHERS + `?redeemable=true&channel=online`, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'API-KEY': userLogin ? userLogin.api_key : '',
          },
        })
          .then((response) => {
            return response.json();
          })
          .then((results) => {
            setValuePromoCode(valueInput);
            if (results.body) {
              setListVoucher(results.body);
            } else {
              setListVoucher([]);
            }
          })
          .catch((error) => {
            if (error) {
              console.log(error);
              showError({ message: COMMON_ERROR_MESSAGE });
            }
          });
      }
    }, 1200);
  };

  const onChangeDonate = (option) => {
    if (donate === option) {
      option = '';
    }
    fetch(GET_ORDERS + orderUser.id, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        'X-Customer-Id': userLogin ? userLogin.id : '',
        'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
        'X-Venue-Id': selectedVenue && selectedVenue.id,
        'X-Brand-Id': selectedRestaurant && selectedRestaurant.id,
        'X-Address-Id': selectedAddress && selectedAddress.id,
      },
      body: JSON.stringify({
        ...orderUser,
        donate: option,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        if (results.id) {
          setDonate(option);
          localStorage.setItem(ORDER_USER, JSON.stringify(results));
          let pay = localStorage.getItem(PAYMENT);
          setOrderUser(results);
          if (parseInt(results.total) > parseInt(userLogin.credits_balance)) {
            setPayment(null);
          } else {
            setPayment(pay);
          }
        } else {
          if (results.error_message) {
            message.error(results.error_message);
          } else {
            message.error('Donate Error');
          }
        }
      })
      .catch((err) => {
        showError(err);
      });
  };

  useEffect(() => {
    if (promoCodeModal) {
      fetch(GET_VOUCHERS + `?redeemable=true&channel=online`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'API-KEY': userLogin ? userLogin.api_key : '',
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((results) => {
          if (results.body) {
            setListVoucher(results.body);
          } else {
            setListVoucher([]);
          }
        })
        .catch((error) => {
          if (error) {
            console.log(error);
          }
        });
    }
  }, [promoCodeModal, userLogin]);

  const Step1 = (props) => {
    return (
      <Card className="border-0">
        <CardHeader>
          <h6>PROMO CODE</h6>
          <Button
            className=""
            color="default"
            type="button"
            onClick={() => setPromoCodeModal(!promoCodeModal)}
          >
            <i className="fas fa-times" />
          </Button>
          <div className="_section mt-3">
            <Container>
              <Row className="_options">
                <Col md="12" className="p-0">
                  <Input
                    autoFocus
                    placeholder="Enter promo code here…"
                    type="text"
                    className="pt-2 pb-2"
                    onChange={changeInputPromoCode}
                    defaultValue={valuePromoCode}
                    allowClear
                  />
                </Col>
              </Row>
            </Container>
            {listVoucher.length > 0 && valuePromoCode.length > 0 && (
              <p className="mt-2 mb-0" style={{ color: '#E1AC6F' }}>
                Promo Code Available
              </p>
            )}
          </div>
        </CardHeader>
        <CardBody className="">
          <PerfectScrollbar>
            <div style={{ maxHeight: 400 }}>
              {listVoucher.length > 0 ? (
                listVoucher.map((option, index) => {
                  return (
                    <div
                      key={index}
                      className="mb-2 item_voucher"
                      onClick={() => {
                        setDetailVoucer(option);
                        props.nextStep();
                      }}
                    >
                      <Card>
                        <CardImg
                          className="mt-0 mb-0"
                          height={200}
                          width="100%"
                          src={
                            option.brand_reward?.brand_image?.thumb_512x320_url
                          }
                          alt="Card image cap"
                        />
                        <CardBody className="pb-2 pt-3 pl-3 pr-3">
                          <CardText className="mt-0 mb-0">
                            {option.brand_reward.brand_outlet.name}
                          </CardText>
                          <CardTitle tag="h6" className="mb-0">
                            {option.brand_reward.title}
                          </CardTitle>
                          <Badge color="light" className="badge_vouchers">
                            Active
                          </Badge>
                        </CardBody>
                      </Card>
                    </div>
                  );
                })
              ) : (
                <div>
                  <b>
                    {valuePromoCode.length > 0
                      ? 'Sorry, it seems this promo code is not available'
                      : 'Or choose from existing voucher(s)'}
                  </b>
                </div>
              )}
            </div>
          </PerfectScrollbar>
        </CardBody>
      </Card>
    );
  };

  const Step2 = (props) => {
    if (!detailVoucer) {
      return '';
    }
    return (
      <Card className="border-0 detail_voucher">
        <Image
          src={detailVoucer.brand_reward.brand_image.original_url}
          className="image_brand m-0"
          preview={false}
        />
        <CardBody className="">
          <span>
            <Button
              className="btn_back"
              color="secondary"
              type="button"
              onClick={() => props.previousStep()}
            >
              <i className="fas fa-angle-left" />
            </Button>
          </span>
          <Badge color="light" className="badge_vouchers">
            Active
          </Badge>
          <Card className="cart_detail_voucher">
            <CardHeader className="pt-2 pb-3">
              <div>
                <div>
                  <Badge color="light" className="badge_vouchers_chanel mb-2">
                    {detailVoucer.brand_reward.channel}
                  </Badge>
                </div>
                <div>
                  <CardTitle tag="h5" className="mb-0">
                    {detailVoucer.brand_reward.title}
                  </CardTitle>
                </div>
              </div>
            </CardHeader>
            <CardBody className="pb-2 pt-3 pl-3 pr-3">
              <div className="mb-3">
                <CardTitle tag="h6" className="mb-0">
                  REDEEM AT
                </CardTitle>
                <CardText className="mt-0 mb-0">
                  {detailVoucer.brand_reward.brand_outlet.name}
                </CardText>
              </div>
              <div className="mb-3">
                <CardTitle tag="h6" className="mb-0">
                  DESCRIPTION
                </CardTitle>
                <CardText className="mt-0 mb-0">
                  {detailVoucer.brand_reward.caption}
                </CardText>
              </div>
              <div className="mb-3">
                <CardTitle tag="h6" className="mb-0">
                  REDEMPTION PERIOD
                </CardTitle>
                <CardText className="mt-0 mb-0">
                  {detailVoucer.brand_reward.redemption_period}
                </CardText>
              </div>
              <div className="mb-3">
                <CardTitle tag="h6" className="mb-0">
                  FINE PRINT
                </CardTitle>
                <CardText className="mt-0 mb-0">
                  {detailVoucer.brand_reward.fine_print}
                </CardText>
              </div>
              <div className="mb-3">
                <CardTitle tag="h6" className="mb-0">
                  VALID UNTIL
                </CardTitle>
                <CardText className="mt-0 mb-0">
                  {moment(detailVoucer.expires_at).format(
                    'Do MMMM YYYY, hh:mmA',
                  )}
                </CardText>
              </div>
            </CardBody>
          </Card>
          <Button
            color="primary"
            block
            type="button"
            className="mb-0 mt-4 btn_apply"
            onClick={useNowVoucher}
          >
            USE NOW
          </Button>
        </CardBody>
      </Card>
    );
  };

  const useNowVoucher = () => {
    if (detailVoucer.promotion_type === 'voucher') {
      fetch(GET_PROMO_CODE + `/apply?code=${detailVoucer.code}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'API-KEY': userLogin ? userLogin.api_key : '',
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((results) => {
          if (results.success) {
            fetch(GET_ORDERS + orderUser.id, {
              method: 'put',
              headers: {
                'Content-Type': 'application/json',
                'X-Customer-Id': userLogin ? userLogin.id : '',
                'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
                'X-Venue-Id': selectedVenue && selectedVenue.id,
                'X-Brand-Id': selectedRestaurant && selectedRestaurant.id,
                'X-Address-Id': selectedAddress && selectedAddress.id,
              },
              body: JSON.stringify({
                ...orderUser,
                order_promotions: [
                  {
                    voucher_no: results.voucher_no,
                  },
                ],
              }),
            })
              .then((response) => {
                return response.json();
              })
              .then((results) => {
                if (results.id) {
                  localStorage.setItem(ORDER_USER, JSON.stringify(results));
                  dispatch(setOrderUserItems(results.order_items));
                  setPromoCodeModal(!promoCodeModal);
                  message.success('Add Promo Code Successful');
                } else {
                  if (results.error_message) {
                    message.error(results.error_message);
                  } else {
                    showError({ message: COMMON_ERROR_MESSAGE });
                  }
                }
              })
              .catch((err) => {
                showError(err);
              });
          } else {
            if (results.error_message) {
              showError(results.error_message);
            } else {
              showError({ message: COMMON_ERROR_MESSAGE });
            }
          }
        })
        .catch((error) => {
          if (error) {
            showError({ message: COMMON_ERROR_MESSAGE });
          }
        });
    } else {
      fetch(GET_ORDERS + orderUser.id, {
        method: 'put',
        headers: {
          'Content-Type': 'application/json',
          'X-Customer-Id': userLogin ? userLogin.id : '',
          'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
          'X-Venue-Id': selectedVenue && selectedVenue.id,
          'X-Brand-Id': selectedRestaurant && selectedRestaurant.id,
          'X-Address-Id': selectedAddress && selectedAddress.id,
        },
        body: JSON.stringify({
          ...orderUser,
          order_promotions: [
            {
              voucher_no: detailVoucer.bepoz_lookup,
            },
          ],
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((results) => {
          if (results.id) {
            localStorage.setItem(ORDER_USER, JSON.stringify(results));
            dispatch(setOrderUserItems(results.order_items));
            setPromoCodeModal(!promoCodeModal);
            message.success('Add Promo Code Success');
          } else {
            if (results.error_message) {
              message.error(results.error_message);
            } else {
              message.error('Add Promo Code Error');
            }
          }
        })
        .catch((err) => {
          if (err) {
            message.error('Add Promo Code Error');
          }
        });
    }
  };

  const confirm_delete_promo = () => {
    fetch(GET_ORDERS + orderUser.id, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        'X-Customer-Id': userLogin ? userLogin.id : '',
        'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
        'X-Venue-Id': selectedVenue && selectedVenue.id,
        'X-Brand-Id': selectedRestaurant && selectedRestaurant.id,
        'X-Address-Id': selectedAddress && selectedAddress.id,
      },
      body: JSON.stringify({
        ...orderUser,
        order_promotions: [],
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        if (results.id) {
          localStorage.setItem(ORDER_USER, JSON.stringify(results));
          dispatch(setOrderUserItems(results.order_items));
          message.success('Remove Promo Code Success');
        } else {
          if (results.error_message) {
            message.error(results.error_message);
          } else {
            message.error('Remove Promo Code Error');
          }
        }
      })
      .catch((err) => {
        if (err) {
          message.error('Remove Promo Code Error');
        }
      });
  };

  const API_CONFIG = (path, method) => {
    return {
      path,
      option: {
        method,
        headers: apiHeader,
      },
    };
  };

  const socialCreditCheckout = () => {
    fetch(GET_ORDERS + `${orderUser.id}/checkout`, {
      method: 'post',
      headers: apiHeader,
      body: JSON.stringify({
        method: payment,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        if (results.WalletBalance) {
          localStorage.removeItem(ORDER_USER);
          localStorage.removeItem(SELECTED_TIME);
          dispatch(setOrderUserItems([]));
          message.success('Pay Success, Thank you & Enjoy your food');
          setLoadingPay(false);
          setTimeout(() => {
            window.location.href = '/';
          }, 500);
        } else {
          if (results.errors) {
            message.error(results.errors.Message);
            setLoadingPay(false);
          } else if (results.error_message) {
            message.error(results.error_message);
            setLoadingPay(false);
          } else {
            showError({ message: COMMON_ERROR_MESSAGE });
            setLoadingPay(false);
          }
        }
      })
      .catch((err) => {
        if (err) {
          showError(err);
          setLoadingPay(false);
        }
      });
  };

  const stripeCheckout = (type) => {
    let odUser = readAndParseStorage(ORDER_USER);
    if (PAYMENT_TYPE.creditCardStripe === type) {
      const config = API_CONFIG(
        GET_PAYMENT_INTENT + odUser.id + '&payment_method_type=card',
        'post',
      );
      axios(config.path, config.option)
        .then((response) => {
          setIntent(response.data);
          setCreditCardStripeModal(true);
        })
        .catch((err) => {
          console.log(err);
          setLoadingPay(false);

          checkoutErrorMessage(
            err?.response?.data?.error_message ||
              "Can't get the payment intent, Please contact us to check.",
          );
        });
    } else {
      if (PAYMENT_TYPE.FPXStripe === type) {
        const config = API_CONFIG(
          GET_PAYMENT_INTENT + odUser.id + '&payment_method_type=fpx',
          'post',
        );
        axios(config.path, config.option)
          .then((response) => {
            setIntent(response.data);
            setPFXStripeModal(true);
          })
          .catch((err) => {
            console.log(err);
            setLoadingPay(false);
            if (
              err.response &&
              err.response.data &&
              err.response.data.error_message
            ) {
              checkoutErrorMessage(err.response.data.error_message);
            } else {
              checkoutErrorMessage(
                "Can't get the payment intent, Please contact us to check.",
              );
            }
          });
      }
    }
  };

  const checkout = () => {
    if (payment === PAYMENT_TYPE.socialCredit) {
      socialCreditCheckout();
    } else {
      if (payment === PAYMENT_TYPE.creditCardStripe) {
        stripeCheckout(PAYMENT_TYPE.creditCardStripe);
      } else {
        if (payment === PAYMENT_TYPE.FPXStripe) {
          stripeCheckout(PAYMENT_TYPE.FPXStripe);
        }
      }
    }
  };

  const checkoutErrorMessage = (mess) => {
    message.error(mess);
    setLoadingPay(false);
  };

  const confirm_pay = () => {
    setLoadingPay(true);
    if (orderUser.time_slot) {
      checkout();
    } else {
      fetch(GET_VENUES + `/${selectedVenue.id}/next_time_slot`, {
        method: 'get',
        headers: apiHeader,
      })
        .then((response) => {
          return response.json();
        })
        .then((results) => {
          if (results.time_slot) {
            //Call Update Order
            fetch(GET_ORDERS + orderUser.id, {
              method: 'put',
              headers: apiHeader,
              body: JSON.stringify({
                ...orderUser,
                time_slot: results.time_slot,
              }),
            })
              .then((response) => {
                return response.json();
              })
              .then((results) => {
                if (results.id) {
                  checkout();
                } else {
                  setLoadingPay(false);
                  checkoutErrorMessage(
                    results.error_message ||
                      "Pay Error, Can't modify the order",
                  );
                }
              })
              .catch((err) => {
                setLoadingPay(false);
                showError(err);
              });
          } else {
            setLoadingPay(false);
            showError(results);
          }
        })
        .catch((err) => {
          setLoadingPay(false);
          showError(err);
        });
    }
  };

  const cancel_stripe = () => {
    if (!processingPayment) {
      if (
        PAYMENT_TYPE.creditCardStripe === payment ||
        PAYMENT_TYPE.FPXStripe === payment
      ) {
        let odUser = readAndParseStorage(ORDER_USER);
        const config = API_CONFIG(GET_PAYMENT_INTENT + odUser.id, 'delete');
        axios(config.path, config.option).then((response) => {
          setIntent(response.data);
          setCreditCardStripeModal(false);
          setPFXStripeModal(false);
        });
      }
    } else {
      message.info('Payment Processing... Please wait!');
    }
  };

  const paymentWith = () => {
    let txt = 'SELECT A PAYMENT METHOD';
    const payment = localStorage.getItem(PAYMENT);
    if (payment) {
      switch (payment) {
        case PAYMENT_TYPE.socialCredit:
          txt = `SOCIAL CREDIT (RM ${userLogin && userLogin.credits_balance})`;
          break;
        case PAYMENT_TYPE.creditCardStripe:
          txt = 'CREDIT/DEBIT CARD';
          break;
        case PAYMENT_TYPE.FPXStripe:
          txt = `Online Banking (FPX)`;
          break;
        default:
          break;
      }
    }
    return txt;
  };

  return (
    <>
      <Spin tip="Loading..." spinning={loadingPay}>
        {orderUserItems && orderUserItems.length > 0 && apiHeader ? (
          <Container className="review_order">
            <Row>
              <Col md="12" className="_header">
                <h1>REVIEW TODAY’S ORDER</h1>
                <p>
                  {selectedRestaurant?.name} — {selectedVenue?.name}
                </p>
                <Button
                  className="close_detail_btn second"
                  color="default"
                  type="button"
                  onClick={() => history.push(`/${selectedRestaurant.id}/home`)}
                >
                  <i className="fas fa-times" />
                </Button>
              </Col>
            </Row>
            <Row className="_body">
              <Col md="7" className="_details">
                <div className="_list_items">
                  {orderUserItems.map((item, index) => {
                    return (
                      <div key={index}>
                        <CartItem cart={item} /> <hr />
                      </div>
                    );
                  })}
                </div>
                <div className="custom_control_item_type">
                  <div className="custom-control custom-checkbox ">
                    <input
                      className="custom-control-input"
                      id="cutleries"
                      name="checkbox-cutleries"
                      type="checkbox"
                      value="DO YOU NEED CUTLERIES?"
                      checked={cutleries}
                      onChange={() => {
                        fetch(GET_ORDERS + orderUser.id, {
                          method: 'put',
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Customer-Id': userLogin ? userLogin.id : '',
                            'X-Customer-Api-Key': userLogin
                              ? userLogin.api_key
                              : '',
                            'X-Venue-Id': selectedVenue && selectedVenue.id,
                            'X-Brand-Id':
                              selectedRestaurant && selectedRestaurant.id,
                            'X-Address-Id':
                              selectedAddress && selectedAddress.id,
                          },
                          body: JSON.stringify({
                            ...orderUser,
                            request_cutleries: !cutleries ? 'yes' : 'no',
                          }),
                        })
                          .then((response) => {
                            return response.json();
                          })
                          .then((results) => {
                            if (results.id) {
                              localStorage.setItem(
                                ORDER_USER,
                                JSON.stringify(results),
                              );
                              setOrderUser(results);
                              setCutleries(!cutleries);
                            } else {
                              if (results.error_message) {
                                message.error(results.error_message);
                              } else {
                                message.error('Donate Error');
                              }
                            }
                          })
                          .catch((err) => {
                            showError(err);
                          });
                      }}
                    />
                    <label className="custom-control-label" htmlFor="cutleries">
                      <span>DO YOU NEED CUTLERIES?</span>
                    </label>
                  </div>
                </div>
                <div className="_section mt-3">
                  <Container>
                    <Row className="_options">
                      <Col md="12" className="p-0">
                        <Input
                          placeholder="Write your instructions…"
                          type="text"
                          value={instruction}
                          className="pt-2 pb-2"
                          onChange={changeInstruction}
                        />
                      </Col>
                    </Row>
                  </Container>
                </div>

                <div className="_delivery_details">
                  <h2>DELIVERY DETAILS</h2>
                  {orderUser && orderUser.order_type === 'delivery' ? (
                    <>
                      <div className="_delivery_details_btn">
                        <div className="_left">
                          <div className="_icon">
                            <i className="fas fa-map-marker-alt" />
                          </div>
                          <div className="_text">
                            <p>DELIVER TO</p>
                            <h2>{selectedAddress && selectedAddress.name}</h2>
                          </div>
                        </div>
                        <i className="fas fa-chevron-right" />
                      </div>
                      <div className="_delivery_details_btn">
                        <div className="_left">
                          <div className="_icon">
                            <i className="fas fa-map-marker-alt" />
                          </div>
                          <div className="_text">
                            <p>DELIVER FROM</p>
                            <h2>
                              {selectedRestaurant && selectedRestaurant.name} -{' '}
                              {selectedVenue && selectedVenue.name}
                            </h2>
                          </div>
                        </div>
                        <i className="fas fa-chevron-right" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div className="_delivery_details_btn">
                        <div className="_left">
                          <div className="_icon">
                            <i className="fas fa-map-marker-alt" />
                          </div>
                          <div className="_text">
                            <p>PICK UP FROM</p>
                            <h2>
                              {selectedRestaurant && selectedRestaurant.name} -{' '}
                              {selectedAddress && selectedAddress.name}
                            </h2>
                          </div>
                        </div>
                        <i className="fas fa-chevron-right" />
                      </div>
                    </>
                  )}

                  <div
                    className="_delivery_details_btn"
                    onClick={() => {
                      dispatch(setCustomOverlay(true));
                      dispatch(setShowPopupTime(true));
                    }}
                  >
                    <div className="_left">
                      <div className="_icon">
                        <i className="far fa-clock" />
                      </div>
                      <div className="_text">
                        <p>DELIVERY TIME</p>
                        <h2>
                          {selectedTime && selectedTime.valueDay.label} at{' '}
                          {selectedTime &&
                          selectedTime.valueSelectTime.id === 'soon'
                            ? 'AS SOON AS POSSIBLE'
                            : selectedTime &&
                              `${selectedTime.valueSelectTime.startT} - ${selectedTime.valueSelectTime.endT}`}
                        </h2>
                      </div>
                    </div>
                    <i className="fas fa-chevron-right" />
                  </div>
                  <div
                    className="_delivery_details_btn"
                    onClick={() => setPaymentModal(!paymentModal)}
                  >
                    <div className="_left">
                      <div className="_icon">
                        <i className="fas fa-wallet" />
                      </div>
                      <div className="_text">
                        <p>PAYING WITH</p>
                        <h2>{paymentWith()}</h2>
                      </div>
                    </div>
                    <i className="fas fa-chevron-right" />
                  </div>
                </div>
                {globalSetting && globalSetting.donate_enabled && (
                  <div className="_donate">
                    <h2>{globalSetting.donate_title}</h2>
                    <p>{globalSetting.donate_desc}</p>
                    <div className="_moneys">
                      {globalSetting.donate_options.map((option, index) => {
                        return (
                          <span
                            key={index}
                            className={donate === option ? 'active_donate' : ''}
                            style={{ cursor: 'pointer' }}
                            onClick={() => onChangeDonate(option)}
                          >
                            RM {parseInt(option)}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                )}
              </Col>

              <Col md="5" className="_summary">
                <div>
                  <h2>CHARGES SUMMARY</h2>
                  <div className="_item">
                    <p>SUBTOTAL</p>
                    <p>RM {orderUser && orderUser.item_total}</p>
                  </div>
                  {orderUser && (
                    <div>
                      {orderUser.order_extra_fees.map((item, index) => {
                        return (
                          <div className="_item" key={index}>
                            <p className="name">{item.title}</p>
                            <p>{item.formatted_amount}</p>
                          </div>
                        );
                      })}
                    </div>
                  )}
                  {orderUser &&
                    orderUser.order_promotions.map((option, index) => {
                      return (
                        <div className="_item" key={index}>
                          <p>PROMO</p>
                          <p>- RM {option.computed_amount}</p>
                        </div>
                      );
                    })}
                  <div className="_item _total">
                    <p>TOTAL</p>
                    <p>RM {orderUser && parseFloat(orderUser.total)}</p>
                  </div>
                  <hr />
                  <Button
                    className="text-primary _link mb-4"
                    color="link"
                    onClick={() => {
                      setPromoCodeModal(!promoCodeModal);
                      setListVoucher([]);
                      setValuePromoCode('');
                    }}
                  >
                    GOT A PROMO CODE?
                  </Button>
                  {orderUser &&
                    orderUser.order_promotions.map((option, index) => {
                      return (
                        <div className="_item mt-2" key={index}>
                          <p>
                            {option.voucher_title}
                            <Popconfirm
                              title="Are you sure to delete this promo code?"
                              onConfirm={confirm_delete_promo}
                              okText="Yes"
                              cancelText="No"
                            >
                              <span className="icon_remove_voucher">
                                <i className="fas fa-times" />
                              </span>
                            </Popconfirm>
                          </p>
                        </div>
                      );
                    })}
                  <Modal
                    className="modal-dialog-centered social_dialog modal_promocode"
                    size="sm"
                    isOpen={creditCardStripeModal}
                    onClosed={() => setLoadingPay(false)}
                    backdropClassName="social_dialog__bg"
                  >
                    <div className="modal-body p-0">
                      <Card className="border-0">
                        <CardHeader>
                          <h6>PAY WITH CREDIT/DEBIT CARD</h6>
                          <Button
                            className=""
                            color="default"
                            type="button"
                            onClick={() => cancel_stripe()}
                          >
                            <i className="fas fa-times" />
                          </Button>
                        </CardHeader>
                        <CardBody className="">
                          {orderUser && intent && (
                            <CreditStripeCheckoutForm
                              onSuccessfulCheckout={() =>
                                onStripeSuccessfulCheckout()
                              }
                              intent={intent}
                              price={`RM ${
                                orderUser && parseFloat(orderUser.total)
                              }`}
                            />
                          )}
                        </CardBody>
                      </Card>
                    </div>
                  </Modal>
                  <Modal
                    className="modal-dialog-centered social_dialog modal_promocode"
                    size="sm"
                    isOpen={PFXStripeModal}
                    onClosed={() => setLoadingPay(false)}
                    backdropClassName="social_dialog__bg"
                  >
                    <div className="modal-body p-0">
                      <Card className="border-0">
                        <CardHeader>
                          <h6>PAY WITH ONLINE BANKING (FPX)</h6>
                          <Button
                            className=""
                            color="default"
                            type="button"
                            onClick={() => cancel_stripe()}
                          >
                            <i className="fas fa-times" />
                          </Button>
                        </CardHeader>
                        <CardBody className="">
                          {orderUser && intent && (
                            <FXBankStripeCheckoutForm
                              intent={intent}
                              price={`RM ${
                                orderUser && parseFloat(orderUser.total)
                              }`}
                            />
                          )}
                        </CardBody>
                      </Card>
                    </div>
                  </Modal>
                  <Modal
                    className="modal-dialog-centered social_dialog modal_promocode"
                    size="sm"
                    isOpen={promoCodeModal}
                    toggle={() => setPromoCodeModal(!promoCodeModal)}
                    backdropClassName="social_dialog__bg"
                  >
                    <div className="modal-body p-0">
                      <StepWizard initialStep={1}>
                        <Step1 />
                        <Step2 />
                      </StepWizard>
                    </div>
                  </Modal>
                  <Modal
                    className="modal-dialog-centered social_dialog modal_promocode"
                    size="sm"
                    isOpen={paymentModal}
                    toggle={() => setPaymentModal(!paymentModal)}
                    backdropClassName="social_dialog__bg"
                  >
                    <div className="modal-body p-0">
                      <Card className="border-0">
                        <CardHeader>
                          <h6>SELECT A PAYMENT METHOD</h6>
                          <Button
                            className=""
                            color="default"
                            type="button"
                            onClick={() => setPaymentModal(!paymentModal)}
                          >
                            <i className="fas fa-times" />
                          </Button>
                        </CardHeader>
                        <CardBody className="">
                          <div className="custom_control_item_type">
                            <div className="custom-control custom-radio ">
                              <input
                                className="custom-control-input"
                                id="payment"
                                name="radio-payment"
                                type="radio"
                                value={PAYMENT_TYPE.socialCredit}
                                checked={PAYMENT_TYPE.socialCredit === payment}
                                onChange={() =>
                                  setPayment(PAYMENT_TYPE.socialCredit)
                                }
                                disabled={
                                  parseInt(
                                    userLogin && userLogin.credits_balance,
                                  ) > parseInt(orderUser && orderUser.total)
                                    ? false
                                    : true
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="payment"
                              >
                                <span>
                                  SOCIAL CREDIT (RM{' '}
                                  {userLogin && userLogin.credits_balance})
                                </span>
                              </label>
                            </div>
                          </div>
                          <div className="custom_control_item_type">
                            <div className="custom-control custom-radio ">
                              <input
                                className="custom-control-input"
                                id="payment-stripe"
                                name="radio-payment"
                                type="radio"
                                value={PAYMENT_TYPE.creditCardStripe}
                                checked={
                                  PAYMENT_TYPE.creditCardStripe === payment
                                }
                                onChange={() =>
                                  setPayment(PAYMENT_TYPE.creditCardStripe)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="payment-stripe"
                              >
                                <span>CREDIT/DEBIT CARD</span>
                              </label>
                            </div>
                          </div>
                          <div className="custom_control_item_type">
                            <div className="custom-control custom-radio ">
                              <input
                                className="custom-control-input"
                                id="payment-stripe-fpx"
                                name="radio-payment"
                                type="radio"
                                value={PAYMENT_TYPE.FPXStripe}
                                checked={PAYMENT_TYPE.FPXStripe === payment}
                                onChange={() =>
                                  setPayment(PAYMENT_TYPE.FPXStripe)
                                }
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="payment-stripe-fpx"
                              >
                                <span>ONLINE BANKING (FPX)</span>
                              </label>
                            </div>
                          </div>
                          <Button
                            color="primary"
                            block
                            type="button"
                            className="mb-0 mt-4 btn_apply"
                            onClick={() => {
                              if (payment) {
                                localStorage.setItem(PAYMENT, payment);
                                setPaymentModal(!paymentModal);
                                message.success(
                                  'Update payment method success',
                                );
                              } else {
                                message.warning(
                                  'Please select a payment method',
                                );
                              }
                            }}
                          >
                            UPDATE
                          </Button>
                        </CardBody>
                      </Card>
                    </div>
                  </Modal>
                </div>
                {payment ? (
                  <Button
                    block
                    color="primary"
                    type="button"
                    onClick={() => {
                      confirm_pay();
                    }}
                  >
                    CONFIRM & PAY
                  </Button>
                ) : (
                  <Button
                    block
                    color="primary"
                    type="button"
                    onClick={() => setPaymentModal(!paymentModal)}
                  >
                    SELECT A PAYMENT METHOD
                  </Button>
                )}
              </Col>
            </Row>
          </Container>
        ) : (
          <Container className="review_order">
            <Row>
              <Col
                md="12"
                className="_header d-flex justify-content-center flex-column align-items-center"
              >
                <h1>REVIEW TODAY’S ORDER</h1>
                <Button
                  color="primary"
                  type="button"
                  onClick={() => {
                    window.location.href = '/';
                  }}
                >
                  BACK TO MAIN PAGE
                </Button>
              </Col>
            </Row>
          </Container>
        )}
      </Spin>
    </>
  );
};
export default ReviewOrder;
