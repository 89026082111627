import { SET_LOGGED_IN, SET_USER_LOGIN } from '../_constants/actionType';

export function setAuthentication(isLogged) {
  return {
    type: SET_LOGGED_IN,
    isLogged,
  };
}

export function setUserLogin(userLogin) {
  return {
    type: SET_USER_LOGIN,
    userLogin,
  };
}
