import React from 'react';
import { Modal } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setCartModal } from '../../_actions/effect.action';
import CartDialog from './CartDialog';

const CartDialogCustom = () => {
  const dispatch = useDispatch();
  const cartModal = useSelector((state) => state.effect.cartModal);
  return (
    <>
      <Modal
        className="modal-dialog-centered custom_dialog"
        size="sm"
        isOpen={cartModal}
        toggle={() => dispatch(setCartModal(!cartModal))}
        backdropClassName="social_dialog__bg"
      >
        <div className="modal-body p-0">
          <CartDialog onToggle={() => dispatch(setCartModal(!cartModal))} />
        </div>
      </Modal>
    </>
  );
};
export default CartDialogCustom;
