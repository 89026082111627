import React from 'react';
// reactstrap components
import { Container, Row, Col } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import {
  setPickRestaurantModal,
  setShowTerms,
} from '../../_actions/effect.action';
import Terms from 'components/main/Terms';

const Footer = () => {
  const selectedRestaurant = useSelector(
    (state) => state.global.selectedRestaurant,
  );
  const dispatch = useDispatch();

  return (
    <>
      <footer className=" footer">
        <Container>
          <Row className="justify-content-md-between">
            <Col md="4" sm="12">
              <div className="pick_restaurant">
                <h2 className="footer__header">PICK RESTAURANT</h2>
                <div
                  className="custom__btn"
                  onClick={() => dispatch(setPickRestaurantModal(true))}
                >
                  <img
                    alt="..."
                    src={
                      selectedRestaurant
                        ? selectedRestaurant.logo_url
                        : require('assets/img/icons/common/the-social-restaurant.svg')
                    }
                    style={{ width: '40px', height: '40px' }}
                  />
                  <div className="custom__btn_name">
                    <h2>
                      {selectedRestaurant
                        ? selectedRestaurant.name
                        : 'THE SOCIAL'}
                    </h2>
                    <span>
                      {selectedRestaurant
                        ? selectedRestaurant.description
                        : 'BAR & GRILL'}
                    </span>
                  </div>
                  <div>
                    <i className="fas fa-chevron-down" />
                  </div>
                </div>
              </div>
            </Col>
            <Col md="8" sm="12">
              <div className="footer__list">
                {/* <div className="footer__item">
                  <h2 className="footer__header">FROM THE MENU</h2>
                  <ul>
                    <li>
                      <a href="#bro">SMALL EATS</a>
                    </li>
                    <li>
                      <a href="#bro">ORIENTAL FAVOURITES</a>
                    </li>
                    <li>
                      <a href="#bro">PASTAS</a>
                    </li>
                    <li>
                      <a href="#bro">CLASSIC WESTERNS</a>
                    </li>
                    <li>
                      <a href="#bro">SANDWICHES & SALADS</a>
                    </li>
                    <li>
                      <a href="#bro">KIDS MENU</a>
                    </li>
                    <li>
                      <a href="#bro">SWEET TOOTH</a>
                    </li>
                  </ul>
                </div>
                <div className="footer__item">
                  <h2 className="footer__header">POPULAR DISHES</h2>
                  <ul>
                    <li>
                      <a href="#bro">NYONYA CURRY LAKSA</a>
                    </li>
                    <li>
                      <a href="#bro">NACHOS</a>
                    </li>
                    <li>
                      <a href="#bro">CHAR KWAY TEOW</a>
                    </li>
                    <li>
                      <a href="#bro">CHICKEN SCHNITZEL</a>
                    </li>
                    <li>
                      <a href="#bro">LASAGNE AL FORNO</a>
                    </li>
                    <li>
                      <a href="#bro">KANI & AVOCADO SALAD</a>
                    </li>
                    <li>
                      <a href="#bro">PENNE PESTO</a>
                    </li>
                  </ul>
                </div> */}
                <div className="footer__item">
                  <h2 className="footer__header">THE SOCIAL</h2>
                  <ul>
                    {/* <li>
                      <a href="#bro">DINING IN? FIND US</a>
                    </li> */}
                    <li>
                      <a
                        href="https://www.thesocialgroup.com.my/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        ABOUT US
                      </a>
                    </li>
                    <li>
                      <a
                        href="#bros"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(setShowTerms(true));
                        }}
                      >
                        PRIVACY POLICY
                      </a>
                    </li>
                    <li>
                      <a
                        href="#bro"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(setShowTerms(true));
                        }}
                      >
                        TERMS OF USE
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://www.instagram.com/thesocial.my"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-instagram"></i>
                      </a>
                      <a
                        href="https://www.facebook.com/thesocial.my/"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <i className="fab fa-facebook-square"></i>
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </Col>
            <Col>
              <hr />
              <div className="footer__second">
                <p>© 2001 - 2022 The Social Group of Restaurants</p>
                <div>
                  <span> GET THE APP</span>
                  <a
                    href="https://thesocial.tsunago.asia/mobile/download"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="..."
                      src={require('assets/img/icons/common/app-store.svg')}
                    />
                  </a>
                  <a
                    href="https://thesocial.tsunago.asia/mobile/download"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <img
                      alt="..."
                      src={require('assets/img/icons/common/gg-store.svg')}
                    />
                  </a>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Terms />
      </footer>
    </>
  );
};

export default Footer;
