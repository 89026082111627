import React, { useEffect, useState } from 'react';
// reactstrap components
import { Button, Card, CardBody } from 'reactstrap';
import { message, Spin, Form, Input } from 'antd';
import { AimOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { POST_ADDRESSES } from '../../api/APIs';
import { setSelectedAddress } from '../../_actions/global.action';
import { USER_LOGIN } from '_constants';
import { StandaloneSearchBox, LoadScript } from '@react-google-maps/api';
import { readAndParseStorage } from '../../_helper';

let searchBox = null;
const DeliverySaveLocation = (props) => {
  const [apiKey, setApiKey] = useState(null);
  const [valueSaveAddress, setValueSaveAddress] = useState({
    name: '',
    lat: '',
    lng: '',
    full_address: '',
    detail: '',
  });
  const [loading, setLoading] = useState(false);
  const [showLatLongError, setShowLatLongError] = useState(false);
  const globalSetting = useSelector((state) => state.global.globalSetting);
  const [form] = Form.useForm();

  useEffect(() => {
    return () => {
      setValueSaveAddress({
        name: '',
        lat: '',
        lng: '',
        full_address: '',
        detail: '',
      });
      setLoading(false);
    };
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    if (globalSetting) {
      setApiKey(globalSetting.google_map_api_key);
    }
  }, [globalSetting]);

  const getLocation = () => {
    setLoading(true);
    let timeoutCheck;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(geocodeLatLng);
      timeoutCheck = setTimeout(() => {
        error('Get location error');
        setLoading(false);
      }, 10000);
    } else {
      error('Get location error');
    }

    function geocodeLatLng(position) {
      if (position) {
        const latlng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        const geocoder = new window.google.maps.Geocoder();

        geocoder.geocode({ location: latlng }, (results, status) => {
          if (status === 'OK') {
            clearTimeout(timeoutCheck);
            success('Get location success');
            setLoading(false);
            setValueSaveAddress({
              ...valueSaveAddress,
              ...latlng,
              full_address: results[0].formatted_address,
            });
            form.setFieldsValue({
              tempAddress: results[0].formatted_address,
            });
          } else {
            error('Get location error');
            setLoading(false);
          }
        });
      } else {
        error('Get location error');
        setLoading(false);
      }
    }
  };

  const onSBLoad = (ref) => {
    searchBox = ref;
  };

  const onPlacesChanged = (e) => {
    let results = searchBox.getPlaces();
    if (results) {
      const places = searchBox.getPlaces();
      var lng = places[0].geometry.location.lng();
      var lat = places[0].geometry.location.lat();
      var latlng = { lat, lng };
      setValueSaveAddress({
        ...valueSaveAddress,
        ...latlng,
        full_address: places[0].formatted_address,
      });
      setShowLatLongError(false);
      console.log(places[0].formatted_address);
    }
  };

  const onFullAddress = (e) => {
    console.log(e.target.value);
    setValueSaveAddress({
      ...valueSaveAddress,
      lat: '',
      lng: '',
      full_address: e.target.value,
    });
    form.setFieldsValue({
      tempAddress: e.target.value,
    });
  };

  const success = (mess) => {
    message.success(mess);
  };

  const error = (mess) => {
    message.error(mess);
  };

  const onChangeData = (e) => {
    let valueSave = valueSaveAddress;
    valueSave[e.target.name] = e.target.value;
    setValueSaveAddress({ ...valueSave });
  };

  const onCheck = async () => {
    try {
      const userLogin = readAndParseStorage(USER_LOGIN);
      fetch(POST_ADDRESSES, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'X-Customer-Id': userLogin ? userLogin.id : '',
          'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
        },
        body: JSON.stringify(valueSaveAddress),
      })
        .then((response) => {
          return response.json();
        })
        .then((results) => {
          if (results.id) {
            success('Save location success');
            dispatch(setSelectedAddress(results));
            props.onSaveLocation({ checkSave: true });
          } else {
            if (results.error_message) {
              error(results.error_message);
            } else {
              error('Save location error');
            }
          }
        })
        .catch((error) => {
          console.log(error);
          error('Save location error');
        });
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
    }
  };
  const save = () => {
    if (valueSaveAddress.lat && valueSaveAddress.lng) {
      form
        .validateFields(['name', 'detail', 'tempAddress'])
        .then((values) => {
          console.log(values);
          onCheck();
        })
        .catch((e) => {
          console.log(e);
          message.warning('Please check the form validation');
        });
    } else {
      setShowLatLongError(true);
      form
        .validateFields(['name', 'detail'])
        .then((values) => {
          console.log(values);
          onCheck();
        })
        .catch((e) => {
          console.log(e);
          message.warning('Please check the form validation');
        });
    }
  };

  return (
    <>
      <div className="address-wrapper">
        <Card>
          <CardBody>
            <Form name="dynamic_rule" form={form}>
              <div className="mb-3">
                <div className="cus-input mb-3">
                  <p>ADDRESS</p>
                  <LoadScript googleMapsApiKey={apiKey} libraries={['places']}>
                    <StandaloneSearchBox
                      onLoad={onSBLoad}
                      onPlacesChanged={onPlacesChanged}
                    >
                      <input
                        name="full_address"
                        type="text"
                        placeholder="Customized your placeholder"
                        value={valueSaveAddress.full_address}
                        onChange={onFullAddress}
                      />
                    </StandaloneSearchBox>
                  </LoadScript>
                  <Form.Item
                    className="mb-0 hidden-input"
                    name="tempAddress"
                    rules={[
                      {
                        required: true,
                        message:
                          'Please search for your address and select the result on the dropdown',
                      },
                    ]}
                  >
                    <Input style={{ display: 'none' }} className="test" />
                  </Form.Item>
                  {showLatLongError && (
                    <div style={{ color: '#ff4d4f' }}>
                      Please search for your address and select the result on
                      the dropdown
                    </div>
                  )}
                </div>
                <button
                  className="btn_get_location"
                  type="button"
                  onClick={getLocation}
                >
                  <AimOutlined /> Please allow us to access your location{' '}
                  <span className="ml-2">
                    <Spin spinning={loading}></Spin>
                  </span>
                </button>
              </div>

              <div className="cus-input mb-3">
                <p>NAME</p>
                <Form.Item
                  name="name"
                  className="mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Please enter your address name (i.e: my home)',
                    },
                  ]}
                >
                  <input
                    name="name"
                    placeholder="My house"
                    value={valueSaveAddress.name}
                    onChange={onChangeData}
                  />
                </Form.Item>
              </div>

              <div className="cus-input mb-3">
                <p>Address Details</p>
                <Form.Item
                  className="mb-0"
                  name="detail"
                  rules={[
                    {
                      required: true,
                      message:
                        'Please enter address details (i.e: floor 1, block A)',
                    },
                  ]}
                >
                  <input
                    name="detail"
                    placeholder="Building, floor, unit…"
                    value={valueSaveAddress.detail}
                    onChange={onChangeData}
                  />
                </Form.Item>
              </div>
              <Form.Item>
                <Button block className="" color="primary" onClick={save}>
                  SAVE LOCATION
                </Button>
              </Form.Item>
            </Form>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default DeliverySaveLocation;
