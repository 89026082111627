import React from 'react';
import { Modal } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setProfileModal } from '../../_actions/effect.action';
import ProfileDialog from './ProfileDialog';

const ProfileDialogCustom = () => {
  const dispatch = useDispatch();
  const profileModal = useSelector((state) => state.effect.profileModal);
  return (
    <>
      <Modal
        className="modal-dialog-centered custom_dialog"
        size="sm"
        isOpen={profileModal}
        toggle={() => dispatch(setProfileModal(!profileModal))}
        backdropClassName="social_dialog__bg"
      >
        <div className="modal-body p-0">
          <ProfileDialog
            onToggle={() => dispatch(setProfileModal(!profileModal))}
          />
        </div>
      </Modal>
    </>
  );
};
export default ProfileDialogCustom;
