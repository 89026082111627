function order_type(data) {
  let titleStatus = '';
  if (data.order_type === 'delivery') {
    if (data.status === 'checked_out') {
      titleStatus = 'YOUR ORDER IS ACCEPTED';
    } else if (
      data.status === 'assigning_rider' ||
      data.status === 'preparing_food' ||
      data.status === 'food_ready' ||
      data.status === 'assigned_rider'
    ) {
      titleStatus = 'YOUR ORDER IS BEING PREPARED';
    } else if (
      data.status === 'rider_pick_up' ||
      data.status === 'staff_delivery'
    ) {
      titleStatus = 'RIDER IS ON THE WAY';
    } else if (data.status === 'rider_arrived') {
      titleStatus = 'RIDER HAS ARRIVED';
    } else if (data.status === 'completed') {
      titleStatus = 'YOUR ORDER IS COMPLETED';
    }
  } else if (data.order_type === 'pickup') {
    if (data.status === 'checked_out') {
      titleStatus = 'YOUR ORDER IS ACCEPTED';
    } else if (data.status === 'preparing_food') {
      titleStatus = 'YOUR ORDER IS BEING PREPARED';
    } else if (data.status === 'food_ready') {
      titleStatus = 'YOUR ORDER IS READY TO PICK UP';
    } else if (data.status === 'completed') {
      titleStatus = 'YOUR ORDER IS COMPLETED';
    }
  }
  return titleStatus;
}

export default order_type;
