import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { effect } from './effect.reducer';
import { global } from './global.reducer';
import { banner } from './banner.reducer';
import { mainMeal } from './mainMeal.reducer';
import { cart } from './cart.reducer';

const rootReducer = combineReducers({
  authentication,
  effect,
  global,
  banner,
  mainMeal,
  cart,
});

export default rootReducer;
