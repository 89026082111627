import React, { useEffect, useRef } from 'react';

// core components
import { useSelector } from 'react-redux';
import DetailLayout from './DetailLayout';
import BannerDetail from '../main/banner/BannerDetail';
import classNames from 'classnames';

const HotDealLayout = (props) => {
  const customOverlay = useSelector((state) => state.effect.customOverlay);
  const mainEl = useRef(null);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainEl.current.scrollTop = 0;
  });
  return (
    <>
      <main ref={mainEl}>
        <div className="position-relative">
          <section className="section header_section">
            <div
              id="custom-overlay"
              className={classNames({ 'd-none': !customOverlay })}
            />
            <DetailLayout component={BannerDetail} match={props.match} />
          </section>
        </div>
      </main>
    </>
  );
};

export default HotDealLayout;
