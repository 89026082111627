import { message } from 'antd';
import { COMMON_ERROR_MESSAGE } from '../_constants/index';
import moment from 'moment';

export const showError = (error) => {
  message.error(
    error && error.error_message ? error.error_message : COMMON_ERROR_MESSAGE,
  );
};

export const parseJson = (text, defaultValue = null) => {
  try {
    return JSON.parse(text) ?? defaultValue;
  } catch (e) {
    return defaultValue;
  }
};

export const readAndParseStorage = (key, defaultValue = null) => {
  return parseJson(localStorage.getItem(key), defaultValue);
};

export const selectedTimeToTimeString = ({ selectedTime, selectedDate }) => {
  if (!selectedTime || !selectedDate) {
    return null;
  }
  return selectedTime.startT
    ? moment(selectedDate.fullday)
        .tz('Asia/Kuala_Lumpur')
        .set('hour', selectedTime.startT.split(':')[0])
        .set('minute', selectedTime.startT.split(':')[1])
        .format()
    : null;
};
