import React, { useState, useEffect } from 'react';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import { setUserLogin } from '_actions/authentication.action';
import { useDispatch } from 'react-redux';
import { setShowTerms } from '_actions/effect.action';
import { USER_LOGIN } from '_constants';
import { readAndParseStorage } from '../../_helper';

const ProfileDialog = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const logOut = () => {
    localStorage.clear();
    dispatch(setUserLogin(null));
    props.onToggle();
    window.location.href = '/';
  };

  const [user, setUser] = useState(null);
  useEffect(() => {
    const userLogin = readAndParseStorage(USER_LOGIN);
    if (userLogin) {
      setUser(userLogin);
    }
  }, []);

  return (
    <>
      <div className="popup-dropdown">
        <Card>
          <CardHeader>
            <h2>
              {user && user.first_name && user.last_name
                ? `${user.first_name} ${user.last_name}`
                : 'Welcome, '}{' '}
            </h2>
            <Button
              className=""
              color="default"
              type="button"
              onClick={() => props.onToggle()}
            >
              <i className="fas fa-times" />
            </Button>
          </CardHeader>
          <CardBody>
            <div className=" _profile_dialog ">
              <div className="_body">
                <PerfectScrollbar>
                  <div style={{ maxHeight: 400 }}>
                    <Button
                      block
                      className="btn-icon btn-nav-item"
                      color="link"
                      type="button"
                      onClick={() => {
                        history.push('/profile');
                        props.onToggle();
                      }}
                    >
                      <span className="btn-inner--text">my profile</span>
                      <span className="btn-inner--icon">
                        <i className="fas fa-chevron-right" />
                      </span>
                    </Button>
                    <Button
                      block
                      className="btn-icon btn-nav-item"
                      color="link"
                      type="button"
                      onClick={() => {
                        history.push('/orders');
                        props.onToggle();
                      }}
                    >
                      <span className="btn-inner--text">my orders</span>
                      <span className="btn-inner--icon">
                        <i className="fas fa-chevron-right" />
                      </span>
                    </Button>
                    {/* <Button
                      block
                      className="btn-icon btn-nav-item"
                      color="link"
                      type="button"
                    >
                      <span className="btn-inner--text">payment methods</span>
                      <span className="btn-inner--icon">
                        <i className="fas fa-chevron-right" />
                      </span>
                    </Button> */}
                    <div className="_apps text-center">
                      <p>
                        <strong>GET THE APP</strong>
                      </p>
                      <p>MORE FEATURES AWAIT</p>
                      <div className="_icon">
                        <a
                          href="https://thesocial.tsunago.asia/mobile/download"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            alt="..."
                            src={require('assets/img/icons/common/app-store.svg')}
                          />
                        </a>
                        <a
                          href="https://thesocial.tsunago.asia/mobile/download"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <img
                            alt="..."
                            src={require('assets/img/icons/common/gg-store.svg')}
                          />
                        </a>
                      </div>
                    </div>
                    <p className="text-center">
                      <a
                        href="/#bro"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(setShowTerms(true));
                        }}
                      >
                        privacy policy
                      </a>{' '}
                      •{' '}
                      <a
                        href="/#bro"
                        onClick={(e) => {
                          e.preventDefault();
                          dispatch(setShowTerms(true));
                        }}
                      >
                        terms of use
                      </a>
                    </p>
                  </div>
                </PerfectScrollbar>
              </div>
              <div className="_footer">
                <Button block color="link" type="button" onClick={logOut}>
                  Logout
                </Button>
              </div>
            </div>
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default ProfileDialog;
