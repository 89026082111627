/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect } from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import TagManager from 'react-gtm-module';
import {
  GET_ALL_MEAL_BY_CATEGORY_ID,
  GET_ALL_MAIN_MEALS,
} from '../../api/APIs';
import { useHistory } from 'react-router-dom';
import axios from '../../api/CustomAxios';
import { useSelector, useDispatch } from 'react-redux';
import Food from './Food';
import { setSelectedMainMeal } from '../../_actions/mainMeal.action';
import { SELECTED_RESTAURANT, SELECTED_TIME, VENUES_DATA } from '_constants';
import { readAndParseStorage, selectedTimeToTimeString } from '../../_helper';

const tagManagerArgs = {
  dataLayer: {
    page: 'Home',
  },
  dataLayerName: 'PageDataLayer',
};

const CategoryDetail = (props) => {
  TagManager.dataLayer(tagManagerArgs);
  const history = useHistory();
  const dispatch = useDispatch();
  const selectedMainMeal = useSelector(
    (state) => state.mainMeal.selectedMainMeal,
  );
  const selectedTime = useSelector((state) => state.global.selectedTime);
  const isAppReady = useSelector((state) => state.global.isAppReady);
  const selectedRestaurant = useSelector(
    (state) => state.global.selectedRestaurant,
  );
  const fetchCategoryFoods = useCallback(() => {
    console.log('isAppReady: ', isAppReady);
    // debugger
    if (!isAppReady) {
      return;
    }
    let venuesStorage = readAndParseStorage(VENUES_DATA);
    let restaurantStorage = readAndParseStorage(SELECTED_RESTAURANT);
    const actualSelectedTime =
      selectedTime ?? readAndParseStorage(SELECTED_TIME);
    if (
      props.match &&
      props.match.params &&
      props.match.params.id &&
      venuesStorage &&
      restaurantStorage
    ) {
      const id = props.match.params.id;
      axios
        .get(`${GET_ALL_MAIN_MEALS}/${id}`, {
          dataHeaders: {
            venueID: venuesStorage.id,
            brandID: restaurantStorage.id,
          },
        })
        .then((resCategory) => {
          const timeSlot = selectedTimeToTimeString({
            selectedTime: actualSelectedTime?.valueSelectTime,
            selectedDate: actualSelectedTime?.valueDay,
          });
          axios
            .get(
              `${GET_ALL_MEAL_BY_CATEGORY_ID}${id}&page=1&per_page=999&time_slot=${timeSlot}`,
              {
                dataHeaders: {
                  venueID: venuesStorage.id,
                  brandID: restaurantStorage.id,
                },
              },
            )
            .then((resProducts) => {
              const dataFilter = resProducts.data.filter(
                (option) => option.status !== 'inactive',
              );
              dispatch(
                setSelectedMainMeal({
                  ...resCategory.data,
                  products: [...dataFilter],
                }),
              );
            });
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTime, isAppReady]);

  useEffect(() => {
    fetchCategoryFoods();
  }, [fetchCategoryFoods]);

  return (
    <>
      {selectedMainMeal && (
        <Container className="component_detail">
          <Row>
            <Col md="12" className="component_detail_content">
              <div className="_header">
                <h1>{selectedMainMeal.title}</h1>
                <p>{selectedMainMeal.description}</p>
                <Button
                  className="close_detail_btn second"
                  color="default"
                  type="button"
                  onClick={() => {
                    dispatch(setSelectedMainMeal(null));
                    history.push(`/${selectedRestaurant.id}/home`);
                  }}
                >
                  <i className="fas fa-times" />
                </Button>
              </div>
              <Container className="_body">
                <Row>
                  <Col className="pl-0 pr-0">
                    {selectedMainMeal && (
                      <Food category={selectedMainMeal} md={4} />
                    )}
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      )}
    </>
  );
};
export default CategoryDetail;
