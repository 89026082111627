import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import TagManager from 'react-gtm-module';
import { setUserLogin } from '_actions/authentication.action';
import { setSelectedMainMeal } from '../../_actions/mainMeal.action';
import { GET_BRAND_USER } from '../../api/APIs';
import { USER_LOGIN } from '_constants';
import moment from 'moment';
import { message } from 'antd';
import { showError } from '../../_helper/index';

const tagManagerArgs = {
  dataLayer: {
    page: 'Home',
  },
  dataLayerName: 'PageDataLayer',
};

const MyProfile = (props) => {
  TagManager.dataLayer(tagManagerArgs);
  const history = useHistory();
  const dispatch = useDispatch();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const userLogin = useSelector((state) => state.authentication.userLogin);

  useEffect(() => {
    if (userLogin) {
      setFirstName(userLogin.first_name);
      setLastName(userLogin.last_name);
    }
  }, [userLogin]);

  const update = () => {
    //call api update
    fetch(GET_BRAND_USER + `/${userLogin.id}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        'API-KEY': userLogin.api_key,
      },
      body: JSON.stringify({
        ...userLogin,
        first_name: firstName,
        last_name: lastName,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        console.log(results);
        if (results.success) {
          dispatch(setUserLogin(results.body));
          localStorage.setItem(USER_LOGIN, JSON.stringify(results.body));
          message.success('Update success');
        } else {
          message.error(results.error_message);
        }
      })
      .catch((err) => {
        showError(err);
      });
  };

  if (!userLogin) {
    return '';
  }

  return (
    <>
      <Container className="component_detail">
        <Row>
          <Col md="12" className="component_detail_content">
            <div className="_header">
              <h1>MY PROFILE</h1>
              <p>your information can be update here</p>
              <Button
                className="close_detail_btn second"
                color="default"
                type="button"
                onClick={() => {
                  dispatch(setSelectedMainMeal(null));
                  history.push('/');
                }}
              >
                <i className="fas fa-times" />
              </Button>
            </div>
            <Container className="_body">
              <Row>
                <Col className="pr-lg-4 pl-lg-4">
                  <div className="profile">
                    <Row>
                      <Col md="6" sm="12">
                        <div className="cus-input">
                          <p>Account Number</p>
                          <input
                            readOnly={true}
                            value={userLogin.formatted_bepoz_card_number}
                          />
                        </div>
                      </Col>
                      <Col md="6" sm="12">
                        <div className="cus-input">
                          <p>Card Number</p>
                          <input
                            readOnly={true}
                            value={userLogin.formatted_bepoz_card_number}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" sm="12">
                        <div className="cus-input">
                          <p>First Name</p>
                          <input
                            value={firstName}
                            onChange={(e) => setFirstName(e.target.value)}
                          />
                        </div>
                      </Col>
                      <Col md="6" sm="12">
                        <div className="cus-input">
                          <p>Last Name</p>
                          <input
                            value={lastName}
                            onChange={(e) => setLastName(e.target.value)}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" sm="12">
                        <div className="cus-input">
                          <p>Email</p>
                          <input readOnly={true} value={userLogin.email} />
                        </div>
                      </Col>
                      <Col md="6" sm="12">
                        <div className="cus-input">
                          <p>phone</p>
                          <input
                            readOnly={true}
                            value={userLogin.mobile_phone_number}
                          />
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6" sm="12">
                        <div className="cus-input">
                          <p>Birthday</p>
                          <input
                            readOnly={true}
                            value={moment(userLogin.birth_date).format(
                              'DD MMM, YYYY',
                            )}
                          />
                        </div>
                      </Col>
                    </Row>
                    <div className="update">
                      <Button
                        className="col-12"
                        color="primary"
                        type="button"
                        onClick={update}
                      >
                        UPDATE
                      </Button>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default MyProfile;
