import React, {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import {Button, CardBody, Card, CardHeader, CardFooter} from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setPickRestaurantModal } from '../../_actions/effect.action';
import { setCartItems } from '../../_actions/cart.action';
import {
  setSelectedRestaurant,
  setSelectedVenue,
  setSelectedAddress,
  setSelectedTime,
  setOrderUserItems,
} from '../../_actions/global.action';
import axios from '../../api/CustomAxios';
import { GET_VENUES, GET_ORDERS } from '../../api/APIs';
import {
  SELECTED_RESTAURANT,
  VENUES_DATA,
  SELECTED_ADDRESS,
  SELECTED_TIME,
  CART,
  USER_LOGIN,
  ORDER_USER, DISMISSED_POPUPS,
} from '../../_constants/index';
import {Checkbox, Modal} from 'antd';
import { showError, readAndParseStorage } from '../../_helper/index';

const { confirm } = Modal;

const PickRestaurant = () => {
  const history = useHistory();

  const [popup, setPopup] = useState(null)
  const restaurants = useSelector((state) => state.global.restaurants);
  const selectedRestaurant = useSelector(
    (state) => state.global.selectedRestaurant,
  );
  const dispatch = useDispatch();
  const pickRestaurantModal = useSelector(
    (state) => state.effect.pickRestaurantModal,
  );

  const handlePopupCheckboxChange = (e) => {
    setPopup({...popup, remember: e.target.checked})
  }

  useEffect(() => {
    if (selectedRestaurant?.popup_enabled) {
      const popupData = {
        enabled: selectedRestaurant.popup_enabled,
        message: selectedRestaurant.popup_message,
        title: selectedRestaurant.popup_title,
        key: `${selectedRestaurant.id}__${selectedRestaurant.popup_title}`,
        remember: false
      }
      if (popupData.enabled && !!popupData.title) {
        const dismissedPopups = readAndParseStorage(DISMISSED_POPUPS, [])
        if (!dismissedPopups.includes(popupData.key)) {
          setPopup(popupData)
        }
      }
    }
  }, [selectedRestaurant])

  const handleDismissPopup = () => {
    if (popup.remember) {
      const dismissedPopups = readAndParseStorage(DISMISSED_POPUPS, [])
      localStorage.setItem(DISMISSED_POPUPS, JSON.stringify([...dismissedPopups, popup.key]))
    }
    setPopup(null)
  }

  const pickRestaurant = (index) => {
    const selectedRes = restaurants[index];
    dispatch(setPickRestaurantModal(false));

    axios
      .get(GET_VENUES + `?brand_id=${selectedRes.id}`)
      .then((resVenues) => {
        dispatch(setSelectedRestaurant(selectedRes));
        localStorage.setItem(SELECTED_RESTAURANT, JSON.stringify(selectedRes));


        dispatch(setSelectedVenue(resVenues.data[0] ?? { _id: new Date() }));
        localStorage.setItem(VENUES_DATA, JSON.stringify(resVenues.data[0] ?? { _id: new Date() }));

        dispatch(setSelectedAddress(null));
        localStorage.removeItem(SELECTED_ADDRESS);

        dispatch(setSelectedTime(null));
        localStorage.removeItem(SELECTED_TIME);

        dispatch(setCartItems([]));
        localStorage.removeItem(CART);

        dispatch(setOrderUserItems([]));
        localStorage.removeItem('ACTIVE_TAB');
        localStorage.removeItem(ORDER_USER);
        history.push(`/${selectedRes.id}/home`);
      })
      .catch((error) => {
        showError(error);
      });
  };

  return (
    <>
      <Modal
        visible={popup?.enabled}
        closable={false}
        footer={null}
        width={350}
        size="sm"
        className="brand-notify-popup"
      >
        <Card className="brand-notify-card">
          <CardHeader className="brand-notify-card__header">
            <strong>{popup?.title}</strong>
          </CardHeader>
          <CardBody className="brand-notify-card__body">
            {popup?.message}
          </CardBody>
          <CardFooter className="brand-notify-card__footer">
            <Button size="md" block color="primary" onClick={handleDismissPopup}>OKAY</Button>
            <Checkbox checked={popup?.remember} onChange={handlePopupCheckboxChange} className="dont-show-checkbox">Don't show this again</Checkbox>
          </CardFooter>
        </Card>

      </Modal>
      <Modal
        closable={false}
        className="modal-dialog-centered social_dialog restaurant_dialog"
        size="sm"
        visible={pickRestaurantModal}
        toggle={() => dispatch(setPickRestaurantModal(!pickRestaurantModal))}
        footer={null}
        backdropClassName="social_dialog__bg"
        width={400}
      >
        <div className="modal-body p-0">
          <Card className="border-0">
            <CardHeader>
              <h3>PICK A RESTAURANT</h3>
              <Button
                className=""
                color="default"
                type="button"
                onClick={() =>
                  dispatch(setPickRestaurantModal(!pickRestaurantModal))
                }
              >
                <i className="fas fa-times" />
              </Button>
            </CardHeader>
            <CardBody className="pl-0 pr-0">
              {restaurants.map((item, index) => {
                return (
                  <div
                    className={`restaurant__btn ${
                      selectedRestaurant && selectedRestaurant.id === item.id
                        ? 'active'
                        : ''
                    }`}
                    key={index}
                    onClick={() => {
                      if (
                        selectedRestaurant &&
                        selectedRestaurant.id !== item.id
                      ) {
                        const orderUser = readAndParseStorage(ORDER_USER);
                        if (orderUser) {
                          confirm({
                            className: 'modalConfirm',
                            title:
                              'In Order to change Restaurant, your bag will be cleared.',
                            content: 'Are you sure?',
                            icon: '',
                            okText: 'YES, CLEAR',
                            cancelText: 'CANCEL',
                            onOk() {
                              const userLogin = readAndParseStorage(USER_LOGIN);
                              fetch(GET_ORDERS + 'cart', {
                                method: 'delete',
                                headers: {
                                  'Content-Type': 'application/json',
                                  'X-Customer-Id': userLogin
                                    ? userLogin.id
                                    : '',
                                  'X-Customer-Api-Key': userLogin
                                    ? userLogin.api_key
                                    : '',
                                },
                              })
                                .then((response) => {
                                  return response.json();
                                })
                                .then((results) => {
                                  pickRestaurant(index);
                                })
                                .catch((err) => {
                                  if (err) {
                                    pickRestaurant(index);
                                  }
                                });
                            },
                            onCancel() {
                              console.log('Cancel');
                            },
                          });
                        } else {
                          pickRestaurant(index);
                        }
                      }
                    }}
                  >
                    <img alt="..." src={item.logo_url} />
                    <div className="pick_restaurant__text">
                      <div className="custom__btn_name">
                        <h2>{item.name}</h2>
                        <span>{item.description}</span>
                      </div>
                      <div>
                        <i className="fas fa-chevron-down" />
                      </div>
                    </div>
                  </div>
                );
              })}
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};
export default PickRestaurant;
