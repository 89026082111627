import React, { useState, useMemo } from 'react';
import '../../assets/scss/stripe.scss';
import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement,
} from '@stripe/react-stripe-js';
import CheckoutError from './CheckoutError';
import stripeSecImg from 'assets/img/stripe-badge-white.svg';
import { Button } from 'reactstrap';
import { useDispatch } from 'react-redux';
import { setProcessingPayment } from '../../_actions/global.action';

const useOptions = () => {
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize: '16px',
          color: '#424770',
          letterSpacing: '0.025em',
          fontFamily: 'Source Code Pro, monospace',
          '::placeholder': {
            color: '#aab7c4',
          },
        },
        invalid: {
          color: '#9e2146',
        },
      },
    }),
    [],
  );

  return options;
};

const CreditStripeCheckoutForm = ({ price, onSuccessfulCheckout, intent }) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();
  const [isProcessing, setProcessingTo] = useState(false);
  const dispatch = useDispatch();

  const [checkoutError, setCheckoutError] = useState();

  const handleCardDetailsChange = (ev) => {
    ev.error ? setCheckoutError(ev.error.message) : setCheckoutError();
  };

  const handleSubmit = async (ev) => {
    // Block native form submission.
    ev.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    // Get a reference to a mounted CardElement. Elements knows how
    // to find your CardElement because there can only ever be one of
    // each type of element.

    setProcessingTo(true);
    dispatch(setProcessingPayment(true));
    console.log(intent);
    const cardElement = elements.getElement(CardNumberElement);
    try {
      const paymentMethodReq = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
      });

      if (paymentMethodReq.error) {
        setCheckoutError(paymentMethodReq.error.message);
        setProcessingTo(false);
        dispatch(setProcessingPayment(false));
        return;
      }

      const confirmedCardPayment = await stripe.confirmCardPayment(
        intent.client_secret,
        {
          payment_method: paymentMethodReq.paymentMethod.id,
        },
      );

      console.log(confirmedCardPayment);

      if (confirmedCardPayment.error) {
        setCheckoutError(confirmedCardPayment.error.message);
        setProcessingTo(false);
        dispatch(setProcessingPayment(false));
        return;
      }

      onSuccessfulCheckout();
    } catch (err) {
      setCheckoutError(err.message);
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit} className="stripe_payment">
        <label>
          Card number
          <CardNumberElement
            options={options}
            onChange={handleCardDetailsChange}
          />
        </label>
        <label>
          Expiration date
          <CardExpiryElement
            options={options}
            onChange={handleCardDetailsChange}
          />
        </label>
        <label>
          CVC
          <CardCvcElement
            options={options}
            onChange={handleCardDetailsChange}
          />
        </label>
        {checkoutError && <CheckoutError>{checkoutError}</CheckoutError>}
        <Button
          color="primary"
          block
          type="submit"
          className="mb-4 mt-4 btn_apply"
          disabled={!stripe || isProcessing}
        >
          {isProcessing ? 'Processing...' : `Pay ${price}`}
        </Button>
      </form>
      <div className="d-flex justify-content-center flex-column align-items-center">
        <img
          src={stripeSecImg}
          alt="Stripe - Trust badge"
          title="Stripe - Trust badge"
          style={{ width: '332px', margin: 0 }}
        />
      </div>
    </>
  );
};

export default CreditStripeCheckoutForm;
