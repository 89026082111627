import React, {useEffect, useMemo, useState} from 'react';
// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  CardFooter,
  InputGroup,
  InputGroupText,
  InputGroupAddon,
  Row,
  Col
} from 'reactstrap';
import { Tabs, Radio, Space, message, Select } from 'antd';

import { setAddAdress, setCustomOverlay } from '../../_actions/effect.action';
import { useDispatch, useSelector } from 'react-redux';
import axios from '../../api/CustomAxios';
import {
  GET_ADDRESSES,
  GET_VENUES,
  POST_VENUES_PICKUP,
  GET_ORDERS,
} from '../../api/APIs';
import {
  setSelectedAddress,
  setSelectedVenue,
  setShowPopupAddress,
  setLngLat,
  setSelectedTime,
  setOrderUserItems, setDeliveryVenues,
} from '../../_actions/global.action';
import { setCartItems } from '../../_actions/cart.action';
import {
  SELECTED_ADDRESS,
  VENUES_DATA,
  LNG_LAT,
  SELECTED_TIME,
  ORDER_USER,
  CART, DELIVERY_VENUES,
} from '_constants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { Modal } from 'antd';
import moment from 'moment';
import { readAndParseStorage, showError } from '../../_helper/index';
import DeliveryVenueSelect from "./DeliveryVenueSelect";

const { Option } = Select

const { confirm } = Modal;

const { TabPane } = Tabs;

function array_move(arr, old_index, new_index) {
  if (new_index >= arr.length) {
    var k = new_index - arr.length + 1;
    while (k--) {
      arr.push(undefined);
    }
  }
  arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
  return arr;
}

function formatDate() {
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

const roundTime = (time, minutesToRound, bonusTime) => {
  var s = new Date();

  let [hours, minutes] = time.split(':');
  s.setHours(hours);
  s.setMinutes(parseInt(minutes) + bonusTime);

  hours = s.getHours();
  minutes = s.getMinutes();

  time = hours * 60 + minutes;

  let rounded = Math.round(time / minutesToRound) * minutesToRound;
  let rHr = '' + Math.floor(rounded / 60);

  let rMin = '' + (rounded % 60);

  return rHr.padStart(2, '0') + ':' + rMin.padStart(2, '0');
};

var optionsDayDefault = [
  {
    data: [],
    dataSplit: [],
    value: 'sunday',
    label: 'Sunday',
    fullday: '',
  },
  {
    data: [],
    dataSplit: [],
    value: 'monday',
    label: 'Monday',
    fullday: '',
  },
  {
    data: [],
    dataSplit: [],
    value: 'tuesday',
    label: 'Tuesday',
    fullday: '',
  },
  {
    data: [],
    dataSplit: [],
    value: 'wednesday',
    label: 'Wednesday',
    fullday: '',
  },
  {
    data: [],
    dataSplit: [],
    value: 'thursday',
    label: 'Thursday',
    fullday: '',
  },
  {
    data: [],
    dataSplit: [],
    value: 'friday',
    label: 'Friday',
    fullday: '',
  },
  {
    data: [],
    dataSplit: [],
    value: 'saturday',
    label: 'Saturday',
    fullday: '',
  },
];

const DeliveryLocation = (props) => {
  const [tmpSelectedAddress, setTmpSelectedAddress] = useState(null);
  const [tmpSelectedPickup, setTmpSelectedPickup] = useState(null);
  const [tmpDeliveryVenueId, setTmpDeliveryVenueId] = useState(null);
  const [tmpDeliveryVenues, setTmpDeliveryVenues] = useState(null);
  const isMobile = useSelector((state) => state.global.isMobile)
  const maxAddressListHeight = useMemo(() => {
    if (isMobile) {
      return window.innerHeight - 460
    } else {
      return 300
    }
  }, [isMobile])
  const [activeTab, setActiveTab] = useState(
    props.saveLocation
      ? 'delivery'
      : localStorage.getItem('ACTIVE_TAB')
      ? localStorage.getItem('ACTIVE_TAB')
      : 'delivery',
  );
  const [listAddress, setListAddress] = useState(null);
  const [listVenues, setListVenues] = useState(null);
  const [loadingGetLocation, setLoadingGetLocation] = useState(false);
  const [optionsDay] = useState(optionsDayDefault);

  const selectedAddress = useSelector((state) => state.global.selectedAddress);
  const userLogin = useSelector((state) => state.authentication.userLogin);
  const selectedRestaurant = useSelector(
    (state) => state.global.selectedRestaurant,
  );
  const globalSetting = useSelector((state) => state.global.globalSetting);
  const lngLat = useSelector((state) => state.global.lngLat);
  const dispatch = useDispatch();

  const callback = (key) => {
    setActiveTab(`${key}`);
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(geocodeLatLng);
    }
    setLoadingGetLocation(!loadingGetLocation);
    function geocodeLatLng(position) {
      if (position) {
        const latlng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        setLoadingGetLocation(!loadingGetLocation);
        dispatch(setLngLat(latlng));
        localStorage.setItem(LNG_LAT, JSON.stringify(latlng));
      } else {
        console.log('error');
      }
    }
  };

  const handleSelectedVenueChange = (venueId) => {
    setTmpDeliveryVenueId(venueId)
  }

  const selectWeek = (day) => {
    day.start_time = roundTime(
      day.start_time,
      30,
      globalSetting && globalSetting.factor_time,
    );
    day.end_time = roundTime(day.end_time, 30, 0);
    let listTime = [];
    var start = moment.tz(
      `${formatDate()} ${day.start_time}`,
      'Asia/Kuala_Lumpur',
    );
    var end = moment.tz(`${formatDate()} ${day.end_time}`, 'Asia/Kuala_Lumpur');
    let idx = 0;
    while (start < end) {
      let startT = start.format();
      start = start.add(30, 'minutes');
      let endT = start.format();
      idx++;

      listTime.push({
        id: idx,
        startT: moment.tz(startT, 'Asia/Kuala_Lumpur').format('HH:mm'),
        endT: moment.tz(endT, 'Asia/Kuala_Lumpur').format('HH:mm'),
      });
    }
    switch (day.week_day) {
      case 'sunday':
        optionsDay[0].data.push(day);
        let resultSunday = optionsDay[0].dataSplit.concat(listTime);
        optionsDay[0].dataSplit = resultSunday;
        break;
      case 'monday':
        optionsDay[1].data.push(day);
        let resultMonday = optionsDay[1].dataSplit.concat(listTime);
        optionsDay[1].dataSplit = resultMonday;
        break;
      case 'tuesday':
        optionsDay[2].data.push(day);
        let resultTuesday = optionsDay[2].dataSplit.concat(listTime);
        optionsDay[2].dataSplit = resultTuesday;
        break;
      case 'wednesday':
        optionsDay[3].data.push(day);
        let resultWednesday = optionsDay[3].dataSplit.concat(listTime);
        optionsDay[3].dataSplit = resultWednesday;
        break;
      case 'thursday':
        optionsDay[4].data.push(day);
        let resultThursday = optionsDay[4].dataSplit.concat(listTime);
        optionsDay[4].dataSplit = resultThursday;
        break;
      case 'friday':
        optionsDay[5].data.push(day);
        let resultFriday = optionsDay[5].dataSplit.concat(listTime);
        optionsDay[5].dataSplit = resultFriday;
        break;
      case 'saturday':
        optionsDay[6].data.push(day);
        let resulSaturday = optionsDay[6].dataSplit.concat(listTime);
        optionsDay[6].dataSplit = resulSaturday;
        break;
      default:
        break;
    }
  };

  const onChangeAddress = (e) => {
    setTmpSelectedAddress(e.target.valueChecked)
  };

  const onChangePickup = (e) => {
    setTmpSelectedPickup(e.target.valueChecked);
  };

  useEffect(() => {
    setLoadingGetLocation(true)
    if (tmpSelectedAddress?.id) {
      getClosestVenues()
        .then((results) => {
          if (!results.length || !results.find((it) => it.id === tmpDeliveryVenueId)) {
            setTmpDeliveryVenueId(results[0]?.id);
          }
          setTmpDeliveryVenues(results);
        })
        .finally(() => {
          setLoadingGetLocation(false)
        })
    }
    // eslint-disable-next-line
  }, [tmpSelectedAddress])

  useEffect(() => {
    if (!selectedRestaurant) return;
    let dataAddress = readAndParseStorage(SELECTED_ADDRESS);
    let deliveryVenues = readAndParseStorage(DELIVERY_VENUES);
    let selectedVenue = readAndParseStorage(VENUES_DATA);
    let selectAdd = selectedAddress ? selectedAddress : dataAddress;
    if (activeTab === 'delivery') {
      axios.get(GET_ADDRESSES).then((res) => {
        if (res.data.length > 0) {
          let dataFinalAddress = [...res.data];
          if (selectAdd && activeTab === 'delivery') {
            const idx = dataFinalAddress.findIndex(
              (opt) => opt.id === selectAdd.id,
            );
            if (idx >= 0) {
              dataFinalAddress = array_move(dataFinalAddress, idx);
              setTmpDeliveryVenueId(selectedVenue?.id)
              setTmpSelectedAddress(dataAddress)
            } else {
              setTmpDeliveryVenueId(null)
              setTmpSelectedAddress(null)
            }
          }
          setListAddress(dataFinalAddress);
          setTmpDeliveryVenues(deliveryVenues)
        }
      });
    } else if (lngLat) {
      axios
        .get(
          GET_VENUES +
            `?brand_id=${selectedRestaurant.id}&with_distance=true&lng=${lngLat.lng}&lat=${lngLat.lat}`,
        )
        .then((res) => {
          let dataFinalVenues = [...res.data];
          if (selectAdd && activeTab === 'pickup') {
            const idx = dataFinalVenues.findIndex(
              (opt) => opt.id === selectAdd.id,
            );
            dataFinalVenues = array_move(dataFinalVenues, idx);
            setTmpSelectedPickup(selectAdd)
          }

          setListVenues(dataFinalVenues);
        });
    }
    return () => {
      optionsDayDefault = [
        {
          data: [],
          dataSplit: [],
          value: 'sunday',
          label: 'Sunday',
          fullday: '',
        },
        {
          data: [],
          dataSplit: [],
          value: 'monday',
          label: 'Monday',
          fullday: '',
        },
        {
          data: [],
          dataSplit: [],
          value: 'tuesday',
          label: 'Tuesday',
          fullday: '',
        },
        {
          data: [],
          dataSplit: [],
          value: 'wednesday',
          label: 'Wednesday',
          fullday: '',
        },
        {
          data: [],
          dataSplit: [],
          value: 'thursday',
          label: 'Thursday',
          fullday: '',
        },
        {
          data: [],
          dataSplit: [],
          value: 'friday',
          label: 'Friday',
          fullday: '',
        },
        {
          data: [],
          dataSplit: [],
          value: 'saturday',
          label: 'Saturday',
          fullday: '',
        },
      ];
    };
  }, [dispatch, activeTab, lngLat, selectedAddress, selectedRestaurant]);

  const getClosestVenues = () => {
    return fetch(GET_VENUES + `/closest?is_get_all=true`, {
      method: 'get',
      headers: {
        'Content-Type': 'application/json',
        'X-Customer-Id': userLogin?.id ?? '',
        'X-Customer-Api-Key': userLogin?.api_key ?? '',
        'X-Brand-Id': selectedRestaurant?.id,
        'X-Address-Id': tmpSelectedAddress?.id,
      },
    })
      .then((response) => {
        return response.json();
      })
  }

  const updateLocation = (activeTab) => {
    if (!selectedRestaurant) return;
    // const userLogin = readAndParseStorage(USER_LOGIN);
    if (activeTab === 'delivery') {
      getClosestVenues()
        .then((results) => {
          if (!results.error_message) {
            const selectedVenue = results.find((it) => it.id === tmpDeliveryVenueId)
            dispatch(setSelectedVenue(selectedVenue));
            dispatch(setDeliveryVenues(results));
            dispatch(setCustomOverlay(false));
            dispatch(setShowPopupAddress(false));
            dispatch(setSelectedAddress(tmpSelectedAddress));

            localStorage.setItem(
              SELECTED_ADDRESS,
              JSON.stringify(tmpSelectedAddress),
            );
            localStorage.setItem(
              DELIVERY_VENUES,
              JSON.stringify(results),
            );
            localStorage.setItem('ACTIVE_TAB', activeTab);
            localStorage.setItem(VENUES_DATA, JSON.stringify(selectedVenue));

            // TIME

            for (
              let index = 0;
              index < selectedVenue.operation_times?.length;
              index++
            ) {
              selectWeek({ ...selectedVenue.operation_times[index] });
            }
            // date now
            let nowTime = moment().tz('Asia/Kuala_Lumpur').format('HH:mm');
            var s = new Date(moment().tz('Asia/Kuala_Lumpur').format());

            let [hours, minutes] = nowTime.split(':');
            s.setHours(hours);
            s.setMinutes(
              parseInt(minutes) +
                (globalSetting ? globalSetting.min_advance_ordertime : 0),
            );
            let timeUpdate = moment(s).format('HH:mm');

            let dataOptionsSlice1 = optionsDay.slice(
              0,
              moment().tz('Asia/Kuala_Lumpur').day(),
            );
            let dataOptionsSlice2 = optionsDay.slice(
              moment().tz('Asia/Kuala_Lumpur').day(),
              7,
            );
            let dataMerge = dataOptionsSlice2.concat(dataOptionsSlice1);
            let dataFilter = dataMerge[0].dataSplit.filter(
              (option) => option.startT > timeUpdate,
            );

            dataMerge[0].dataSplit = dataFilter;
            dataMerge[0].label = 'Today';
            dataMerge[0].value = 'today';

            for (let index = 0; index < dataMerge.length; index++) {
              dataMerge[index]['fullday'] = moment(new Date())
                .add('day', index)
                .tz('Asia/Kuala_Lumpur')
                .format('YYYY-MM-DD');
            }

            let valueDay = dataMerge[0];
            let valueSelectTime = { id: 'soon' };

            localStorage.setItem(
              SELECTED_TIME,
              JSON.stringify({
                dataMerge,
                valueDay,
                valueSelectTime,
              }),
            );
            dispatch(
              setSelectedTime({
                dataMerge,
                valueDay,
                valueSelectTime,
              }),
            );

            dispatch(setCartItems([]));
            dispatch(setOrderUserItems([]));
            localStorage.removeItem(CART);
            localStorage.removeItem(ORDER_USER);

            success();
          } else {
            message.error(results.error_message);
          }
        })
        .catch((error) => {
          if (error) {
            showError(error);
          }
        });
    } else {
      // Fetch POST_VENUES_PICKUP
      if (lngLat) {
        fetch(
          POST_VENUES_PICKUP +
            `?with_distance=true&lng=${lngLat.lng}&lat=${lngLat.lat}`,
          {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              'X-Customer-Id': userLogin ? userLogin.id : '',
              'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
              'X-Brand-Id': selectedRestaurant.id,
            },
            body: JSON.stringify({
              venue_id: tmpSelectedPickup?.id,
              distance: tmpSelectedPickup?.distance,
              estimated_time: tmpSelectedPickup?.estimated_time,
            }),
          },
        )
          .then((response) => {
            return response.json();
          })
          .then((results) => {
            if (results) {
              dispatch(setSelectedVenue(results));
              dispatch(setCustomOverlay(false));
              dispatch(setShowPopupAddress(false));
              dispatch(setSelectedAddress(results));
              localStorage.setItem(SELECTED_ADDRESS, JSON.stringify(results));
              localStorage.setItem('ACTIVE_TAB', activeTab);
              localStorage.setItem(VENUES_DATA, JSON.stringify(results));

              // TIME
              for (
                let index = 0;
                index < results.operation_times.length;
                index++
              ) {
                selectWeek({ ...results.operation_times[index] });
              }
              // date now
              let nowTime = moment().tz('Asia/Kuala_Lumpur').format('HH:mm');
              var s = new Date(moment().tz('Asia/Kuala_Lumpur').format());
              let [hours, minutes] = nowTime.split(':');
              s.setHours(hours);
              s.setMinutes(
                parseInt(minutes) +
                  (globalSetting ? globalSetting.min_advance_ordertime : 0),
              );
              let timeUpdate = moment(s).format('HH:mm');
              let dataOptionsSlice1 = optionsDay.slice(
                0,
                moment().tz('Asia/Kuala_Lumpur').day(),
              );
              let dataOptionsSlice2 = optionsDay.slice(
                moment().tz('Asia/Kuala_Lumpur').day(),
                7,
              );
              let dataMerge = dataOptionsSlice2.concat(dataOptionsSlice1);

              let dataFilter = dataMerge[0].dataSplit.filter(
                (option) => option.startT > timeUpdate,
              );

              dataMerge[0].dataSplit = dataFilter;
              dataMerge[0].label = 'Today';
              dataMerge[0].value = 'today';

              for (let index = 0; index < dataMerge.length; index++) {
                dataMerge[index]['fullday'] = moment(new Date())
                  .add('day', index)
                  .tz('Asia/Kuala_Lumpur')
                  .format('YYYY-MM-DD');
              }

              let valueDay = dataMerge[0];
              let valueSelectTime = { id: 'soon' };

              localStorage.setItem(
                SELECTED_TIME,
                JSON.stringify({
                  dataMerge,
                  valueDay,
                  valueSelectTime,
                }),
              );
              dispatch(
                setSelectedTime({
                  dataMerge,
                  valueDay,
                  valueSelectTime,
                }),
              );
              dispatch(setCartItems([]));
              dispatch(setOrderUserItems([]));
              localStorage.removeItem(CART);
              localStorage.removeItem(ORDER_USER);

              success();
            } else {
              showError({ message: 'Update address error' });
            }
          })
          .catch((err) => {
            if (err) {
              showError();
            }
          });
      }
    }
  };

  const success = () => {
    message.success('Update address success');
  };

  return (
    <>
      <div className="popup-dropdown popup_delivery address_popup">
        <Card>
          <CardHeader>
            <h2>LOCATION</h2>
            <Button
              className=""
              color="default"
              type="button"
              onClick={() => {
                props.onToggle();
              }}
            >
              <i className="fas fa-times" />
            </Button>
          </CardHeader>
          <CardBody className="pl-0 pr-0">
            <Tabs activeKey={activeTab} onChange={callback}>
              <TabPane tab="DELIVERY" key="delivery">
                <div className="pl-3 pr-3">
                  <InputGroup>
                    <input
                      id="input_type"
                      className="form-control"
                      type="text"
                      placeholder="Type in an adress"
                      onClick={() => {
                        dispatch(setAddAdress(true));
                        props.onToggle('addAdress');
                      }}
                    />
                    <InputGroupAddon addonType="append">
                      <InputGroupText>
                        <i className="fas fa-map-marker-alt" />
                      </InputGroupText>
                    </InputGroupAddon>
                  </InputGroup>
                  <h2 className="mt-3 mb-3">OR SELECT FROM SAVED ADDRESS</h2>
                  <PerfectScrollbar>
                    <div style={{ maxHeight: maxAddressListHeight }}>
                      <Radio.Group
                        onChange={onChangeAddress}
                        value={tmpSelectedAddress?.id}
                      >
                        <Space direction="vertical">
                          {listAddress &&
                            listAddress.map((value) => {
                              return (
                                <Radio
                                  value={value.id}
                                  valueChecked={value}
                                  key={value.id}
                                >
                                  <span className="text_over_ellip">
                                    <b>{value.name}</b> - {value.full_address}
                                  </span>
                                </Radio>
                              );
                            })}
                        </Space>
                      </Radio.Group>
                    </div>
                  </PerfectScrollbar>
                </div>
              </TabPane>
              <TabPane tab="PICK-UP" key="pickup">
                <div className="pl-3 pr-3">
                  {lngLat ? (
                    <>
                      <h2 className="mt-0 mb-3">CHOOSE PICK-UP LOCATION</h2>
                      <PerfectScrollbar>
                        <div style={{ maxHeight: maxAddressListHeight }}>
                          <Radio.Group
                            className="col-12 radio_pick_up p-0"
                            onChange={onChangePickup}
                            value={tmpSelectedPickup?.id}
                          >
                            <Space direction="vertical" className="col-12 p-0">
                              {listVenues &&
                                listVenues.map((value) => {
                                  return (
                                    <Radio
                                      value={value.id}
                                      valueChecked={value}
                                      className="col-12 pb-2 pl-0 pr-0 m-0"
                                      key={value.id}
                                    >
                                      <Row className="m-0">
                                        <Col xs="8" className="pl-0 pr-1">
                                          <b>{value.name}</b>
                                          <div className="detail_location text_over_ellip">
                                            {value.address}
                                          </div>
                                        </Col>
                                        <Col
                                          xs="4"
                                          className="pr-0 pl-0 text-right range"
                                        >
                                          <b>{value.distance.toFixed(1)}KM</b>
                                        </Col>
                                      </Row>
                                    </Radio>
                                  );
                                })}
                            </Space>
                          </Radio.Group>
                        </div>
                      </PerfectScrollbar>
                    </>
                  ) : (
                    <>
                      <h2 className="mt-0 mb-3">
                        PLEASE ALLOW US TO ACCESS YOUR LOCATION
                      </h2>
                      <Button
                        block
                        className=""
                        color="primary"
                        type="button"
                        disabled={loadingGetLocation}
                        onClick={getLocation}
                      >
                        {loadingGetLocation
                          ? 'Getting...'
                          : 'GET YOUR LOCATION'}
                      </Button>
                    </>
                  )}
                </div>
              </TabPane>
            </Tabs>
          </CardBody>
          {activeTab === 'delivery' ? (
            <CardFooter className="pl-3 pr-3">
              {
                tmpDeliveryVenues?.length && tmpSelectedAddress?.id &&
                (
                  <>
                    {
                      isMobile ? (
                        <>
                          <DeliveryVenueSelect
                            venues={tmpDeliveryVenues}
                            selectedVenueId={tmpDeliveryVenueId}
                            onChange={handleSelectedVenueChange}
                          />
                          <div style={{marginTop: '20px', height: '2px'}}>&nbsp;</div>
                        </>
                      ) : (
                        <Select
                          className="delivery-venue-select"
                          value={tmpDeliveryVenueId}
                          onChange={(venueId) => {
                            setTmpDeliveryVenueId(venueId)
                          }}
                        >
                          {
                            tmpDeliveryVenues.map((it) => {
                              return (
                                <Option key={it.id} value={it.id} className="hello-ipion">
                                  <div style={{paddingTop: '5px', paddingBottom: '5px', height: '40px'}}>
                                    <div className="inline float-left">{it.name}</div>
                                    <div className="inline float-right">{it.distance} KM</div>
                                  </div>
                                </Option>
                              )
                            })
                          }
                        </Select>
                      )
                    }
                  </>
                )
              }

              <Button
                block
                className="update-location-btn"
                color="primary"
                type="button"
                onClick={() => {
                  const orderUser = readAndParseStorage(ORDER_USER);
                  if (orderUser) {
                    confirm({
                      className: 'modalConfirm',
                      title:
                        'In Order to change Address/Location, your bag will be cleared.',
                      content: 'Are you sure?',
                      icon: '',
                      okText: 'YES, CLEAR',
                      cancelText: 'CANCEL',
                      onOk() {
                        // const userLogin = readAndParseStorage(USER_LOGIN);
                        fetch(GET_ORDERS + 'cart', {
                          method: 'delete',
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Customer-Id': userLogin ? userLogin.id : '',
                            'X-Customer-Api-Key': userLogin
                              ? userLogin.api_key
                              : '',
                          },
                        })
                          .then((response) => {
                            return response.json();
                          })
                          .then((results) => {
                            dispatch(setCartItems([]));
                            dispatch(setOrderUserItems([]));
                            localStorage.removeItem(CART);
                            localStorage.removeItem(ORDER_USER);
                            updateLocation(activeTab);
                          })
                          .catch((err) => {
                            if (err) {
                              updateLocation(activeTab);
                            }
                          });
                      },
                      onCancel() {
                        console.log('Cancel');
                      },
                    });
                  } else {
                    updateLocation(activeTab);
                  }
                }}
              >
                UPDATE
              </Button>
            </CardFooter>
          ) : lngLat ? (
            <CardFooter className="pl-3 pr-3">
              <Button
                block
                className="update-location-btn"
                color="primary"
                type="button"
                onClick={() => {
                  const orderUser = readAndParseStorage(ORDER_USER);
                  if (orderUser) {
                    confirm({
                      className: 'modalConfirm',
                      title:
                        'In Order to change Address/Location, your bag will be cleared.',
                      content: 'Are you sure?',
                      icon: '',
                      okText: 'YES, CLEAR',
                      cancelText: 'CANCEL',
                      onOk() {
                        // const userLogin = readAndParseStorage(USER_LOGIN);
                        fetch(GET_ORDERS + 'cart', {
                          method: 'delete',
                          headers: {
                            'Content-Type': 'application/json',
                            'X-Customer-Id': userLogin ? userLogin.id : '',
                            'X-Customer-Api-Key': userLogin
                              ? userLogin.api_key
                              : '',
                          },
                        })
                          .then((response) => {
                            return response.json();
                          })
                          .then((results) => {
                            updateLocation(activeTab);
                          })
                          .catch((err) => {
                            if (err) {
                              updateLocation(activeTab);
                            }
                          });
                      },
                      onCancel() {
                        console.log('Cancel');
                      },
                    });
                  } else {
                    updateLocation(activeTab);
                  }
                }}
              >
                UPDATE
              </Button>
            </CardFooter>
          ) : (
            ''
          )}
        </Card>
      </div>
    </>
  );
};

export default DeliveryLocation;
