import { SET_LOGGED_IN, SET_USER_LOGIN } from '_constants/actionType';

const initialState = {
  isLogged: false,
  userLogin: null,
};
export function authentication(state = initialState, action) {
  switch (action.type) {
    case SET_LOGGED_IN:
      return {
        ...state,
        isLogged: action.isLogged,
      };
    case SET_USER_LOGIN:
      return {
        ...state,
        userLogin: action.userLogin,
      };
    default:
      return state;
  }
}
