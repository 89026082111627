import React, { useMemo, useState } from 'react';
import {
  Button,
  CardBody,
  Card,
  InputGroup,
  InputGroupText,
  Input,
  InputGroupAddon,
  FormGroup,
  FormText,
} from 'reactstrap';
import {
  Modal,
  Statistic,
  Spin,
  Form,
  Checkbox,
  DatePicker,
  message,
} from 'antd';
import { LeftCircleOutlined } from '@ant-design/icons';
import ReactCodeInput from 'react-verification-code-input';
import StepWizard from 'react-step-wizard';
import { isValidPhoneNumber } from 'libphonenumber-js';
import moment from 'moment';

import { useSelector, useDispatch } from 'react-redux';
import { setSignInModal } from '../../_actions/effect.action';
import {
  POST_LOGIN,
  RESEND_CODE,
  VERIFY_LOGIN,
  POST_SIGNUP,
  VERIFY,
} from '../../api/APIs';
import { SIGN_IN_TYPE, USER_LOGIN } from '_constants';
import { showError } from '../../_helper/index';

const area_code_phone = '+60';

const dateFormatList = ['DD/MM/YYYY', 'DD/MM/YY'];

const { Countdown } = Statistic;

const Step1 = (props) => {
  const [loading, setLoading] = useState(false);
  const { referredBy } = useSelector((state) => state.effect.referral);
  const dispatch = useDispatch();
  const [mess, setMess] = useState(null);

  const onLogin = () => {
    setLoading(true);
    fetch(POST_LOGIN, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mobile_phone_number: area_code_phone + props.dataProps.phoneNumber,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        if (results.success) {
          props.nextStep();
          setLoading(false);
        } else {
          setLoading(false);
          message.error({
            content: results.message,
            duration: 5,
            style: {
              marginTop: '20vh',
            },
          });
          dispatch(
            setSignInModal({
              status: true,
              type: SIGN_IN_TYPE.SIGN_UP,
            }),
          );
        }
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
          showError(err);
        }
      });
  };

  const onSignUp = () => {
    setLoading(true);
    fetch(POST_SIGNUP, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mobile_phone_number: area_code_phone + props.dataProps.phoneNumber,
        referred_by: referredBy,
        agent: 'store_front',
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        if (results.success) {
          props.nextStep();
          setLoading(false);
        } else {
          if (results.success === undefined) {
            setLoading(false);
            dispatch(
              setSignInModal({
                status: true,
                type: SIGN_IN_TYPE.SIGN_IN,
              }),
            );
            message.error({
              content: results.message,
              duration: 3,
              style: {
                marginTop: '20vh',
              },
            });
          } else {
            setLoading(false);
            message.error({
              content: results.message,
              duration: 3,
              style: {
                marginTop: '20vh',
              },
            });
          }
        }
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
          showError(err);
        }
      });
  };

  return (
    <Card className="border-0">
      <Spin tip="Loading..." spinning={loading}>
        <CardBody className="text-center p-0">
          <div className="form_input_phone_number">
            <FormGroup>
              <InputGroup>
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <b>{area_code_phone}</b>
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="ENTER YOUR PHONE NUMBER"
                  type="tel"
                  value={props.dataProps.phoneNumber}
                  onChange={(event) => {
                    const regex = /^[0-9]*$/gm;
                    const value = event.target.value;
                    if (regex.test(value)) {
                      props.dataProps.setPhoneNumber(value);
                      setMess(false);
                    } else {
                      setMess('Please input number only.');
                    }
                  }}
                  onKeyUp={(event) => {
                    const value = event.target.value;
                    if (event.keyCode === 13) {
                      const phoneNumber = isValidPhoneNumber(`0${value}`, 'MY');
                      if (phoneNumber) {
                        if (props.dataProps.signInModal.type === 'SIGN IN') {
                          onLogin();
                        } else {
                          onSignUp();
                        }
                      } else {
                        setMess(
                          'Your phone number is not valid, please check again.',
                        );
                      }
                    }
                  }}
                />
                {props.dataProps.phoneNumber && (
                  <InputGroupAddon addonType="append">
                    <InputGroupText
                      className="text-primary sign-in-btn"
                      onClick={() => {
                        const phoneNumber = isValidPhoneNumber(
                          `0${props.dataProps.phoneNumber}`,
                          'MY',
                        );
                        if (phoneNumber) {
                          if (props.dataProps.signInModal.type === 'SIGN IN') {
                            onLogin();
                          } else {
                            onSignUp();
                          }
                        } else {
                          setMess(
                            'Your phone number is not valid, please check again.',
                          );
                        }
                      }}
                    >
                      <span style={{ cursor: 'pointer' }}>
                        {props.dataProps.signInModal.type}
                      </span>
                    </InputGroupText>
                  </InputGroupAddon>
                )}
              </InputGroup>
              <FormText>{mess}</FormText>
            </FormGroup>
          </div>
          {/* <p>
                        <small>OR CONTINUE WITH</small>
                    </p>
                    <div>
                    <Button block color="primary" type="button">
                        <i className="fab fa-facebook-square" /> CONTINUE WITH
                        FACEBOOK
                    </Button>
                    </div> */}
        </CardBody>
      </Spin>
    </Card>
  );
};

const Step2 = (props) => {
  const [request, setRequest] = useState(false);
  const [loading, setLoading] = useState(false);

  if (!props.dataProps.phoneNumber) {
    props.goToStep(1);
  }

  const onFinishTimeRequest = () => {
    setRequest(!request);
  };

  const reSend = () => {
    setLoading(true);
    fetch(RESEND_CODE, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mobile_phone_number: area_code_phone + props.dataProps.phoneNumber,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        if (results.success) {
          setRequest(!request);
          setLoading(false);
        } else {
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err) {
          setLoading(false);
        }
      });
  };

  const onComplete = (dataComplete) => {
    setLoading(true);
    fetch(VERIFY_LOGIN, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        mobile_phone_number: area_code_phone + props.dataProps.phoneNumber,
        verification_code: dataComplete,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        if (results.success) {
          if (results.body.status === 'verified') {
            localStorage.clear();
            localStorage.setItem(USER_LOGIN, JSON.stringify(results.body));
            success();
            window.location.href = '/';
          } else {
            props.nextStep();
            props.dataProps.setVerificationCode(dataComplete);
            props.dataProps.setDataUser(results.body);
            setLoading(false);
          }
        } else {
          message.error(results.error_message);
          setLoading(false);
        }
      })
      .catch((err) => {
        if (err) {
          showError(err);
          setLoading(false);
        }
      });
  };

  const success = () => {
    message.success('Login success');
  };

  return (
    <div>
      <Spin tip="Loading..." spinning={loading}>
        <LeftCircleOutlined onClick={props.previousStep} />
        <div className="pb-3">
          Enter verification code we’ve sent to +60{props.dataProps.phoneNumber}
          :
        </div>
        <ReactCodeInput fields={4} onComplete={onComplete} />
        <div className="pt-3">
          {request ? (
            <div onClick={reSend} style={{ cursor: 'pointer' }}>
              <b>Resend Code</b>
            </div>
          ) : (
            <div>
              Request new code in{' '}
              <Countdown
                format="mm:ss"
                value={Date.now() + 120000}
                onFinish={onFinishTimeRequest}
              />
            </div>
          )}
        </div>
      </Spin>
    </div>
  );
};

const Step3 = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  if (!props.dataProps.phoneNumber) {
    props.goToStep(1);
  }

  const onChangeCheckBox = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  const onFinish = (values) => {
    values.birth_date = moment(values.birth_date).format('YYYY-MM-DD');
    try {
      setLoading(true);
      fetch(VERIFY, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
          'X-Customer-Id': props.dataProps.dataUser.id,
          'X-Customer-Api-Key': props.dataProps.dataUser.api_key,
        },
        body: JSON.stringify({
          mobile_phone_number: area_code_phone + props.dataProps.phoneNumber,
          verification_code: props.dataProps.verificationCode,
          referral_code: props.dataProps.dataUser.referral_code,
          ...values,
        }),
      })
        .then((response) => {
          return response.json();
        })
        .then((results) => {
          if (results.body) {
            localStorage.clear();
            localStorage.setItem(USER_LOGIN, JSON.stringify(results.body));
            window.location.href = '/';
          } else if (results.message) {
            message.error(results.message);
            setLoading(false);
          } else {
            setLoading(false);
            message.error('Error');
          }
        })
        .catch((err) => {
          if (err) {
            message.error(err);
            setLoading(false);
          }
        });
    } catch (errorInfo) {
      console.log('Failed:', errorInfo);
      setLoading(false);
    }
  };

  return (
    <div>
      <Spin tip="Loading..." spinning={loading}>
        <LeftCircleOutlined onClick={props.previousStep} />
        <h6 className="pb-2">Tell us more about youself</h6>
        <div>
          <Form form={form} name="dynamic_rule" onFinish={onFinish}>
            <Form.Item
              name="first_name"
              rules={[
                {
                  required: true,
                  message: 'Please input your first name!',
                },
              ]}
            >
              <Input
                placeholder="FIRST NAME*"
                type="text"
                className="ant-input"
                name="first_name"
              />
            </Form.Item>
            <Form.Item
              name="last_name"
              rules={[
                {
                  required: true,
                  message: 'Please input your last name!',
                },
              ]}
            >
              <Input
                placeholder="LAST NAME*"
                type="text"
                className="ant-input"
                name="last_name"
              />
            </Form.Item>
            <Form.Item
              name="email"
              rules={[
                {
                  required: true,
                  message: 'Please input your email address!',
                },
              ]}
            >
              <Input
                placeholder="EMAIL ADDRESS*"
                type="text"
                className="ant-input"
                name="email"
              />
            </Form.Item>
            <Form.Item
              name="birth_date"
              rules={[
                {
                  required: true,
                  message: 'Please input your birthday!',
                },
                {
                  validator: (rule, value) => {
                    if (value) {
                      const date1 = moment().subtract(100, 'years');
                      const date2 = moment().subtract(5, 'years');
                      if (value.isAfter(date2)) {
                        return Promise.reject(
                          `We only accept birthdate before ${date2.format(
                            'DD-MM-YYYY',
                          )}`,
                        );
                      } else if (value.isBefore(date1)) {
                        return Promise.reject(
                          `We only accept birthdate after ${date1.format(
                            'DD-MM-YYYY',
                          )}`,
                        );
                      }
                    }
                    return Promise.resolve(true);
                  },
                },
              ]}
            >
              <DatePicker
                className="ant-input"
                name="birth_date"
                placeholder="BIRTHDAY* (dd/mm/yyyy)"
                format={dateFormatList}
              />
            </Form.Item>
            <div className="mt-3">
              By creating an account you are agreeing to our{' '}
              <a href="#/" style={{ textDecoration: 'underline' }}>
                terms of use
              </a>{' '}
              and{' '}
              <a href="#/" style={{ textDecoration: 'underline' }}>
                privacy policy
              </a>
              .
            </div>
            <Form.Item>
              <Button block className="mt-2" color="primary" type="submit">
                CONTINUE
              </Button>
            </Form.Item>
            <div className="mt-2">
              <Checkbox onChange={onChangeCheckBox}>
                I don’t want to receive member’s only exclusive deals,
                promotions, and marketing emails from The Social Group.
              </Checkbox>
            </div>
          </Form>
        </div>
      </Spin>
    </div>
  );
};

const Login = () => {
  const dispatch = useDispatch();
  const signInModal = useSelector((state) => state.effect.signInModal);
  const { hasReferral, referringUser, reward } = useSelector(
    (state) => state.effect.referral,
  );
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [dataUser, setDataUser] = useState(null);

  const onCancel = (val) => {
    dispatch(
      setSignInModal({
        status: false,
        type: '',
      }),
    );
    setPhoneNumber('');
  };

  const modalTitle = useMemo(() => {
    return hasReferral &&
      referringUser &&
      reward &&
      signInModal.type === SIGN_IN_TYPE.SIGN_UP ? (
      <>
        <div>
          {referringUser} just gave you RM20 Social Credit for you to spend at
          The Social Group of Restaurants!
        </div>
        <br />
        <div>SIGN UP now to claim it before it expires!</div>
      </>
    ) : (
      'LET’S GET YOU STARTED WITH SOCIAL EATS'
    );
  }, [hasReferral, referringUser, reward, signInModal]);

  return (
    <>
      <Modal
        title={modalTitle}
        centered
        visible={signInModal.status}
        footer={null}
        width={400}
        className="modal_login"
        wrapClassName="login_modal_wrapper"
        maskStyle={{ zIndex: 1400 }}
        onCancel={onCancel}
      >
        <div className="modal-body p-0">
          <StepWizard initialStep={1}>
            <Step1
              dataProps={{
                phoneNumber,
                setPhoneNumber,
                signInModal,
              }}
            />
            <Step2
              dataProps={{
                phoneNumber,
                setDataUser,
                setVerificationCode,
              }}
            />
            <Step3
              dataProps={{
                dataUser,
                phoneNumber,
                verificationCode,
              }}
            />
          </StepWizard>
        </div>
      </Modal>
    </>
  );
};

export default Login;
