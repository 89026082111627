import React, { useEffect, useState } from 'react';
import Food from './Food';
import axios from '../../api/CustomAxios';
import { Container, Row, Badge, Col, FormGroup, InputGroup } from 'reactstrap';
import {
  GET_ALL_MAIN_MEALS,
  GET_ALL_TAGS,
  GET_MAIN_MEAL_BY_ID,
} from '../../api/APIs';
import { useSelector, useDispatch } from 'react-redux';
import { setMainMealsList, setTags } from '../../_actions/mainMeal.action';
import { setLngLat } from '../../_actions/global.action';
import classnames from 'classnames';
import Slider from 'react-slick';
import { Link } from 'react-scroll';
import { Input, Affix } from 'antd';
import { LNG_LAT } from '_constants';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { readAndParseStorage, selectedTimeToTimeString } from '../../_helper';

let timeoutCall;

const settings = {
  className: 'center',
  dots: false,
  arrows: false,
  infinite: false,
  variableWidth: true,
  swipeToSlide: true,
  adaptiveHeight: true,
};

const Body = () => {
  const mainMeals = useSelector((state) => state.mainMeal.mainMeals);
  const tags = useSelector((state) => state.mainMeal.tags);
  const selectedTime = useSelector((state) => state.global.selectedTime);
  const isAppReady = useSelector((state) => state.global.isAppReady);
  const selectedRestaurant = useSelector(
    (state) => state.global.selectedRestaurant,
  );
  const selectedVenue = useSelector((state) => state.global.selectedVenue);

  const [valueSearch, setValueSearch] = useState('');
  const [searchCheck, setSearchCheck] = useState(false);
  const [selectedTag, setSelectedTag] = useState(null);
  const dispatch = useDispatch();

  useEffect(() => {
    let dataLngLat = readAndParseStorage(LNG_LAT);
    dispatch(setLngLat(dataLngLat));
  }, [dispatch]);

  const onSelectTag = (tag) => {
    const timeSlot = selectedTimeToTimeString({
      selectedTime: selectedTime?.valueSelectTime,
      selectedDate: selectedTime?.valueDay,
    });
    if (tag === selectedTag) {
      setSelectedTag(null);
      axios
        .get(`${GET_ALL_MAIN_MEALS}?time_slot=${timeSlot}`, {
          dataHeaders: {
            venueID: selectedVenue.id,
            brandID: selectedRestaurant.id,
          },
        })
        .then((res) => {
          setSearchCheck(false);
          dispatch(setMainMealsList(res.data));
        })
        .catch((error) => {
          if (error) {
            dispatch(setMainMealsList(null));
          }
        });
    } else {
      setSelectedTag(tag);
      let listTags = [];
      listTags.push(tag);
      axios
        .get(GET_MAIN_MEAL_BY_ID + `?tags=${listTags}&time_slot=${timeSlot}`, {
          dataHeaders: {
            venueID: selectedVenue.id,
            brandID: selectedRestaurant.id,
          },
        })
        .then((results) => {
          let dataRes = [
            {
              title: '',
              description: '',
              products: results.data,
            },
          ];
          if (dataRes) {
            setSearchCheck(true);
            dispatch(setMainMealsList(dataRes));
          } else {
            dispatch(setMainMealsList(null));
          }
        })
        .catch((error) => {
          if (error) {
            dispatch(setMainMealsList(null));
          }
        });
    }
  };

  useEffect(() => {
    if (!isAppReady) return;
    if (!selectedRestaurant) return;
    // if (!selectedVenue) return;
    setValueSearch('');
    setSearchCheck(false);
    debugger

    const timeSlot = selectedTimeToTimeString({
      selectedTime: selectedTime?.valueSelectTime,
      selectedDate: selectedTime?.valueDay,
    });
    const dataHeaders = {
      brandID: selectedRestaurant.id
    }
    if (selectedVenue?.id) {
      dataHeaders.venueID = selectedVenue?.id
    }
    axios
      .get(`${GET_ALL_MAIN_MEALS}?time_slot=${timeSlot}`, {
        dataHeaders
      })
      .then((res) => {
        dispatch(setMainMealsList(res.data));
      })
      .catch((error) => {
        if (error) {
          dispatch(setMainMealsList(null));
        }
      });

    axios
      .get(GET_ALL_TAGS, {
        dataHeaders
      })
      .then((res) => {
        dispatch(setTags(res.data));
      })
      .catch((error) => {
        if (error) {
          dispatch(setTags(null));
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVenue, selectedTime, isAppReady]);

  const onChangeSearch = (e) => {
    let valueInput = e.target.value;
    setValueSearch(valueInput);
    clearTimeout(timeoutCall);
    const timeSlot = selectedTimeToTimeString({
      selectedTime: selectedTime?.valueSelectTime,
      selectedDate: selectedTime?.valueDay,
    });
    timeoutCall = setTimeout(() => {
      if (valueInput.length > 0) {
        axios
          .get(
            GET_MAIN_MEAL_BY_ID + `?search=${valueInput}&time_slot=${timeSlot}`,
            {
              dataHeaders: {
                venueID: selectedVenue.id,
                brandID: selectedRestaurant.id,
              },
            },
          )
          .then((results) => {
            let dataRes = [
              {
                title: '',
                description: '',
                products: results.data,
              },
            ];
            if (dataRes) {
              setSearchCheck(true);
              dispatch(setMainMealsList(dataRes));
            } else {
              dispatch(setMainMealsList(null));
            }
          })
          .catch((error) => {
            if (error) {
              dispatch(setMainMealsList(null));
            }
          });
      } else {
        axios
          .get(`${GET_ALL_MAIN_MEALS}?time_slot=${timeSlot}`, {
            dataHeaders: {
              venueID: selectedVenue.id,
              brandID: selectedRestaurant.id,
            },
          })
          .then((res) => {
            setSearchCheck(false);
            dispatch(setMainMealsList(res.data));
          })
          .catch((error) => {
            if (error) {
              dispatch(setMainMealsList(null));
            }
          });
      }
    }, 800);
  };

  return (
    <>
      <Container className="body form_body">
        <Row>
          <Col md="4" className="d-none d-md-block">
            <div id="browse_menu">
              <h2>BROWSE THE MENU</h2>
              <FormGroup>
                <InputGroup>
                  <Input
                    placeholder="Search the menu…"
                    type="text"
                    className="pt-2 pb-2"
                    onChange={onChangeSearch}
                    value={valueSearch}
                    prefix={
                      <i
                        className="fas fa-search"
                        style={{ color: '#adb5bd' }}
                      />
                    }
                    allowClear
                  />
                </InputGroup>
              </FormGroup>
              <div className="browse_menu__category">
                <PerfectScrollbar>
                  <div style={{ maxHeight: 250 }}>
                    {mainMeals &&
                      !searchCheck &&
                      mainMeals.map((meal, index) => {
                        return (
                          <div key={index}>
                            <Link
                              to={`category${meal.id}`}
                              spy={true}
                              offset={-150}
                              smooth={true}
                            >
                              {meal.title}
                            </Link>
                          </div>
                        );
                      })}
                  </div>
                </PerfectScrollbar>
                <div className="tags">
                  <h2>FILTER BY TAGS</h2>
                  <div className="d-flex flex-wrap">
                    {tags &&
                      tags.map((tag, index) => {
                        return (
                          <Badge
                            key={index}
                            onClick={() => onSelectTag(tag.name)}
                            className={classnames('text-uppercase', {
                              active: tag.name === selectedTag,
                            })}
                            pill
                          >
                            {tag.name}
                          </Badge>
                        );
                      })}
                  </div>
                </div>
              </div>
            </div>
          </Col>
          <Col md="8" sm="12" id="meal_list" className="p-0">
            <div id="main_body">
              <Col className="pr-lg-3 pl-lg-3 d-md-none">
                <FormGroup>
                  <InputGroup>
                    <Input
                      placeholder="Search the menu…"
                      type="text"
                      className="pt-2 pb-2"
                      onChange={onChangeSearch}
                      defaultValue={valueSearch}
                      prefix={
                        <i
                          className="fas fa-search"
                          style={{ color: '#adb5bd' }}
                        />
                      }
                      allowClear
                    />
                  </InputGroup>
                </FormGroup>
              </Col>
              {mainMeals && !searchCheck && (
                <Affix offsetTop={90} className=" d-md-none">
                  <Col className="list category_mobile pr-lg-3 pl-lg-3">
                    <div className="_sliders">
                      <div className="_slider">
                        <Slider {...settings}>
                          {mainMeals.map((meal, index) => {
                            return (
                              <div key={index}>
                                <Link
                                  className="btn"
                                  activeClass="active"
                                  to={`category${meal.id}`}
                                  offset={-230}
                                  spy={true}
                                  smooth={true}
                                >
                                  {meal.title}
                                </Link>
                              </div>
                            );
                          })}
                        </Slider>
                      </div>
                    </div>
                  </Col>
                </Affix>
              )}
              <div className="d-md-none">
                <div className="mt-4"></div>
              </div>
              <Col className="form_meal pr-lg-3 pl-lg-3">
                {mainMeals
                  ? !searchCheck
                    ? mainMeals.map((category, index) => {
                        return (
                          <Food category={category} key={index} limit={4} />
                        );
                      })
                    : mainMeals.map((category, index) => {
                        return <Food category={category} key={index} md={6} />;
                      })
                  : ''}
              </Col>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Body;
