import React, { useEffect, useRef } from 'react';
import queryString from 'query-string';
import TagManager from 'react-gtm-module';

// core components
import Body from '../components/main/Body';
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { setLngLat } from '../_actions/global.action';
import { LNG_LAT, SIGN_IN_TYPE } from '_constants';
import { setReferral, setSignInModal } from '../_actions/effect.action';

const tagManagerArgs = {
  dataLayer: {
    page: 'Home',
  },
  dataLayerName: 'PageDataLayer',
};

const Index = (props) => {
  TagManager.dataLayer(tagManagerArgs);
  const customOverlay = useSelector((state) => state.effect.customOverlay);

  const userLogin = useSelector((state) => state.authentication.userLogin);
  const isAppReady = useSelector((state) => state.global.isAppReady);
  const dispatch = useDispatch();

  const mainEl = useRef(null);

  useEffect(() => {
    if (isAppReady) {
      const { referredBy, referringUser, reward } =
        queryString.parse(props.location.search.replace('?', '')) ?? {};
      const hasReferral = !!referredBy && !!referringUser && !!reward;
      dispatch(
        setReferral({
          hasReferral,
          referredBy,
          referringUser,
          reward,
        }),
      );
      if (hasReferral && !userLogin?.id) {
        dispatch(
          setSignInModal({
            status: true,
            type: SIGN_IN_TYPE.SIGN_UP,
          }),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAppReady]);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainEl.current.scrollTop = 0;
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(geocodeLatLng);
    }
    function geocodeLatLng(position) {
      if (position) {
        const latlng = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        dispatch(setLngLat(latlng));
        localStorage.setItem(LNG_LAT, JSON.stringify(latlng));
      } else {
        console.log('error');
      }
    }
  }, [dispatch]);
  return (
    <>
      <main ref={mainEl}>
        <div className="position-relative">
          <section className={classNames({ 'abc': true, 'section': true, 'header_section': true, 'is_logged_in': userLogin})}>
            <div
              id="custom-overlay"
              className={`${classNames({
                'd-none': !customOverlay,
              })} display_none_mobile`}
            />
            <Body />
          </section>
        </div>
      </main>
    </>
  );
};

export default Index;
