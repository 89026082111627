import React, { useState } from 'react';
// reactstrap components
import { useDispatch, useSelector } from 'react-redux';
import { Button, Card, CardBody, CardHeader } from 'reactstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useHistory } from 'react-router-dom';
import CartItem from 'components/cart/CartItem';
import { setOrderUserItems } from '_actions/global.action';
import { ORDER_USER } from '_constants';
import { GET_ORDERS } from 'api/APIs';

const CartDialog = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const userLogin = useSelector((state) => state.authentication.userLogin);
  const orderUserItems = useSelector((state) => state.global.orderUserItems);
  const selectedRestaurant = useSelector(
    (state) => state.global.selectedRestaurant,
  );
  const onClickReviewOrder = () => {
    history.push(`/${selectedRestaurant.id}/review-order`);
    props.onToggle();
  };

  const onClickClearBag = () => {
    setLoading(true);
    fetch(GET_ORDERS + 'cart', {
      method: 'delete',
      headers: {
        'Content-Type': 'application/json',
        'X-Customer-Id': userLogin ? userLogin.id : '',
        'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
      },
    })
      .then(() => {
        dispatch(setOrderUserItems([]));
        localStorage.removeItem(ORDER_USER);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const browseMenu = () => {
    props.onToggle();
    history.push(`/${selectedRestaurant.id}/home`);
  };

  return (
    <>
      <div className="popup-dropdown">
        <Card>
          <CardHeader>
            <h2>YOUR BAG</h2>
            <Button
              className=""
              color="default"
              type="button"
              onClick={() => props.onToggle()}
            >
              <i className="fas fa-times" />
            </Button>
          </CardHeader>
          <CardBody>
            {orderUserItems && orderUserItems.length <= 0 ? (
              <div className="cart_no_item cart_content">
                <i className="ni ni-bag-17 c_icon" />
                <h2>YOU HAVE NOT ADDED ANY DISH</h2>
                <Button
                  block
                  color="primary"
                  type="button"
                  onClick={browseMenu}
                >
                  BROWSE MENU
                </Button>
              </div>
            ) : (
              <div className="cart_has_item cart_content">
                <div className="_body">
                  <PerfectScrollbar>
                    <div style={{ maxHeight: 300 }}>
                      {orderUserItems &&
                        orderUserItems.map((item, index) => {
                          return (
                            <CartItem
                              cart={item}
                              typeCart="popupCart"
                              onTogg={() => props.onToggle()}
                              key={index}
                              {...props}
                            />
                          );
                        })}
                    </div>
                  </PerfectScrollbar>
                </div>
                <div className="_footer">
                  <Button
                    block
                    disabled={loading}
                    color="secondary"
                    type="button"
                    size="md"
                    onClick={onClickClearBag}
                  >
                    CLEAR BAG
                  </Button>
                  <Button
                    block
                    disabled={loading}
                    color="primary"
                    type="button"
                    onClick={onClickReviewOrder}
                  >
                    REVIEW ORDER
                  </Button>
                </div>
              </div>
            )}
          </CardBody>
        </Card>
      </div>
    </>
  );
};

export default CartDialog;
