export const CART_NUMBER = 'CART_NUMBER';
export const LOCATION = 'LOCATION';
export const SELECTED_RESTAURANT = 'SELECTED_RESTAURANT';
export const SELECTED_ADDRESS = 'SELECTED_ADDRESS';
export const DELIVERY_VENUES = 'DELIVERY_VENUES';
export const SELECTED_TIME = 'SELECTED_TIME';
export const DISMISSED_POPUPS = 'DISMISSED_POPUPS'
export const USER_LOGIN = 'USER_LOGIN';
export const ORDER_USER = 'ORDER_USER';
export const PAYMENT = 'PAYMENT';
export const VENUES_DATA = 'VENUES_DATA';
export const LNG_LAT = 'LNG_LAT';
export const CART = '______CART______';
export const TOKEN_NAME = 'token';
export const CMS_API = process.env.REACT_APP_CMS_API || '';
export const REWARD_API = process.env.REACT_APP_REWARD_API || '';
export const API_KEY = process.env.REACT_APP_API_KEY || '';
export const STRIPE_PUBLIC_KEY = 'STRIPE_PUBLIC_KEY';
export const PAYMENT_INTENT_CLIENT_SECRET = 'PAYMENT_INTENT_CLIENT_SECRET';
export const PAYMENT_INTENT_ID = 'PAYMENT_INTENT_ID';
export const COMMON_ERROR_MESSAGE =
  'Sorry, we get unexpected error, Please contact us or try again';

export const SIGN_IN_TYPE = {
  SIGN_UP: 'SIGN UP',
  SIGN_IN: 'SIGN IN',
};
