import React from 'react';
import { Modal, Button, CardBody, Card } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setStartedModal, setSignInModal } from '../../_actions/effect.action';
const GetStarted = () => {
  const dispatch = useDispatch();
  const startedModal = useSelector((state) => state.effect.startedModal);
  const onClickSignIn = () => {
    dispatch(setStartedModal(false));
    dispatch(setSignInModal(true));
  };
  return (
    <>
      <Modal
        className="modal-dialog-centered auth_dialog social_dialog"
        size="sm"
        isOpen={startedModal}
        toggle={() => dispatch(setStartedModal(!startedModal))}
        backdrop="static"
        backdropClassName="auth_dialog__bg"
      >
        <div className="modal-body p-0">
          <Button
            className="close_modal"
            color="default"
            type="button"
            onClick={() => dispatch(setStartedModal(!startedModal))}
          >
            <i className="fas fa-times" />
          </Button>
          <Card className="border-0">
            <CardBody className="text-center">
              <img
                alt="The Social"
                src={require('assets/img/brand/the-social-logo.svg')}
              />
              <h1>LET’S GET YOU STARTED WITH SOCIAL EATS</h1>
              <div>
                <Button block color="primary" type="button">
                  NEW MEMBER
                </Button>
              </div>
              <div>
                <Button block color="primary" type="button">
                  LINK EXISTING CARD
                </Button>
              </div>
              <p>
                ALREADY HAVE AN ACCOUNT?{' '}
                <a href="#ee" onClick={() => onClickSignIn()}>
                  SIGN IN
                </a>
              </p>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};
export default GetStarted;
