import React from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, Redirect, HashRouter } from 'react-router-dom';

import 'react-perfect-scrollbar/dist/css/styles.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
import 'assets/scss/the-social.scss?v1.1.0';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'antd/dist/antd.css';

import Index from './views/Index.js';
import { createStore, applyMiddleware, compose } from 'redux';
import thunkMiddleware from 'redux-thunk';
// import { createLogger } from "redux-logger";
import rootReducer from './_reducers/index';
import { Provider } from 'react-redux';
import Footer from 'components/Footers/Footer';
import GetStarted from './components/auth/GetStarted';
import Login from 'components/auth/Login';
import PickRestaurant from 'components/dialog/PickRestaurant';
import MainNavbar from 'components/Navbars/MainNavbar.js';
import HotDealLayout from './components/layouts/HotDealLayout';
import MainFoodLayout from './components/layouts/MainFoodLayout';
import FoodDetail from './components/main/FoodDetail';
import CategoryDetail from './components/main/CategoryDetail';
import ReviewOrder from './components/main/ReviewOrder';
import NotLoggedIn from 'components/dialog/NotLoggedIn.js';
import Tracking from 'components/main/Tracking.js';
import ProfileDialogCustom from 'components/dialog/ProfileDialogCustom.js';
import CartDialogCustom from 'components/dialog/CartDialogCustom.js';
import MealPlansLayout from './components/layouts/MealPlansLayout';
import MealPlansDetailLayout from './components/layouts/MealPlansDetailLayout';
import MyProfile from './components/main/MyProfile';
import MyOrders from './components/main/MyOrders';
import ViewDetailOrder from './components/dialog/ViewDetailOrder';
import TagManager from 'react-gtm-module';
import FacebookDataDeletionInstructions from "./components/layouts/FacebookDataDeletionInstructions";
// import { createLogger } from "redux-logger";
// const loggerMiddleware = createLogger();

const tagManagerArgs = {
  gtmId: 'GTM-KZWLFB5',
  dataLayerName: 'PageDataLayer',
};

TagManager.initialize(tagManagerArgs);

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(thunkMiddleware)),
);
ReactDOM.render(
  <Provider store={store}>
    <HashRouter>
      <MainNavbar />
      <Switch>
        <Route path="/" exact render={(props) => <Index {...props} />} />
        <Route
          path="/:id/home"
          exact
          render={(props) => <Index {...props} />}
        />
        <Route
          path="/referral"
          exact
          render={(props) => <Index {...props} isShowReferral />}
        />
        <Route
          path="/orders"
          render={(props) => (
            <MainFoodLayout detailComponent={MyOrders} {...props} />
          )}
        />
        <Route
          path="/profile"
          render={(props) => (
            <MainFoodLayout detailComponent={MyProfile} {...props} />
          )}
        />
        <Route
          path="/meal-plans"
          render={(props) => <MealPlansLayout {...props} />}
        />
        <Route
          path="/meal-plans-detail"
          render={(props) => <MealPlansDetailLayout {...props} />}
        />
        <Route
          path="/hot-deal/:id"
          exact
          render={(props) => <HotDealLayout {...props} />}
        />
        <Route
          path="/main-meal/:id/:id_order_item"
          exact
          render={(props) => (
            <MainFoodLayout detailComponent={FoodDetail} {...props} />
          )}
        />
        <Route
          path="/:id/category-meal/:id"
          exact
          render={(props) => (
            <MainFoodLayout detailComponent={CategoryDetail} {...props} />
          )}
        />
        <Route
          path="/category-meal/:id"
          exact
          render={(props) => (
            <MainFoodLayout detailComponent={CategoryDetail} {...props} />
          )}
        />
        <Route
          path="/review-order"
          render={(props) => (
            <MainFoodLayout detailComponent={ReviewOrder} {...props} />
          )}
        />
        <Route
          path="/:id/review-order"
          render={(props) => (
            <MainFoodLayout detailComponent={ReviewOrder} {...props} />
          )}
        />
        <Route
          path="/tracking"
          render={(props) => (
            <MainFoodLayout detailComponent={Tracking} {...props} />
          )}
        />
        <Route
          path="/facebook-data-deletion-instructions"
          render={(props) => (
            <FacebookDataDeletionInstructions />
          )}
        />
        <Redirect to="/" />
      </Switch>
      <Footer />
      <GetStarted />
      <Login />
      <PickRestaurant />
      <NotLoggedIn />
      <ProfileDialogCustom />
      <CartDialogCustom />
      <ViewDetailOrder />
    </HashRouter>
  </Provider>,
  document.getElementById('root'),
);
