import React from 'react';
import moment from 'moment';
import { Statistic } from 'antd';

const { Countdown } = Statistic;

function checkTime(data) {
  let time = '';
  if (data.order_type === 'pickup') {
    if (data.status === 'checked_out') {
      time = `${moment(data.time_slot)
        .tz('Asia/Kuala_Lumpur')
        .format('DD MMM, HH:mm A')} - ${moment(data.time_slot)
        .add(30, 'minutes')
        .tz('Asia/Kuala_Lumpur')
        .format('HH:mm A')}`;
    } else if (data.status === 'preparing_food') {
      let minutes = Math.floor(
        (moment(data.time_slot).tz('Asia/Kuala_Lumpur').unix() -
          moment(new Date()).tz('Asia/Kuala_Lumpur').unix()) /
          60,
      );
      if (minutes <= 0) {
        time = `0 MINUTES`;
      } else if (minutes < 60) {
        time = (
          <Countdown
            format="mm"
            value={moment(data.time_slot).unix() * 1000}
            title="MINUTES"
          />
        );
      } else {
        time = `${moment(data.time_slot)
          .tz('Asia/Kuala_Lumpur')
          .format('DD MMM, HH:mm A')} - ${moment(data.time_slot)
          .add(30, 'minutes')
          .tz('Asia/Kuala_Lumpur')
          .format('HH:mm A')}`;
      }
    } else if (data.status === 'food_ready') {
      time = `0 MINUTES`;
    } else {
      time = `${moment(data.time_slot)
        .tz('Asia/Kuala_Lumpur')
        .format('DD MMM, HH:mm A')} - ${moment(data.time_slot)
        .add(30, 'minutes')
        .tz('Asia/Kuala_Lumpur')
        .format('HH:mm A')}`;
    }
  } else if (data.order_type === 'delivery') {
    if (
      data.status === 'checked_out' ||
      data.status === 'assigning_rider' ||
      data.status === 'preparing_food' ||
      data.status === 'assigned_rider' ||
      data.status === 'staff_delivery'
    ) {
      time = `${moment(data.time_slot)
        .tz('Asia/Kuala_Lumpur')
        .format('DD MMM, HH:mm A')} - ${moment(data.time_slot)
        .add(30, 'minutes')
        .tz('Asia/Kuala_Lumpur')
        .format('HH:mm A')}`;
    } else if (data.status === 'rider_pick_up') {
      let minutes = Math.floor(
        (moment(data.shipment.estimated_arrival_at)
          .tz('Asia/Kuala_Lumpur')
          .unix() -
          moment(new Date()).tz('Asia/Kuala_Lumpur').unix()) /
          60,
      );
      if (minutes <= 0) {
        time = `0 MINUTES`;
      } else if (minutes < 60) {
        time = (
          <Countdown
            format="mm"
            value={moment(data.time_slot).unix() * 1000}
            title="MINUTES"
          />
        );
      } else {
        time = `${moment(data.time_slot)
          .tz('Asia/Kuala_Lumpur')
          .format('DD MMM, HH:mm A')} - ${moment(data.time_slot)
          .add(30, 'minutes')
          .tz('Asia/Kuala_Lumpur')
          .format('HH:mm A')}`;
      }
    } else if (data.status === 'rider_arrived') {
      time = `0 MINUTES`;
    } else {
      time = `${moment(data.time_slot)
        .tz('Asia/Kuala_Lumpur')
        .format('DD MMM, HH:mm A')} - ${moment(data.time_slot)
        .add(30, 'minutes')
        .tz('Asia/Kuala_Lumpur')
        .format('HH:mm A')}`;
    }
  }
  return time;
}

export default checkTime;
