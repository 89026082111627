import React from 'react';
import _ from 'lodash';
import { USER_LOGIN, ORDER_USER } from '_constants';
import { GET_ORDERS } from '../../api/APIs';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { message } from 'antd';
import { setOrderUserItems } from '../../_actions/global.action';
import { readAndParseStorage } from '../../_helper';

const CartItem = (props) => {
  const { cart, readOnly } = props;
  const dispatch = useDispatch();
  const history = useHistory();
  const selectedRestaurant = useSelector(
    (state) => state.global.selectedRestaurant,
  );
  const selectedVenue = useSelector((state) => state.global.selectedVenue);
  const selectedAddress = useSelector((state) => state.global.selectedAddress);

  const removeItem = () => {
    //Remove to cart
    const userLogin = readAndParseStorage(USER_LOGIN);
    const orderUserStorage = readAndParseStorage(ORDER_USER);
    console.log(props);
    let order_items = [];
    if (orderUserStorage) {
      if (
        orderUserStorage.order_items &&
        orderUserStorage.order_items.length > 0
      ) {
        order_items = orderUserStorage.order_items;
        _.remove(order_items, (item) => {
          return item.id === cart.id;
        });
      }
    }

    fetch(GET_ORDERS + `${orderUserStorage.id}`, {
      method: 'put',
      headers: {
        'Content-Type': 'application/json',
        'X-Customer-Id': userLogin ? userLogin.id : '',
        'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
        'X-Venue-Id': selectedVenue.id,
        'X-Brand-Id': selectedRestaurant.id,
        'X-Address-Id': selectedAddress.id,
      },
      body: JSON.stringify({
        ...orderUserStorage,
        order_items: order_items,
      }),
    })
      .then((response) => {
        return response.json();
      })
      .then((results) => {
        if (!results.error_message) {
          localStorage.setItem(ORDER_USER, JSON.stringify(results));
          dispatch(setOrderUserItems(results.order_items));

          message.success(`Remove success item ${cart.product.title}`);
        } else {
          message.error(results.error_message);
        }
      })
      .catch((err) => {
        if (err) {
          message.error('Add to bag error');
        }
      });
  };

  const edit = () => {
    history.push('/main-meal/' + cart.product_id + `/` + cart.id);
    if (props.onTogg) {
      props.onTogg();
    }
  };

  return (
    <>
      <div className="_h_item" key={cart.product_id}>
        <div
          className="_img"
          style={{
            backgroundImage: `url(${cart.product.featured_image_512x512_url})`,
          }}
        >
          <span>x{cart.quantity}</span>
        </div>
        <div className="_info">
          <h3>{cart.product.title}</h3>
          <small>RM {parseInt(cart.price) * cart.quantity}</small>
        </div>
        {!readOnly ? (
          <div className="_action">
            <button className="_edit" onClick={edit}>
              edit
            </button>
            <button className="_remove" onClick={removeItem}>
              remove
            </button>
          </div>
        ) : (
          ''
        )}
      </div>
    </>
  );
};
CartItem.defaultProps = {
  cart: {
    meal: {
      id: Date.now(),
      img: '/img/common/no-food.jpg',
      name: 'Food Name',
    },
    totalPrice: 0,
    quantity: 0,
  },
  readOnly: false,
};
export default CartItem;
