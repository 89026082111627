import React, { useEffect, useState } from 'react';
// reactstrap components
import { Button, Card, CardBody, CardHeader, CardFooter } from 'reactstrap';
import { Radio, message } from 'antd';
import { GET_ORDERS } from '../../api/APIs';
import Select from 'react-select';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useSelector, useDispatch } from 'react-redux';
import {
  setSelectedTime,
  setOrderUserItems,
} from '../../_actions/global.action';
import { SELECTED_TIME, USER_LOGIN, ORDER_USER } from '_constants';
import moment from 'moment';
import {
  readAndParseStorage,
  selectedTimeToTimeString,
  showError,
} from '../../_helper/index';

const DeliveryTime = (props) => {
  const dispatch = useDispatch();
  const [dataTime, setDataTime] = useState([]);
  const [optionsDayShow, setOptionsDayShow] = useState([]);
  const [valueDay, setValueDay] = useState(null);
  const [valueSelectTime, setValueSelectTime] = useState(null);

  const selectedRestaurant = useSelector(
    (state) => state.global.selectedRestaurant,
  );
  const selectedVenue = useSelector((state) => state.global.selectedVenue);
  const selectedAddress = useSelector((state) => state.global.selectedAddress);

  const globalSetting = useSelector((state) => state.global.globalSetting);

  useEffect(() => {
    let selectedTimeStorage = readAndParseStorage(SELECTED_TIME);

    if (selectedTimeStorage) {
      if (
        moment().tz('Asia/Kuala_Lumpur').format('YYYY-MM-DD') ===
        selectedTimeStorage.valueDay.fullday
      ) {
        // date now
        let nowTime = moment().tz('Asia/Kuala_Lumpur').format('HH:mm');
        var s = new Date(moment().tz('Asia/Kuala_Lumpur').format());

        let [hours, minutes] = nowTime.split(':');
        s.setHours(hours);
        s.setMinutes(
          parseInt(minutes) +
            (globalSetting ? globalSetting.min_advance_ordertime : 0),
        );
        let timeUpdate = moment(s).format('HH:mm');

        let dataFilter = selectedTimeStorage.valueDay.dataSplit.filter(
          (option) => option.startT > timeUpdate,
        );
        selectedTimeStorage.dataMerge[0].dataSplit = dataFilter;
        selectedTimeStorage.valueDay.dataSplit = dataFilter;
      }

      setOptionsDayShow(selectedTimeStorage.dataMerge);
      setValueDay(selectedTimeStorage.valueDay);
      setDataTime(selectedTimeStorage.valueDay.dataSplit);
      setValueSelectTime(selectedTimeStorage.valueSelectTime);
    }
  }, [dispatch, globalSetting]);

  const onChangeDay = (value) => {
    let selectedTimeStorage = readAndParseStorage(SELECTED_TIME);

    setDataTime(value.dataSplit);
    setValueDay(value);

    if (selectedTimeStorage) {
      if (selectedTimeStorage.valueDay.value === value.value) {
        setValueSelectTime(selectedTimeStorage.valueSelectTime);
      } else {
        setValueSelectTime(null);
      }
    } else {
      setValueSelectTime(null);
    }
  };

  const onChangeSelect = (e) => {
    setValueSelectTime(e.target.valueChecked);
  };
  const updateTime = () => {
    if (valueSelectTime) {
      //Add to cart
      const userLogin = readAndParseStorage(USER_LOGIN);
      const orderUser = readAndParseStorage(ORDER_USER);
      if (orderUser) {
        fetch(GET_ORDERS + orderUser.id, {
          method: 'put',
          headers: {
            'Content-Type': 'application/json',
            'X-Customer-Id': userLogin ? userLogin.id : '',
            'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
            'X-Venue-Id': selectedVenue.id,
            'X-Brand-Id': selectedRestaurant.id,
            'X-Address-Id': selectedAddress.id,
          },
          body: JSON.stringify({
            ...orderUser,
            time_slot: selectedTimeToTimeString({
              selectedDate: valueDay,
              selectedTime: valueSelectTime,
            }),
            // valueSelectTime.startT ? moment(valueDay.fullday).tz("Asia/Kuala_Lumpur").set('hour', valueSelectTime.startT.split(':')[0]).set('minute', valueSelectTime.startT.split(':')[1]).format() : null,
            lat: selectedVenue.lat,
            lng: selectedVenue.lng,
          }),
        })
          .then((response) => {
            return response.json();
          })
          .then((results) => {
            if (results.id) {
              localStorage.setItem(ORDER_USER, JSON.stringify(results));
              dispatch(setOrderUserItems(results.order_items));
              localStorage.setItem(
                SELECTED_TIME,
                JSON.stringify({
                  dataMerge: optionsDayShow,
                  valueDay,
                  valueSelectTime,
                }),
              );
              dispatch(
                setSelectedTime({
                  dataMerge: optionsDayShow,
                  valueDay,
                  valueSelectTime,
                }),
              );
              props.onToggle();
              success();
            } else {
              if (results.error_message) {
                message.error(results.error_message);
              } else {
                message.error('Update time error');
              }
            }
          })
          .catch((err) => {
            if (err) {
              showError(err);
            }
          });
      } else {
        localStorage.setItem(
          SELECTED_TIME,
          JSON.stringify({
            dataMerge: optionsDayShow,
            valueDay,
            valueSelectTime,
          }),
        );
        dispatch(
          setSelectedTime({
            dataMerge: optionsDayShow,
            valueDay,
            valueSelectTime,
          }),
        );
        props.onToggle();
        success();
      }
    } else {
      showError({ message: 'Please select time' });
    }
  };

  const success = () => {
    message.success('Update time success');
  };

  return (
    <>
      <div className="popup-dropdown delivery_time_dialog">
        <Card>
          <CardHeader>
            <h2>DELIVERY TIME</h2>
            <Button
              className=""
              color="default"
              type="button"
              onClick={() => props.onToggle()}
            >
              <i className="fas fa-times" />
            </Button>
          </CardHeader>
          <CardBody style={{ height: 330 }} className="mb-4">
            <PerfectScrollbar>
              <div style={{ maxHeight: 300 }}>
                <Select
                  label="Select time"
                  value={valueDay}
                  options={optionsDayShow}
                  onChange={onChangeDay}
                  className="the_social_select"
                  classNamePrefix="the_social_select_"
                />
                {valueDay?.dataSplit.length === 0 && (
                  <div className="text-center">No available time slot</div>
                )}
                <Radio.Group
                  onChange={onChangeSelect}
                  value={valueSelectTime && valueSelectTime.id}
                  className="col-12 p-0"
                >
                  {valueDay?.value === 'today' &&
                    valueDay?.dataSplit.length > 0 && (
                      <div className="custom_control_item_type col-12 the_social_select">
                        <div className="custom-radio">
                          <Radio value="soon" valueChecked={{ id: 'soon' }}>
                            AS SOON AS POSSIBLE
                          </Radio>
                        </div>
                      </div>
                    )}
                  {dataTime.map((item, index) => {
                    return (
                      <div
                        className="custom_control_item_type col-12"
                        key={index}
                      >
                        <div className="custom-radio">
                          <Radio
                            value={item.id}
                            valueChecked={item}
                          >{`${item.startT} - ${item.endT}`}</Radio>
                        </div>
                      </div>
                    );
                  })}
                </Radio.Group>
              </div>
            </PerfectScrollbar>
          </CardBody>
          <CardFooter>
            <Button
              block
              disabled={valueDay?.dataSplit.length === 0}
              color="primary"
              type="button"
              onClick={updateTime}
            >
              UPDATE
            </Button>
          </CardFooter>
        </Card>
      </div>
    </>
  );
};

export default DeliveryTime;
