import React, { useEffect, useRef } from 'react';

// core components
import { useSelector } from 'react-redux';
import DetailLayout from './DetailLayout';
import classNames from 'classnames';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useState } from 'react';

const MainFoodLayout = (props) => {
  const customOverlay = useSelector((state) => state.effect.customOverlay);
  const stripeKey = useSelector((state) => state.global.stripeKey);
  const selectedVenue = useSelector((state) => state.global.selectedVenue);
  const mainEl = useRef(null);
  const [stripePromise, setStripePromise] = useState(null);
  const userLogin = useSelector((state) => state.authentication.userLogin);

  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainEl.current.scrollTop = 0;
  }, []);

  useEffect(() => {
    if (stripeKey && selectedVenue?.id) {
      let stripeParams = {};
      if (selectedVenue?.stripe_connected && selectedVenue?.stripe_account_id) {
        stripeParams.stripeAccount = selectedVenue.stripe_account_id;
      }
      setStripePromise(loadStripe(stripeKey, stripeParams));
    }
  }, [stripeKey, selectedVenue]);
  return (
    <>
      <main ref={mainEl} className="main-food-layout">
        <div className="position-relative">
          <section className={classNames({ 'section': true, 'header_section': true, 'is_logged_in': userLogin})}>
            <div
              id="custom-overlay"
              className={classNames({ 'd-none': !customOverlay })}
            />
            <div className="form_edit_cart">
              {stripePromise && (
                <Elements stripe={stripePromise}>
                  <DetailLayout
                    component={props.detailComponent}
                    foodId={props.match.params.id}
                    orderItemId={props.match.params.id_order_item}
                    {...props}
                  />
                </Elements>
              )}
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default MainFoodLayout;
