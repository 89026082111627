import {
  SET_CART_MODAL,
  SET_STARTED_MODAL,
  SET_SIGN_IN_MODAL,
  SET_CUSTOM_OVERLAY,
  SET_PICK_RESTAURANT_MODAL,
  SET_TOOLTIP_OPEN,
  SET_BODY_4_DETAIL,
  SET_NOT_LOGGED_IN_MODAL,
  SET_PROFILE_MODAL,
  SET_ADD_ADDRESS,
  SET_SHOW_TERMS,
  SET_REFERRAL,
} from '../_constants/actionType';

const initialState = {
  startedModal: false,
  signInModal: {
    status: false,
    type: '',
  },
  referral: {
    referredBy: null,
    reward: 0,
    referringUser: null,
  },
  customOverlay: false,
  pickRestaurantModal: false,
  notLoggedInModal: false,
  profileModal: false,
  cartModal: false,
  tooltipOpen: false,
  body4detail: false,
  addAddress: false,
  showTerms: false,
};
export const effect = (state = initialState, action) => {
  switch (action.type) {
    case SET_STARTED_MODAL:
      return {
        ...state,
        startedModal: action.startedModal,
      };
    case SET_SHOW_TERMS:
      return {
        ...state,
        showTerms: action.showTerms,
      };
    case SET_SIGN_IN_MODAL:
      return {
        ...state,
        signInModal: action.signInModal,
      };
    case SET_CUSTOM_OVERLAY:
      return {
        ...state,
        customOverlay: action.customOverlay,
      };
    case SET_PICK_RESTAURANT_MODAL:
      return {
        ...state,
        pickRestaurantModal: action.pickRestaurantModal,
      };
    case SET_NOT_LOGGED_IN_MODAL:
      return {
        ...state,
        notLoggedInModal: action.notLoggedInModal,
      };
    case SET_TOOLTIP_OPEN:
      return {
        ...state,
        tooltipOpen: action.tooltipOpen,
      };
    case SET_BODY_4_DETAIL:
      return {
        ...state,
        body4detail: action.body4detail,
      };
    case SET_PROFILE_MODAL:
      return {
        ...state,
        profileModal: action.profileModal,
      };
    case SET_CART_MODAL:
      return {
        ...state,
        cartModal: action.cartModal,
      };
    case SET_ADD_ADDRESS:
      return {
        ...state,
        addAddress: action.addAddress,
      };
    case SET_REFERRAL:
      return {
        ...state,
        referral: action.referral,
      };
    default:
      return state;
  }
};
