import {
  SET_MAIN_MEALS_LIST,
  SET_SELECTED_MAIN_MEAL,
  SET_TAGS,
} from '../_constants/actionType';
const initialState = {
  selectedMainMeal: null,
  mainMeals: [],
  tags: [],
};
export const mainMeal = (state = initialState, action) => {
  switch (action.type) {
    case SET_MAIN_MEALS_LIST:
      return {
        ...state,
        mainMeals: action.mainMeals,
      };
    case SET_SELECTED_MAIN_MEAL:
      return {
        ...state,
        selectedMainMeal: action.selectedMainMeal,
      };
    case SET_TAGS:
      return {
        ...state,
        tags: action.tags,
      };
    default:
      return state;
  }
};
