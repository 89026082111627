import {
  SET_SELECTED_RESTAURANT,
  SET_RESTAURANTS_LIST,
  SET_SELECTED_MENU,
  SET_LNG_LAT,
  SET_SELECTED_VENUE,
  SET_SELECTED_ADDRESS,
  SET_GLOBAL_SETTING,
  SET_SHOW_POPUP_ADDRESS,
  SET_SELECTED_TIME,
  SET_SHOW_POPUP_TIME,
  SET_ORDER_USER_ITEMS,
  SET_SHOW_DETAIL,
  SET_SELECTED_ORDER,
  SET_STRIPE_KEY,
  SET_PROCESSING_PAYMENT,
  SET_APP_READY, SET_DELIVERY_VENUES, SET_IS_MOBILE,
} from '../_constants/actionType';

const initialState = {
  selectedRestaurant: null,
  selectedAddress: null,
  selectedTime: null,
  selectedVenue: null,
  deliveryVenues: null,
  lngLat: null,
  globalSetting: null,
  selectedOrder: null,
  orderUserItems: [],
  selectedMenu: 'main',
  restaurants: [],
  cartNumber: 0,
  showPopupAddress: false,
  showPopupTime: false,
  showDetail: false,
  stripeKey: null,
  processingPayment: false,
  isAppReady: false,
  isMobile: false
};
export const global = (state = initialState, action) => {
  switch (action.type) {
    case SET_PROCESSING_PAYMENT:
      return {
        ...state,
        processingPayment: action.processingPayment,
      };
    case SET_STRIPE_KEY:
      return {
        ...state,
        stripeKey: action.stripeKey,
      };
    case SET_SELECTED_RESTAURANT:
      return {
        ...state,
        selectedRestaurant: action.selectedRestaurant,
      };
    case SET_SELECTED_ADDRESS:
      return {
        ...state,
        selectedAddress: action.selectedAddress,
      };
    case SET_SELECTED_TIME:
      return {
        ...state,
        selectedTime: action.selectedTime,
      };
    case SET_SELECTED_VENUE:
      return {
        ...state,
        selectedVenue: action.selectedVenue,
      };
    case SET_DELIVERY_VENUES:
      return {
        ...state,
        deliveryVenues: action.deliveryVenues,
      };
    case SET_RESTAURANTS_LIST:
      return {
        ...state,
        restaurants: action.restaurants,
      };
    case SET_LNG_LAT:
      return {
        ...state,
        lngLat: action.lngLat,
      };
    case SET_SELECTED_MENU:
      return {
        ...state,
        selectedMenu: action.selectedMenu,
      };
    case SET_GLOBAL_SETTING:
      return {
        ...state,
        globalSetting: action.globalSetting,
      };
    case SET_SHOW_POPUP_ADDRESS:
      return {
        ...state,
        showPopupAddress: action.showPopupAddress,
      };
    case SET_SHOW_POPUP_TIME:
      return {
        ...state,
        showPopupTime: action.showPopupTime,
      };
    case SET_ORDER_USER_ITEMS:
      return {
        ...state,
        orderUserItems: action.orderUserItems,
      };
    case SET_SHOW_DETAIL:
      return {
        ...state,
        showDetail: action.showDetail,
      };
    case SET_SELECTED_ORDER:
      return {
        ...state,
        selectedOrder: action.selectedOrder,
      };
    case SET_APP_READY:
      return {
        ...state,
        isAppReady: action.isAppReady,
      };
    case SET_IS_MOBILE:
      return {
        ...state,
        isMobile: action.isMobile,
      };
    default:
      return state;
  }
};
