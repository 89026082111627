import React from 'react';
import { Modal, Button, CardBody, Card, CardHeader } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { setNotLoggedInModal } from '../../_actions/effect.action';

const NotLoggedIn = () => {
  const dispatch = useDispatch();
  const notLoggedInModal = useSelector(
    (state) => state.effect.notLoggedInModal,
  );

  return (
    <>
      <Modal
        className="modal-dialog-centered social_dialog"
        size="sm"
        isOpen={notLoggedInModal}
        toggle={() => dispatch(setNotLoggedInModal(!notLoggedInModal))}
        backdropClassName="social_dialog__bg"
      >
        <div className="modal-body p-0">
          <Card className="border-0">
            <CardHeader>
              <h3>
                LET’S GET YOU STARTED <br />
                WITH SOCIAL EATS
              </h3>
              <Button
                className=""
                color="default"
                type="button"
                onClick={() => dispatch(setNotLoggedInModal(!notLoggedInModal))}
              >
                <i className="fas fa-times" />
              </Button>
            </CardHeader>
            <CardBody className="">
              <Button
                color="primary"
                block
                type="button"
                onClick={() => dispatch(setNotLoggedInModal(!notLoggedInModal))}
              >
                CREATE NEW ACCOUNT
              </Button>
              <Button
                color="primary"
                block
                type="button"
                onClick={() => dispatch(setNotLoggedInModal(!notLoggedInModal))}
              >
                SIGN IN
              </Button>
              <p className="text-center">OR CONTINUE WITH</p>
              <Button
                color="primary"
                block
                type="button"
                onClick={() => dispatch(setNotLoggedInModal(!notLoggedInModal))}
              >
                <i className="fab fa-facebook-square"></i> CONTINUE WITH
                FACEBOOK
              </Button>
            </CardBody>
          </Card>
        </div>
      </Modal>
    </>
  );
};
export default NotLoggedIn;
