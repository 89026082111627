import React, { useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  Container,
  Button,
} from 'reactstrap';

// core components
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { setSelectedMenu } from '../../_actions/global.action';
import Slider from 'react-slick';
import { useHistory } from 'react-router-dom';

const checkIcon = require('assets/img/icons/common/check.svg');
const settings = {
  className: 'center',
  dots: false,
  arrows: false,
  infinite: false,
  variableWidth: true,
  swipeToSlide: true,
  afterChange: function (index) {
    console.log(
      `Slider Changed to: ${index + 1}, background: #222; color: #bada55`,
    );
  },
};

const MealPlansLayout = (props) => {
  const customOverlay = useSelector((state) => state.effect.customOverlay);
  const mainEl = useRef(null);
  const dispatch = useDispatch();
  const history = useHistory();
  const userLogin = useSelector((state) => state.authentication.userLogin);

  useEffect(() => {
    dispatch(setSelectedMenu('meal-plans'));
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainEl.current.scrollTop = 0;
  }, [dispatch]);
  return (
    <>
      <main ref={mainEl}>
        <div className="position-relative">
          <section className={classNames({ 'section': true, 'header_section': true, 'is_logged_in': userLogin})}>
            <div
              id="custom-overlay"
              className={classNames({ 'd-none': !customOverlay })}
            />
            <Container fluid className="p-0">
              <div className="body4detail meal-plans">
                <div className="body4Detail__header">
                  <div>
                    <img
                      alt="The Social"
                      src={require('assets/img/brand/the-social-logo-dark.svg')}
                    />
                    <h1>MEAL PLANS</h1>
                    <p>
                      plan your week with your affordable meal plans from the
                      social
                    </p>
                  </div>
                </div>
                <Container className="body4Detail__body">
                  <Container className="banner-detail">
                    <Row>
                      <Col md="6" className="banner-detail_more_deals">
                        <div className="list">
                          <h2 className="banner-detail_more_deals_title">
                            WHAT YOU’LL GET
                          </h2>
                          <div className="banner-detail_more_deals__list">
                            <div className="restaurant__btn">
                              <img alt="The Social" src={checkIcon} />
                              <div className="pick_restaurant__text">
                                <div className="custom__btn_name">
                                  <h2>AFFORDABLE</h2>
                                  <span>
                                    SAVE UP TO 55% WITH OUR MEAL PLANS
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="restaurant__btn">
                              <img alt="The Social" src={checkIcon} />
                              <div className="pick_restaurant__text">
                                <div className="custom__btn_name">
                                  <h2>FLEXIBLE</h2>
                                  <span>
                                    HASSLE-FREE - NO MINIMUM ORDER & TAX
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="restaurant__btn">
                              <img alt="The Social" src={checkIcon} />
                              <div className="pick_restaurant__text">
                                <div className="custom__btn_name">
                                  <h2>ALWAYS FRESH</h2>
                                  <span>WEEKLY MENU CHANGES</span>
                                </div>
                              </div>
                            </div>

                            <div className="restaurant__btn">
                              <img alt="The Social" src={checkIcon} />
                              <div className="pick_restaurant__text">
                                <div className="custom__btn_name">
                                  <h2>REWARDS</h2>
                                  <span>
                                    EARN EXCLUSIVE REWARDS WHILE SUBSCRIBED
                                  </span>
                                </div>
                              </div>
                            </div>

                            <div className="restaurant__btn">
                              <img alt="The Social" src={checkIcon} />
                              <div className="pick_restaurant__text">
                                <div className="custom__btn_name">
                                  <h2>PLAN AHEAD</h2>
                                  <span>
                                    CHOOSE FROM BREAKFAST, LUNCH OR DINNER
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="text-center">
                            <div className="subscribe">
                              <div className="save_up">
                                <h2>SAVE UP TO RM 80</h2>
                              </div>
                              <h1>RM 90 FOR 6 MEALS</h1>
                            </div>
                          </div>
                          <p className="text-center __desc">
                            note: meal plans are exclusive to the restaurant you
                            subscribed to. For example if you purchased a meal
                            plan via the social, it is not eligible for the
                            other restaurants we carry and needs to be purchased
                            seperately.
                          </p>
                        </div>
                      </Col>

                      <Col md="6" className="banner-detail_more_deals">
                        <div className="list">
                          <h2 className="banner-detail_more_deals_title">
                            PREVIEW TODAY’S MENU
                          </h2>
                          <div className="_sliders">
                            <div className="_slider">
                              <h2>BREAKFAST</h2>
                              <p>8 AM - 11 AM</p>
                              <Slider {...settings}>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        CRISP SKIN SALMON TROUT
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        BUTTERMILK CHICKEN BURGER
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        BUTTERMILK CHICKEN BURGER
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        BUTTERMILK CHICKEN BURGER
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                              </Slider>
                            </div>

                            <div className="_slider">
                              <h2>LUNCH</h2>
                              <p>11 AM - 5 PM</p>
                              <Slider {...settings}>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        CRISP SKIN SALMON TROUT
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        BUTTERMILK CHICKEN BURGER
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        BUTTERMILK CHICKEN BURGER
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        BUTTERMILK CHICKEN BURGER
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Button
                          onClick={() => history.push('/meal-plans-detail')}
                          className="btn-get-this-plan mb-5"
                          block
                          color="primary"
                          type="button"
                        >
                          GET THIS MEAL PLAN
                        </Button>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>
            </Container>
          </section>
        </div>
      </main>
    </>
  );
};

export default MealPlansLayout;
