import React from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import CartItem from 'components/cart/CartItem';

const Tracking = () => {
  const cartItems = useSelector((state) => state.cart.cart.items);
  const history = useHistory();
  return (
    <>
      <Container className="review_order _tracking">
        <Row>
          <Col md="12" className="_header">
            <h1>25 MINUTES</h1>
            <p>ESTIMATED ARRIVAL</p>
            <Button
              className="close_detail_btn second"
              color="default"
              type="button"
              onClick={() => history.push('/')}
            >
              <i className="fas fa-times" />
            </Button>
          </Col>
        </Row>
        <Row className="_body">
          <Col md="12" className="_progress">
            <div className="_progress_contain">
              <div className="_10 _step _active" />
              <div className="_50 _step _active" />
              <div className="_70 _step" />
              <div className="_100 _step" />
            </div>
            <p>YOUR ORDER IS BEING PREPARED</p>
          </Col>
          <Col md="7" className="_details">
            <div className="_list_items">
              {cartItems.map((item, index) => {
                return (
                  <div key={index}>
                    <CartItem readOnly={true} cart={item} /> <hr />
                  </div>
                );
              })}
            </div>
            <div className="_total_paid">
              <p>TOTAL PAID</p>
              <p>RM 65.50</p>
            </div>
          </Col>

          <Col md="5" className="_summary">
            <div className="_map" />
            <p className="_address">
              DELIVERY FROM THE SOCIAL AT 163 RETAIL PARK
            </p>
            <hr />
            <div className="_driver">
              <div className="_avatar">
                <img alt="img" src="/img/common/driver-test.jpg" />
                <div>
                  <p className="_name">Darika Samak</p>
                  <p className="_bikeID">BJP329</p>
                </div>
              </div>
              <Button block color="primary" type="button">
                <i className="fas fa-phone-volume" /> ESTIMATED ARRIVAL
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default Tracking;
