import { STRIPE_PUBLIC_KEY } from '../_constants';
import {
  SET_SELECTED_RESTAURANT,
  SET_RESTAURANTS_LIST,
  SET_SELECTED_MENU,
  SET_LNG_LAT,
  SET_SELECTED_VENUE,
  SET_SELECTED_ADDRESS,
  SET_GLOBAL_SETTING,
  SET_SHOW_POPUP_ADDRESS,
  SET_SELECTED_TIME,
  SET_SHOW_POPUP_TIME,
  SET_ORDER_USER_ITEMS,
  SET_SHOW_DETAIL,
  SET_SELECTED_ORDER,
  SET_STRIPE_KEY,
  SET_PROCESSING_PAYMENT,
  SET_APP_READY, SET_DELIVERY_VENUES, SET_IS_MOBILE,
} from '../_constants/actionType';

export function setSelectedRestaurant(selectedRestaurant) {
  return {
    type: SET_SELECTED_RESTAURANT,
    selectedRestaurant,
  };
}

export function setSelectedAddress(selectedAddress) {
  return {
    type: SET_SELECTED_ADDRESS,
    selectedAddress,
  };
}

export function setSelectedTime(selectedTime) {
  return {
    type: SET_SELECTED_TIME,
    selectedTime,
  };
}

export function setSelectedVenue(selectedVenue) {
  return {
    type: SET_SELECTED_VENUE,
    selectedVenue,
  };
}

export function setDeliveryVenues(deliveryVenues) {
  return {
    type: SET_DELIVERY_VENUES,
    deliveryVenues,
  };
}

export function setLngLat(lngLat) {
  return {
    type: SET_LNG_LAT,
    lngLat,
  };
}

export function setRestaurantsList(restaurants) {
  return {
    type: SET_RESTAURANTS_LIST,
    restaurants,
  };
}

export function setSelectedMenu(selectedMenu) {
  return {
    type: SET_SELECTED_MENU,
    selectedMenu,
  };
}

export function setGlobalSetting(globalSetting) {
  return {
    type: SET_GLOBAL_SETTING,
    globalSetting,
  };
}

export function setStripeKey(stripeKey) {
  localStorage.setItem(STRIPE_PUBLIC_KEY, stripeKey);
  return {
    type: SET_STRIPE_KEY,
    stripeKey,
  };
}

export function setShowPopupAddress(showPopupAddress) {
  return {
    type: SET_SHOW_POPUP_ADDRESS,
    showPopupAddress,
  };
}

export function setShowPopupTime(showPopupTime) {
  return {
    type: SET_SHOW_POPUP_TIME,
    showPopupTime,
  };
}

export function setOrderUserItems(orderUserItems) {
  return {
    type: SET_ORDER_USER_ITEMS,
    orderUserItems,
  };
}

export function setShowDetail(showDetail) {
  return {
    type: SET_SHOW_DETAIL,
    showDetail,
  };
}

export function setSelectedOrder(selectedOrder) {
  return {
    type: SET_SELECTED_ORDER,
    selectedOrder,
  };
}

export function setProcessingPayment(processingPayment) {
  return {
    type: SET_PROCESSING_PAYMENT,
    processingPayment,
  };
}

export function setIsAppReady(isAppReady) {
  return {
    type: SET_APP_READY,
    isAppReady,
  };
}

export function setIsMobile(isMobile) {
  return {
    type: SET_IS_MOBILE,
    isMobile,
  };
}
