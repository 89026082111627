import React from 'react';
import { Container } from 'reactstrap';

const DetailLayout = (props) => {
  return (
    <>
      <Container fluid className="p-0">
        <div className="body4detail">
          <div className="body4Detail__header" />
          <Container className="body4Detail__body">
            <props.component {...props} />
          </Container>
        </div>
      </Container>
    </>
  );
};
export default DetailLayout;
