import {
  SET_MAIN_MEALS_LIST,
  SET_SELECTED_MAIN_MEAL,
  SET_TAGS,
} from '../_constants/actionType';

export function setMainMealsList(mainMeals) {
  return {
    type: SET_MAIN_MEALS_LIST,
    mainMeals,
  };
}

export function setTags(tags) {
  return {
    type: SET_TAGS,
    tags,
  };
}

export function setSelectedMainMeal(selectedMainMeal) {
  return {
    type: SET_SELECTED_MAIN_MEAL,
    selectedMainMeal,
  };
}
