import { SET_BANNERS } from '../_constants/actionType';
const initialState = {
  banners: [],
};
export const banner = (state = initialState, action) => {
  switch (action.type) {
    case SET_BANNERS:
      return {
        ...state,
        banners: action.banners,
      };
    default:
      return state;
  }
};
