import React, { useCallback, useEffect, useState } from 'react';
import { Row, Col, Container, Button } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import TagManager from 'react-gtm-module';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedMainMeal } from '../../_actions/mainMeal.action';
import { GET_ORDERS } from '../../api/APIs';
import axios from '../../api/CustomAxios';
import moment from 'moment';
import { Pagination, Tooltip, message } from 'antd';
import { SyncOutlined } from '@ant-design/icons';
import { setShowDetail, setSelectedOrder } from '../../_actions/global.action';
import CheckTime from '../common/checkTime';
import { showError } from '../../_helper/index';

const limit = 10;

const tagManagerArgs = {
  dataLayer: {
    page: 'Home',
  },
  dataLayerName: 'PageDataLayer',
};

const MyOrders = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [completedOrdersAll, setCompletedOrdersAll] = useState([]);
  const [completedOrders, setCompletedOrders] = useState([]);
  const [activeOrders, setActiveOrders] = useState([]);
  const [page, setPage] = useState(1);
  TagManager.dataLayer(tagManagerArgs);

  const selectedOrder = useSelector((state) => state.global.selectedOrder);

  const fetchOrders = useCallback((propsMess) => {
    //cal api USER INFO
    axios
      .get(`${GET_ORDERS}/completed?per_page=10000`)
      .then((res) => {
        setCompletedOrdersAll(
          res.data.filter((item) => item.restaurant_info !== null),
        );
        const crrData = res.data
          .filter((item) => item.restaurant_info !== null)
          .slice(0, limit);
        setCompletedOrders([...crrData]);
        if (propsMess?.message) {
          message.success(propsMess.message);
        }
      })
      .catch((error) => {
        showError(error);
      });
    axios
      .get(`${GET_ORDERS}/todo`)
      .then((res) => {
        setActiveOrders(res.data);
      })
      .catch((error) => {
        showError(error);
      });
  }, []);

  useEffect(() => {
    fetchOrders();
  }, [fetchOrders]);

  useEffect(() => {
    if (selectedOrder && selectedOrder.status === 'completed') {
      fetchOrders();
    }
  }, [selectedOrder, fetchOrders]);

  const onChangePage = (pageNumber) => {
    const offset = (pageNumber - 1) * limit;
    const crrData = [...completedOrdersAll].slice(offset, offset + limit);
    setPage(pageNumber);
    setCompletedOrders([...crrData]);
  };

  const orderItem = (order) => {
    return (
      <div
        className="_h_item"
        onClick={() => {
          axios
            .get(`${GET_ORDERS}${order.id}`)
            .then((res) => {
              let data = res.data;
              data.timeDetail = CheckTime(data);
              dispatch(setSelectedOrder(data));
              dispatch(setShowDetail(true));
            })
            .catch((error) => {
              showError(error);
            });
        }}
      >
        <div
          className="_img"
          style={{ backgroundImage: `url(${order.restaurant_info.logo_url})` }}
        ></div>
        <div className="_info">
          <h3>{order.restaurant_info.brand_name}</h3>
          <small>
            {moment(order.time_slot)
              .tz('Asia/Kuala_Lumpur')
              .format('DD MMM, HH:mm a')}{' '}
            -{' '}
            {moment(order.time_slot)
              .add(30, 'minutes')
              .tz('Asia/Kuala_Lumpur')
              .format('HH:mm a')}
          </small>
        </div>
        <div className="_action">
          <button>
            <span className="mr-2">RM {order.total}</span>
            <i className="fas fa-chevron-right" />
          </button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Container className="component_detail">
        <Row>
          <Col md="12" className="component_detail_content">
            <div className="_header">
              <h1>MY ORDERS</h1>
              <p>your active and completed orders</p>
              <Button
                className="close_detail_btn second"
                color="default"
                type="button"
                onClick={() => {
                  dispatch(setSelectedMainMeal(null));
                  history.push('/');
                }}
              >
                <i className="fas fa-times" />
              </Button>
            </div>
            <Container className="_body">
              <Row>
                <Col>
                  <div className="orders" style={{ position: 'relative' }}>
                    <div className="icon_refresh">
                      <Tooltip title="CLICK TO REFRESH LIST ORDERS">
                        <SyncOutlined
                          style={{ fontSize: '18px', color: '#E1AC6F' }}
                          onClick={() => {
                            setPage(1);
                            fetchOrders({
                              message: 'Refresh Success',
                            });
                          }}
                        />
                      </Tooltip>
                    </div>
                    <Row>
                      <Col className="pr-lg-4 pl-lg-4">
                        <h2>ACTIVE ORDERS</h2>
                        <Row>
                          {activeOrders &&
                            activeOrders.map((order, index) => {
                              return (
                                <Col md="12" key={index}>
                                  {orderItem(order)}
                                </Col>
                              );
                            })}
                        </Row>
                      </Col>
                    </Row>
                    <hr></hr>
                    <Row>
                      <Col className="pr-lg-4 pl-lg-4">
                        <h2>COMPLETED ORDERS</h2>
                        <Row>
                          {completedOrders &&
                            completedOrders.map((order, index) => {
                              return (
                                <Col md="12" key={index}>
                                  {orderItem(order)}
                                </Col>
                              );
                            })}
                        </Row>
                        <div className="pagination_myorder mt-3">
                          <Pagination
                            current={page}
                            defaultPageSize={limit}
                            showLessItems
                            total={completedOrdersAll.length}
                            showSizeChanger={false}
                            onChange={onChangePage}
                          />
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default MyOrders;
