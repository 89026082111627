import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setShowDetail } from '../../_actions/global.action';
import OrderType from '../common/order_type';
import moment from 'moment';
import { Button, Modal, Card, CardHeader, CardBody } from 'reactstrap';

function ViewDetailOrder(props) {
  const dispatch = useDispatch();
  const showDetail = useSelector((state) => state.global.showDetail);
  const selectedOrder = useSelector((state) => state.global.selectedOrder);
  const [titleOrderStatus, setTitleOrderStatus] = useState('');

  useEffect(() => {
    let titleStatus = '';
    if (selectedOrder) {
      titleStatus = OrderType(selectedOrder);
    }
    setTitleOrderStatus(titleStatus);
  }, [selectedOrder]);

  return (
    <Modal
      className="modal-dialog-centered social_dialog bill modal_my_order mt-0"
      size="md"
      isOpen={showDetail}
      toggle={() => dispatch(setShowDetail(!showDetail))}
      backdropClassName="social_dialog__bg"
    >
      <div className="modal-body p-0">
        <Card className="border-0">
          <CardHeader>
            <p></p>
            <Button
              className=""
              color="default"
              type="button"
              onClick={() => dispatch(setShowDetail(!showDetail))}
            >
              <i className="fas fa-times" />
            </Button>
          </CardHeader>
          {selectedOrder && (
            <CardBody className="">
              <div className="text-center">
                <p>Order Number: {selectedOrder.order_no}</p>
                <strong className="order_status">
                  {selectedOrder.restaurant_info.venue_name}
                </strong>
                <p className="phone">
                  Contact:{' '}
                  <a href={`tel:${selectedOrder?.venue?.phone_number}` || '#'}>
                    {selectedOrder?.venue?.phone_number}
                  </a>
                </p>
                {selectedOrder.status !== 'completed' && (
                  <>
                    <p className="mt-4 pt-1">
                      <strong>{titleOrderStatus}</strong>
                    </p>
                    <h3 className="mt-2">{selectedOrder.timeDetail}</h3>
                    <h3 className="mt-2" style={{ fontWeight: 300 }}>
                      Estimated {selectedOrder.order_type} Time
                    </h3>
                  </>
                )}
                <hr></hr>
              </div>
              <div className="box">
                <h4>Order details</h4>
                {selectedOrder.order_type === 'delivery' ? (
                  <>
                    <p className="address">
                      Deliver To: {selectedOrder.address_info.full_address}
                    </p>
                    <p className="address">
                      Deliver From: {selectedOrder.restaurant_info.brand_name} -{' '}
                      {selectedOrder.restaurant_info.venue_name}
                    </p>
                  </>
                ) : (
                  <>
                    <p className="address">
                      Pick Up From: {selectedOrder.restaurant_info.brand_name} -{' '}
                      {selectedOrder.restaurant_info.venue_name}
                    </p>
                  </>
                )}

                <p className="address">
                  Time Slot:{' '}
                  {moment(selectedOrder.time_slot)
                    .tz('Asia/Kuala_Lumpur')
                    .format('DD MMM, HH:mm a')}{' '}
                  -{' '}
                  {moment(selectedOrder.time_slot)
                    .add(30, 'minutes')
                    .tz('Asia/Kuala_Lumpur')
                    .format('HH:mm a')}
                </p>
              </div>
              <hr></hr>
              <div className="box">
                <h4>Order summary</h4>
                {selectedOrder.order_items.map((item, index) => {
                  return (
                    <div key={index}>
                      <p className="address _bold">
                        <span className="name">
                          <small>x</small>
                          {item.quantity} {item.product.title}
                        </span>
                        <span>RM {item.price}</span>
                      </p>
                      {item.order_item_options.map((option, i) => {
                        return (
                          <p className="address" key={i}>
                            <span className="name pl-2">
                              {option.option.name}
                            </span>
                            <span>RM {option.price}</span>
                          </p>
                        );
                      })}
                    </div>
                  );
                })}
              </div>
              <hr></hr>
              <div className="box">
                <p className="address">
                  <span className="name">SUB TOTAL</span>
                  <span>RM {selectedOrder.item_total}</span>
                </p>
                {selectedOrder.order_extra_fees.map((item, index) => {
                  return (
                    <p className="address" key={index}>
                      <span className="name">{item.title}</span>
                      <span>{item.formatted_amount}</span>
                    </p>
                  );
                })}

                {selectedOrder.order_promotions &&
                  selectedOrder.order_promotions.map((item, index) => {
                    return (
                      <p className="address" key={index}>
                        <span className="name">{item.voucher_title}</span>
                        <span>- {item.formatted_computed_amount}</span>
                      </p>
                    );
                  })}
                <p className="address _bold">
                  <span className="name">TOTAL</span>
                  <span>RM {selectedOrder.total}</span>
                </p>
                <p className="address _bold">
                  <span className="name">Paid With</span>
                  <span>
                    {selectedOrder.checkout
                      ? selectedOrder.checkout.formatted_method
                      : ''}
                  </span>
                </p>
              </div>
              <hr></hr>
              <div className="box">
                <p className="address _bold">
                  <span className="name">Points Earned</span>
                  <span>
                    +{' '}
                    {selectedOrder.checkout
                      ? selectedOrder.checkout.points_earned
                      : ''}{' '}
                    PTS
                  </span>
                </p>
              </div>
            </CardBody>
          )}
        </Card>
      </div>
    </Modal>
  );
}

export default ViewDetailOrder;
