import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setShowTerms } from '../../_actions/effect.action';
import { Button, Modal, Card, CardHeader, CardBody } from 'reactstrap';
import axios from 'axios';
import { GET_TERMS } from 'api/APIs';

function Terms() {
  const dispatch = useDispatch();
  const showTerms = useSelector((state) => state.effect.showTerms);
  const [terms, setTerms] = useState(null);

  useEffect(() => {
    axios.get(GET_TERMS).then((res) => {
      setTerms(res.data);
    });
  }, []);

  return (
    <Modal
      className="modal-dialog-centered social_dialog mt-0"
      size="lg"
      isOpen={showTerms}
      toggle={() => dispatch(setShowTerms(!showTerms))}
      backdropClassName="social_dialog__bg"
    >
      <div className="modal-body p-0">
        <Card className="border-0">
          <CardHeader>
            <h2 style={{ fontSize: '1rem' }}>PRIVACY POLICY & TERMS OF USE</h2>
            <Button
              className=""
              color="default"
              type="button"
              onClick={() => dispatch(setShowTerms(!showTerms))}
            >
              <i className="fas fa-times" />
            </Button>
          </CardHeader>
          <CardBody className="">
            <div dangerouslySetInnerHTML={{ __html: terms }}></div>
            {/* <ReactMarkdown>
              {`## TERMS OF USE

Welcome to The Social Reward App, a digitized loyalty, and membership platform for The Social users to earn points, get instant rebates and enjoy users-only promotions (the “Platform”), provided by BlueFire (M) Sdn Bhd (184335-D) (“The Social”). These terms of use (“Terms of Use”) govern your access to and use of the Platform, whether as a guest or a registered user, and provide you with an understanding of the Platform for a safe and pleasurable visit.

### 1.	ACCEPTANCE OF TERMS

By visiting, browsing, adding information or creating an account at this Platform, you are deemed to have accepted these Terms of Use in full with effect from the date you first use this Platform. If you do not agree to these terms, please immediately discontinue use of the Platform. We reserve the right to change these Terms of Use from time to time in our sole and absolute discretion without notice. Such changes will become effective and binding immediately upon posting to this Platform and you will be deemed to have accepted such changes by your continued use of this Platform.

### 2.	YOUR PRIVACY

We respect and are committed to protect your privacy. Please refer to our www.thesocial.com.my, which forms part of these Terms of Use.

### 3.	USE OF PLATFORM

You represent and agree that you will not use this Platform, nor allow anyone to use this Platform, (a) contrary to The Social’s reputation, public image, or goodwill, (b) in violation of any law, regulation, ordinance or court order, (c) in any manner that could damage, disable, overburden or otherwise impair the ability of The Social to make this Platform available to other users or to interfere with the use and enjoyment of this Platform by other users, (d) to create, copy, upload, download, store, publish, distribute, broadcast, transmit or otherwise disseminate any content, information or communication that (i) infringes, misappropriates or otherwise violates any third party patent, copyright, trademark, trade secret, right of privacy, right of publicity, moral rights or any other intellectual property right, or (ii) may be deemed libelous or slanderous, (e) to harass, intimidate, threaten or encourage any violence against any person, group or organization, (f) to transmit or otherwise disseminate any spam e-mails, viruses, corrupted files, spyware, malware, or any other software or code that may damage or impair the operation or security of any computer, hardware, software, system or service, (g) to gain or attempt to gain unauthorized access to any computer, hardware, software, system, service, account, communication or information of The Social, any other users or any third party, or (h) to engage in any conduct that could expose The Social to civil or criminal liability or (i) to engage in any conduct that could be damaging, harassing or aggravating to any other The Social users (“Prohibited Conduct”).

### 4.	USER ACCOUNT

Only one account per user. You represent and agree that all information you provide in creating an account and placing orders for products and services through this Platform is truthful and accurate. If you provide any information that is untrue, inaccurate, not current or incomplete (or becomes untrue, inaccurate, not current or incomplete), or The Social has reasonable grounds to suspect that such information is untrue, inaccurate, not current or incomplete, The Social has the right to suspend or terminate your account and refuse any and all current or future use of the Platform (or any portion thereof). Creating fraudulent accounts in order to manipulate the results of any contest on The Social will result in disqualification. Any accounts related to unverifiable email addresses or mobile phone numbers will also be disqualified. The Social may, in its sole discretion, terminate or suspend any user’s account to all or part of the Platform for breach of any of these Terms of Use, or taking actions that are inconsistent with their intent. The Social shall be the sole determiner in cases of suspected abuse, fraud, or breach of these Terms of Use or intent of these Terms of Use. You accept all responsibility for activities that occur under your username, account, and password, and for the accuracy of all information you submit when using this Platform. You are responsible for maintaining the confidentiality of your username, account, and password and for maintaining the security of your devices including restriction of access. You should notify us immediately if you determine or suspect that your account is being used without your authorization. You may contact us via email if you intend to terminate your account.

### 5.	CREDITS

You may purchase credits which may be used to pay for our products and/or services. You may choose to purchase your user credits through any of the methods as may be made available by The Social from time to time, including but is not limited to the top-up method with The Social Gift Card. The Social Gift Card, once purchased, shall be valid for topping up from the date of purchase until the end of the 6th calendar month following your purchase.

The Social may at its sole discretion reject your request to purchase your user credits for any reason whatsoever, including without limitation, where your proposed credits purchase would result in the aggregate amount of stored value held by The Social, alone or together with any person over whom The Social has control or influence, to exceed any value permitted under the prevailing laws and regulations.

Your user credits will be valid from the date of your purchase, top-up or earn, whichever is applicable and shall expire at the end of the 6th calendar month following your purchase, top-up or earn. The expiry date as indicated in the Platform shall serve as a conclusive evidence of the expiry date of your user credits. The Social shall have the right to deal with the expired credits in such manner as it deems fit in its absolute discretion. Upon expiry of your user credits, you hereby agree that you have no claim whatsoever against The Social for any expired user credits.

Your user credits are not redeemable for cash nor are they refundable under any circumstances. The credits cannot be resold, exchanged or transferred for value under any circumstances. These credits shall in no event be regarded, construed, or used as valuable or exchangeable instruments. You will not receive any interest or other earnings on your user credits. 

The credits balance as set out in the Platform shall serve as a conclusive evidence of your user credits balance. In the event that you terminate your user account for whatsoever reason, all then outstanding credits at such point of time shall be automatically cancelled. Credits should be spent before termination of account as once cancelled, credits cannot be utilized. No accumulation or carry-over of credits will be permitted even if you subsequently reinstate your user account.

### 6.	PAYMENT THROUGH THE PLATFORM

The Platform allows you to settle your payment for our products and/or services by scanning a designated quick response code (“QR Code”) provided to you. The Platform payment feature allows you to pay via a credit/debit card or available credits in your user account. Please refer to Section 5 for more details about the credits in your user account.

By paying via the Platform, you allow us to process your payment by (i) holding the credit/debit card number provided by you and charge the card for the transaction at the time the products and/or services are transacted or provided, or (ii) deduct the available credits for a value equivalent to the product and/or services provided. This, however, does not waive our right to seek payment directly from you through alternative methods if payment via the Platform fails for whatsoever reason.

With payment by card, you must provide us with a current, valid and accepted payment card (“Card”) which belongs to you in accordance with the instruction within the Platform. If the Card belongs to another person, you hereby warrant and agree that you have obtained their permission to use the Card for payment of our products and/or services.

You agree that we may verify and authorize your Card information when you first register the Card with us as well as when you use the Platform. You further agree that we may issue a reasonable authorization hold, which is not an actual charge against your Card, in order to verify your payment method via your Card. The authorization hold is issued as a preventive measure against any unauthorized or fraudulent usage of your Card. In the event that your payment by your Card is processed overseas, you will be liable for any additional charges in relation thereto.

You may edit your Card by changing it on the “Manage Credit Card” feature of the Platform. If a payment is not successfully settled, due to expiration, insufficient funds or credits, or otherwise, and you do not edit your Card information or top up your credits, you remain responsible for the settlement of any unpaid products and/or services.

We use third-party payment service providers to facilitate your payment via our Platform. The terms of your payment will be based on your Card and may be determined by agreement between you and your financial institution, credit/debit issuer or other providers of your selected Card. We disclaim all liabilities associated with the security of the Card. You shall be responsible to resolve any disputes with your financial institution, credit/debit issuer or other providers of your selected Card. The Social has the right to suspend the processing of any transaction where it reasonably believes that the transaction may be fraudulent, illegal or involves any criminal activity or where The Social reasonably believes you to be in breach of these Terms of Use.

Your purchase of our products and/or services may be subject to applicable taxes, whenever required.

In the event of any question or dispute in relation to any transaction made through the Platform, please contact us via our “Support” feature on the Platform. Any refunds, if approved by us, may be paid to you in the form of credits in your user account or by way of adjustment to the payment card transaction (if feasible). The Social shall have the sole discretion in determining its refund process.

### 7.	LOYALTY PROGRAM

By using the Platform, you will automatically be a member of the loyalty program operated by The Social and/or its affiliates (“The Social Loyalty Program”).

The Social may, at its sole and absolute discretion, award you points (“Points”) which can be used for Rewards on your actual spending that goes towards paying for our products and/or services. The Company will issue you 1 Point for every RM1.00 that you spend on our products and/or services (tax and service charge excluded). The Points are calculated on a per transaction basis and rounded down to the nearest whole number.

The Social may, at its sole and absolute discretion, increase or decrease the rate of Points to be awarded for each selected transaction as may be notified to you from time to time. You shall not be entitled to request from us any explanation pertaining to the calculation methods for crediting the Points or other matters relating to the Points or discounts for whatsoever purposes.

By paying via the Platform, your Points entitlement will automatically be added to your account. If you opt to pay by alternative methods outside of the Platform, you may, however, obtain your Points entitlement by scanning the designated QR Code. All QR Codes issued by our system will have a validity period of 30 minutes or such other validity period as may be determined by The Social from time to time. Only Points obtained through a QR Code issued in respect of your successful transaction with The Social will be considered as valid. The Social reserves its absolute right to cancel any Point(s) obtained through unauthorized manner.

All Points shall have no cash or monetary value and are not redeemable for cash in any form. They cannot be purchased, resold or transferred, for value or otherwise, under any circumstances. Points shall not be regarded, construed, or used as valuable or exchangeable instruments under any circumstances.

If you do not wish to be a member of The Social Loyalty Program, you may opt out through the Platform or by contacting us. You will automatically cease to be a member of The Social Loyalty Program if you delete the Platform from your devices. Upon cessation of your membership, all then outstanding Points at such point of time shall be automatically cancelled. Points should be redeemed before ceasing membership as once cancelled, Points cannot be redeemed. No accumulation or carry over of Points will be permitted even if you subsequently reinstate your membership. Fraud or abuse of redemptions may result in the forfeiture of accumulated Points as well as cancellation of your membership in The Social Loyalty Program.

### 8.	REDEMPTION OF REWARDS

You may select and redeem applicable Rewards via the Platform by using your acquired Points. Your redemption of a Reward (e.g. e-voucher or gift card) is subject to any additional terms and conditions as may be imposed thereto. You must use your Rewards redeemed during their applicable validity period. You agree that you will have no claim whatsoever against The Social for any expired e-vouchers.

Rewards are not redeemable for cash nor are they exchangeable for other Rewards under any circumstances. They shall not be regarded, construed, or used as valuable or exchangeable instruments under any circumstances. Points that you have successfully redeemed for a Reward cannot be exchangeable for another Reward under any circumstances. No requests for exchange of Rewards will be entertained. 

The Social may at its sole direction reject your request to redeem Points for any reason whatsoever, including without limitation, where there are insufficient Points to redeem for the particular Reward, or where the Reward is no longer made available, or where the Points you wish to use for redemption of Rewards have been issued to you in error.

Your Points balance and redemptions as set out in the Platform shall serve as a conclusive evidence of the same.

In the event of any question or dispute in relation to any redemption of Rewards or any use of the Rewards thereafter, please contact us via our “Support” feature on the Platform. We may refund you in the form of credits in your user account. 


### 9.	OUR ELECTRONIC COMMUNICATIONS TO YOU

You agree to receive communications from us electronically. We will communicate with you by email or SMS, as the case may be. You agree that all agreements, notices, disclosures and other communications that we provide to you electronically satisfy any legal requirement that such communications be in writing.

### 10.	INTELLECTUAL PROPERTY

This Platform and its contents are intended for your personal use. Unless otherwise noted, all content on this Platform, including text, graphics, images, logos, and software, is the property of The Social or its third party content providers, and is protected under the applicable intellectual property laws. You may display, copy, download and print hard copy portions of content appearing on this app solely for your own non-commercial use, to make payment and redemption, or any other purposes as contemplated within the use of this Platform. Except for such expressly permitted uses of content appearing on this Platform, you may not modify, publish, adapt, transmit, participate in the transfer or sale, exhibit, distribute, display, reverse engineer, decompile, dissemble, perform, reproduce, create derivative works from, or in any way exploit any content appearing on this Platform, in whole or in part. Any unauthorized use of any content appearing on this Platform may violate copyright, trademark and other laws and could result in criminal or civil penalties. You shall be solely responsible and liable for any and all damages resulting from any infringement of copyrights, proprietary rights, or any other harm resulting from your breach of this Section 10.

### 11.	PROTECTING CHILDREN

We take special care to protect the safety and privacy of children. This Platform and any affiliated The Social applications are general audience applications, but The Social does not knowingly collect personal information from anyone under the age of 18 without parental consent. Children under the age of 18 should always ask their parents or guardians for permission before providing any personal information to anyone online.

### 12.	LINKS TO THIRD PARTY APPS

This Platform may contain links to other apps on the Internet that are owned and operated by third parties. You agree that we are not responsible for the operation of or content located on or through any such app nor are we responsible for the actions of any third party. You shall refer to the third parties’ terms of use, privacy policy and any other policy governing their apps, platforms and related contents, products or services.

### 13.	DISCLAIMER OF WARRANTIES AND LIMITED OF LIABILITY

This Platform and all information, content, materials, products and services included on or otherwise made available to you through this site are provided by The Social on an "as is" and "as available" basis unless otherwise specified in writing. The Social makes no representations or warranties of any kind, express or implied, as to the operation of this Platform or the information, content, materials, products (including software) or services included on or otherwise made available to you through this Platform, unless otherwise specified in writing. You expressly agree that your use of this Platform is at your sole risk. To the full extent permissible by applicable law, The Social disclaims all warranties, express or implied, including, but not limited to, implied warranties of merchantability and fitness for a particular purpose. The Social does not warrant that this Platform, the information, content, materials, products or services included on or otherwise made available to you through this Platform, their servers or electronic communications sent from The Social are free of viruses or other harmful components. The Social will not be liable for any damages of any kind arising from the use of this Platform or from any information, content, materials, products or services included on or otherwise made available to you through this Platform, including, but not limited to direct, indirect, incidental, punitive, and consequential damages, unless otherwise specified in writing. Certain country laws do not allow limitations on implied warranties or the exclusion or limitation of certain damages. If these laws apply to you, some or all of the above disclaimers, exclusions, or limitations may not apply to you, and you may be entitled to additional rights.

### 14.	INDEMNIFICATION

You agree to defend, indemnify and hold harmless The Social and its affiliates, officers, directors, owners, employees, advisors and agents, from and against any and all claims, damages, liabilities and causes of action (including reasonable solicitor’s cost) arising out of or relating to your use of this Platform, your breach of these Terms of Use, your engaging in any Prohibited Conduct.

### 15.	ENTIRE AGREEMENT

These Terms of Use constitute the entire agreement and supersedes any and all other understandings and agreements between you and The Social with respect to the subject matter hereof and no representation, statement or promise not contained herein shall be binding on you or The Social. Although these Terms of Use may be updated or modified by The Social from time to time as set forth therein, The Social will not be bound by any other amendments or modifications to these Terms of Use, or any terms and/or conditions you include with any purchase order, unless expressly accepted in writing by The Social in a written amendment expressly referencing these Terms of Use. These Terms of Use may not be supplemented or modified by any course of dealing or trade usage.

### 16.	WAIVER

No failure or delay by us in exercising any right under these Terms of Use shall operate as a waiver of such right or extend to or affect any other or subsequent events or impair any rights or remedies in respect of or in any way modify or diminish our right under these Terms of Use.

### 17.	GOVERNING LAW

These Terms of Use and your use of the Platform shall be governed by and construed in accordance with the laws of the Malaysia.

### 18.	CONTACT US

If you have any questions about these Terms of Use or have any further queries, you may contact us at the contact details below:

A-0-9 Plaza Damas 3
Jalan Sri Hartamas 1
Sri Hartamas
50480 Kuala Lumpur

Email: peishyen@thesocial.com.my
Contact No.: 03 6206 1973 

These Terms of Use were last updated on 1 April 2017.`}
          </ReactMarkdown> */}
          </CardBody>
        </Card>
      </div>
    </Modal>
  );
}

export default Terms;
