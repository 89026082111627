import axios from 'axios';
import { TOKEN_NAME, USER_LOGIN } from '../_constants';
import { readAndParseStorage } from '../_helper';

const axiosApiInstance = axios.create();

axiosApiInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem(TOKEN_NAME);

    const userLogin = readAndParseStorage(USER_LOGIN);
    config.headers = {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json',
      'X-Venue-Id': config.dataHeaders ? config.dataHeaders.venueID ?? '' : '',
      'X-Brand-Id': config.dataHeaders ? config.dataHeaders.brandID : '',
      'X-Customer-Id': userLogin ? userLogin.id : '',
      'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
      'X-Address-Id': config.dataHeaders
        ? config.dataHeaders.addressID
          ? config.dataHeaders.addressID
          : ''
        : '',
    };

    // if (config?.dataHeaders?.venueID) {
    //   config.headers['X-Venue-Id'] = config.dataHeaders.venueID
    // }
    return config;
  },
  (error) => {
    Promise.reject(error);
  },
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  async function (error) {
    const originalRequest = error.config;
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      !originalRequest._retry
    ) {
      originalRequest._retry = true;
      localStorage.clear();
      alert('Session is expired,Please login again.', 5000);
      setTimeout(() => {
        window.location.reload();
      }, 5000);
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  },
);

export default axiosApiInstance;
