import React, { useEffect, useState, useCallback } from 'react';
import { Row, Col, Container, Button, Badge, Input } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import axios from '../../api/CustomAxios';
import { GET_MAIN_MEAL_BY_ID, GET_ORDERS } from '../../api/APIs';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { setOrderUserItems } from '../../_actions/global.action';
import { CART, USER_LOGIN, ORDER_USER } from '_constants';
//import Slider from "react-slick";
import { message } from 'antd';
import { CheckCircleOutlined } from '@ant-design/icons';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import { readAndParseStorage, showError } from '../../_helper/index';

// const settings = {
//   className: "center",
//   dots: false,
//   arrows: false,
//   infinite: false,
//   variableWidth: true,
//   swipeToSlide: true,
//   adaptiveHeight: true
// };

const FoodDetail = ({ foodId, orderItemId, onClose = null }) => {
  const history = useHistory();
  const [meal, setMeal] = useState(null);
  const [instruction, setInstruction] = useState('');
  const [dataProduct, setDataProduct] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [allowAddToCart, setAllowAddToCart] = useState(false);
  const [quantity, setQuantity] = useState(1);
  //const [imageActive, setImageActive] = useState(0);

  const dispatch = useDispatch();
  const selectedRestaurant = useSelector(
    (state) => state.global.selectedRestaurant,
  );
  const selectedVenue = useSelector((state) => state.global.selectedVenue);
  const selectedAddress = useSelector((state) => state.global.selectedAddress);
  const selectedTime = useSelector((state) => state.global.selectedTime);

  const fetchFoodInfo = useCallback(() => {
    let cartObj = readAndParseStorage(CART);
    let orderUserObj = readAndParseStorage(ORDER_USER);
    if (orderUserObj) {
      if (selectedRestaurant && selectedVenue) {
        axios
          .get(GET_MAIN_MEAL_BY_ID + `/${foodId}`, {
            dataHeaders: {
              venueID: selectedVenue.id,
              brandID: selectedRestaurant.id,
            },
          })
          .then((res) => {
            if (res.data) {
              let dataMeal = res.data;
              dataMeal.gallery_images.unshift({
                id: 0,
                url: dataMeal.featured_image_url,
              });
              setMeal(dataMeal);
              if (dataMeal.status === 'out_of_stock') return;
              if (dataMeal.product_type === 'simple') {
                setTotalPrice(dataMeal.price);
                setAllowAddToCart(true);
              }
              let dataFind = orderUserObj.order_items.find(
                (option) => option.id === parseInt(orderItemId),
              );
              console.log(dataFind);
              if (dataFind && !onClose) {
                setTotalPrice(dataFind.price);
                setQuantity(dataFind.quantity);
                for (
                  let index = 0;
                  index < dataMeal.option_groups.length;
                  index++
                ) {
                  let lengthChecked = 0;
                  let priceChecked = 0;
                  for (let i = 0; i < dataFind.order_item_options.length; i++) {
                    if (
                      dataFind.order_item_options[i].option_group_id ===
                      dataMeal.option_groups[index].id
                    ) {
                      for (
                        let j = 0;
                        j < dataMeal.option_groups[index].options.length;
                        j++
                      ) {
                        if (
                          dataFind.order_item_options[i].option_id ===
                          dataMeal.option_groups[index].options[j].id
                        ) {
                          dataMeal.option_groups[index].options[
                            j
                          ].checked = true;
                          lengthChecked++;
                          priceChecked =
                            priceChecked +
                            parseInt(
                              dataMeal.option_groups[index].options[j].price,
                            );
                        }
                      }
                      dataMeal.option_groups[index].checkedLength =
                        lengthChecked;
                      dataMeal.option_groups[index].priceChecked = priceChecked;
                    }
                  }
                }
                let additional_instructions = dataFind.additional_instructions
                  ? dataFind.additional_instructions
                  : '';
                setInstruction(additional_instructions);
                setDataProduct(dataMeal.option_groups);

                setAllowAddToCart(true);
              } else {
                setDataProduct(dataMeal.option_groups);
              }
            } else {
              setMeal(null);
              setDataProduct([]);
            }
          });
      }
    } else {
      let itemLocal = null;
      if (cartObj) {
        if (cartObj.items && cartObj.items.length > 0) {
          itemLocal = _.find(cartObj.items, (item) => {
            return item.id === parseInt(foodId);
          });
        }
      }
      if (selectedRestaurant && selectedVenue) {
        axios
          .get(GET_MAIN_MEAL_BY_ID + `/${foodId}`, {
            dataHeaders: {
              venueID: selectedVenue.id,
              brandID: selectedRestaurant.id,
            },
          })
          .then((res) => {
            if (res.data) {
              let dataMeal = res.data;
              dataMeal.gallery_images.unshift({
                id: 0,
                url: dataMeal.featured_image_url,
              });
              setMeal(dataMeal);
              setDataProduct(dataMeal.option_groups);
              if (dataMeal.status === 'out_of_stock') return;
              if (dataMeal.product_type === 'simple') {
                setTotalPrice(dataMeal.price);
                setAllowAddToCart(true);
              }
              if (!itemLocal) return;
              if (itemLocal.id === dataMeal.id) {
                setTotalPrice(itemLocal.totalPrice);
                setQuantity(itemLocal.quantity);
                setDataProduct(itemLocal.option_groups);
                setAllowAddToCart(true);
              }
            } else {
              setMeal(null);
              setDataProduct([]);
            }
          });
      }
    }
  }, [foodId, selectedRestaurant, selectedVenue, onClose, orderItemId]);

  useEffect(() => {
    fetchFoodInfo();
  }, [fetchFoodInfo]);

  const selectRadio = (idex, id) => {
    let data = [...dataProduct];
    data[idex].checkedLength = 1;
    for (let index = 0; index < data[idex].options.length; index++) {
      if (data[idex].options[index].id === id) {
        data[idex].options[index].checked = true;
        data[idex].priceChecked = Number(data[idex].options[index].price);
      } else {
        data[idex].options[index].checked = false;
      }
    }

    let totalChecked = 0;
    for (let index = 0; index < data.length; index++) {
      let priceChecked =
        data[index].priceChecked === undefined ? 0 : data[index].priceChecked;
      totalChecked = totalChecked + priceChecked;
    }

    const idx = data.findIndex((option) => {
      let check;
      if (option.min_choice > 0) {
        if (option.checkedLength) {
          if (option.checkedLength < option.min_choice) {
            check = true;
          }
        } else if (option.min_choice === option.max_choice) {
          check = true;
        }
      }
      return check;
    });
    if (idx < 0) {
      setAllowAddToCart(true);
    }
    setTotalPrice(totalChecked);
    setDataProduct(data);
  };

  const selectCheckBox = (idex, id) => {
    let data = [...dataProduct];
    let checkedLength =
      data[idex].checkedLength === undefined ? 0 : data[idex].checkedLength;
    for (let index = 0; index < data[idex].options.length; index++) {
      if (data[idex].options[index].id === id) {
        if (data[idex].options[index].checked) {
          data[idex].options[index].checked = false;
          data[idex].checkedLength = checkedLength - 1;
          let priceChecked = data[idex].priceChecked;
          data[idex].priceChecked =
            priceChecked - Number(data[idex].options[index].price);
        } else {
          data[idex].options[index].checked = true;
          data[idex].checkedLength = checkedLength + 1;
          let priceChecked =
            data[idex].priceChecked === undefined ? 0 : data[idex].priceChecked;
          data[idex].priceChecked =
            priceChecked + Number(data[idex].options[index].price);
        }
      }
    }

    let totalChecked = 0;
    for (let index = 0; index < data.length; index++) {
      let priceChecked =
        data[index].priceChecked === undefined ? 0 : data[index].priceChecked;
      totalChecked = totalChecked + priceChecked;
    }

    const idx = data.findIndex((option) => {
      let check;
      if (option.min_choice > 0) {
        if (option.checkedLength) {
          if (option.checkedLength < option.min_choice) {
            check = true;
          }
        } else if (option.min_choice === option.max_choice) {
          check = true;
        }
      }
      return check;
    });
    if (totalChecked > 0 && idx < 0) {
      setAllowAddToCart(true);
    } else {
      setAllowAddToCart(false);
    }
    setTotalPrice(totalChecked);
    setDataProduct(data);
  };

  const minusQuantity = () => {
    if (quantity > 1) {
      const nextQuantity = quantity - 1;
      setQuantity(nextQuantity);
    }
  };

  const addQuantity = () => {
    const nextQuantity = quantity + 1;
    setQuantity(nextQuantity);
  };

  const addToCart = () => {
    if (allowAddToCart) {
      const idx = dataProduct.findIndex((option) => {
        let check;
        if (option.min_choice > 0) {
          if (option.checkedLength) {
            if (option.checkedLength < option.min_choice) {
              check = true;
            }
          } else if (option.min_choice === option.max_choice) {
            check = true;
          }
        }
        return check;
      });
      if (idx >= 0 && meal.product_type !== 'simple') {
        message.warning(
          `You have not chosen enough - ${dataProduct[idx].name}`,
        );
      } else {
        //Add to cart
        const userLogin = readAndParseStorage(USER_LOGIN);
        const orderUserStorage = readAndParseStorage(ORDER_USER);
        const activeTab = localStorage.getItem('ACTIVE_TAB');

        let order_items = orderUserStorage ? orderUserStorage.order_items : [];
        let order_item_options = [];
        for (let index = 0; index < dataProduct.length; index++) {
          if (
            dataProduct[index].checkedLength &&
            dataProduct[index].checkedLength > 0
          ) {
            for (let i = 0; i < dataProduct[index].options.length; i++) {
              if (dataProduct[index].options[i].checked) {
                order_item_options.push({
                  option_id: dataProduct[index].options[i].id,
                  option_group_id: dataProduct[index].id,
                });
              }
            }
          }
        }
        let products = {
          product_id: meal.id,
          additional_instructions: instruction,
          quantity: quantity,
          order_item_options: order_item_options,
        };

        if (!onClose) {
          let idx = order_items.findIndex(
            (option) => option.id === parseInt(orderItemId),
          );

          if (idx >= 0) {
            products.id = order_items[idx].id;
            order_items[idx] = products;
          } else {
            order_items.push(products);
          }
        } else {
          order_items.push(products);
        }
        if (orderUserStorage) {
          fetch(GET_ORDERS + orderUserStorage.id, {
            method: 'put',
            headers: {
              'Content-Type': 'application/json',
              'X-Customer-Id': userLogin ? userLogin.id : '',
              'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
              'X-Venue-Id': selectedVenue.id,
              'X-Brand-Id': selectedRestaurant.id,
              'X-Address-Id': selectedAddress.id,
            },
            body: JSON.stringify({
              ...orderUserStorage,
              order_items: order_items,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((results) => {
              if (results.id) {
                localStorage.setItem(ORDER_USER, JSON.stringify(results));
                dispatch(setOrderUserItems(results.order_items));
                if (onClose) {
                  success();
                } else {
                  message.success('Update bag success');
                  closeDetail();
                }
              } else {
                if (results.error_message) {
                  message.error(results.error_message);
                } else {
                  if (onClose) {
                    message.error('Add to bag error');
                  } else {
                    message.error('Update bag error');
                  }
                }
              }
            })
            .catch((err) => {
              if (err) {
                if (onClose) {
                  showError(err);
                } else {
                  showError(err);
                }
              }
            });
        } else {
          fetch(GET_ORDERS, {
            method: 'post',
            headers: {
              'Content-Type': 'application/json',
              'X-Customer-Id': userLogin ? userLogin.id : '',
              'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
              'X-Venue-Id': selectedVenue.id,
              'X-Brand-Id': selectedRestaurant.id,
              'X-Address-Id': selectedAddress.id,
            },
            body: JSON.stringify({
              ...orderUserStorage,
              agent: 'store_front',
              time_slot: selectedTime.valueSelectTime.startT
                ? moment(selectedTime.valueDay.fullday)
                    .tz('Asia/Kuala_Lumpur')
                    .set(
                      'hour',
                      selectedTime.valueSelectTime.startT.split(':')[0],
                    )
                    .set(
                      'minute',
                      selectedTime.valueSelectTime.startT.split(':')[1],
                    )
                    .format()
                : null,
              order_type: activeTab,
              lat: selectedVenue.lat,
              lng: selectedVenue.lng,
              order_items: order_items,
            }),
          })
            .then((response) => {
              return response.json();
            })
            .then((results) => {
              if (results.id) {
                localStorage.setItem(ORDER_USER, JSON.stringify(results));
                dispatch(setOrderUserItems(results.order_items));

                success();
              } else {
                if (results.error_message) {
                  message.error(results.error_message);
                } else {
                  showError(results.error_message);
                }
              }
            })
            .catch((err) => {
              if (err) {
                showError(err);
              }
            });
        }
      }
    }
  };

  const success = () => {
    message.success('Add to bag success');
    closeDetail();
  };

  const closeDetail = () => {
    if (onClose) {
      onClose();
    } else {
      history.push('/review-order');
    }
  };

  const changeInstruction = (e) => {
    const instructionItem = e.target.value;
    setInstruction(instructionItem);
  };

  if (meal === null) {
    return '';
  }

  return (
    <>
      <Container className="food_detail">
        <Row>
          <Col lg="6" className="p-0">
            <div className="_food-detail-img">
              <img
                alt="Food"
                src={
                  meal.featured_image_url !== null
                    ? meal.featured_image_url
                    : require('assets/img/default-no-img.jpeg')
                }
              />
            </div>
            {/* <div className="_food-detail-img">
              <img alt="Food" src={meal.gallery_images.length > 0 ? meal.gallery_images[imageActive].url : meal.featured_image_url !== null ? meal.featured_image_url : require("assets/img/default-no-img.jpeg")} />
            </div>
            <div className="list">
              <div className="_sliders">
                <div className="_slider">
                  <Slider {...settings}>
                    {
                      meal.gallery_images.map((val,index) => {
                        return(
                          <div className={`_food-detail-img-next image_thumb ${imageActive === index && 'active'}`} key={index}>
                            <img alt="Food"  src={val.url} width={128} onClick={() => setImageActive(index)}/>
                          </div>
                        )
                      })
                    }
                  </Slider>
                </div>
              </div>
            </div> */}
            <Button
              className="close_detail_btn"
              color="default"
              type="button"
              onClick={() => closeDetail()}
            >
              <i className="fas fa-times" />
            </Button>
          </Col>
          <Col lg="6" className="food_detail__content">
            <div className="_header">
              <div className="tags mb-2">
                {meal.tags.map((tag, index) => {
                  return (
                    <Badge
                      key={index}
                      className="text-uppercase"
                      color="info"
                      pill
                    >
                      {tag.name}
                    </Badge>
                  );
                })}
              </div>
              <h1>{meal.title}</h1>
              <p>{meal.description}</p>
            </div>
            <div className="_body">
              <PerfectScrollbar>
                <div style={{ maxHeight: 285 }}>
                  {meal.product_type !== 'simple' &&
                    dataProduct.map((item, index) => {
                      return (
                        <div className="_section" key={index}>
                          <div className="title">
                            <h2>{item.name}</h2>
                            {item.min_choice > 0 && (
                              <p>
                                {item.checkedLength === item.max_choice && (
                                  <CheckCircleOutlined
                                    style={{
                                      fontSize: '16px',
                                      color: '#E1AC6F',
                                    }}
                                  />
                                )}{' '}
                                {item.checkedLength === undefined
                                  ? 0
                                  : item.checkedLength}{' '}
                                / {item.max_choice} SELECTED{' '}
                              </p>
                            )}
                            {item.min_choice === 0 &&
                            item.max_choice < item.options.length ? (
                              <p>ONLY SELECT {item.max_choice} ITEMS</p>
                            ) : (
                              ''
                            )}
                          </div>
                          <Container>
                            <Row className="_options">
                              {item.options.map((opt, i) => {
                                let disBoxChecked;
                                if (item.checkedLength > 0) {
                                  disBoxChecked =
                                    item.checkedLength === item.max_choice;
                                  if (opt.checked) {
                                    disBoxChecked = false;
                                  }
                                }
                                if (
                                  item.max_choice === item.min_choice &&
                                  item.max_choice === 1
                                ) {
                                  return (
                                    <Col
                                      lg="6"
                                      xs="12"
                                      className="p-0"
                                      key={opt.id}
                                    >
                                      <div className="custom_control_item_type">
                                        <div className="custom-control custom-radio ">
                                          <input
                                            className="custom-control-input"
                                            id={`radio-carbohydrates-${opt.id}`}
                                            name={opt.id}
                                            type="radio"
                                            disabled={
                                              meal.status === 'out_of_stock'
                                                ? true
                                                : opt.status !== 'active'
                                            }
                                            value={opt.name + opt.id}
                                            checked={opt.checked}
                                            onChange={() =>
                                              selectRadio(index, opt.id)
                                            }
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={`radio-carbohydrates-${opt.id}`}
                                          >
                                            <span>{opt.name}</span>
                                            <span
                                              style={{ whiteSpace: 'nowrap' }}
                                              className="pr-0 pl-1"
                                            >
                                              RM {opt.price}
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </Col>
                                  );
                                } else {
                                  return (
                                    <Col
                                      lg="6"
                                      xs="12"
                                      className="p-0"
                                      key={opt.id}
                                    >
                                      <div className="custom_control_item_type">
                                        <div className="custom-control custom-checkbox">
                                          <input
                                            className="custom-control-input"
                                            id={`checkbox-extras-${opt.id}`}
                                            index={opt.id}
                                            type="checkbox"
                                            value={opt.name}
                                            disabled={
                                              disBoxChecked
                                                ? true
                                                : false &&
                                                  meal.status === 'out_of_stock'
                                                ? true
                                                : opt.status !== 'active'
                                            }
                                            onChange={() =>
                                              selectCheckBox(index, opt.id)
                                            }
                                            checked={opt.checked}
                                            price={opt.price}
                                          />
                                          <label
                                            className="custom-control-label"
                                            htmlFor={`checkbox-extras-${opt.id}`}
                                          >
                                            <span>{opt.name}</span>
                                            <span
                                              style={{ whiteSpace: 'nowrap' }}
                                              className="pr-0 pl-1"
                                            >
                                              RM {opt.price}
                                            </span>
                                          </label>
                                        </div>
                                      </div>
                                    </Col>
                                  );
                                }
                              })}
                            </Row>
                          </Container>
                        </div>
                      );
                    })}
                </div>
              </PerfectScrollbar>
              <div className="_section mt-3">
                <div className="title">
                  <h2>ADDITIONAL INSTRUCTIONS</h2>
                </div>
                <Container>
                  <Row className="_options">
                    <Col md="12" className="p-0">
                      <Input
                        placeholder="Write your instructions…"
                        type="text"
                        value={instruction}
                        onChange={changeInstruction}
                      />
                    </Col>
                  </Row>
                </Container>
              </div>
              <div className="_section mt-3">
                <Container>
                  <Row className="_options">
                    <Col md="12" className="p-0">
                      <div className="add_remove">
                        <Button
                          color="default"
                          type="button"
                          onClick={minusQuantity}
                        >
                          <i className="fas fa-minus" />{' '}
                        </Button>
                        <span>x {quantity}</span>
                        <Button
                          color="default"
                          type="button"
                          onClick={addQuantity}
                        >
                          <i className="fas fa-plus" />{' '}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </div>
            </div>
            <div className="_footer">
              <Button
                block
                color="primary"
                disabled={!allowAddToCart}
                type="button"
                onClick={addToCart}
              >
                <i className="ni ni-bag-17" />{' '}
                {meal.status === 'out_of_stock'
                  ? 'OUT OF STOCK'
                  : `${!onClose ? 'UPDATE' : 'ADD TO BAG'} — RM ${
                      totalPrice * quantity
                    }`}
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default FoodDetail;
