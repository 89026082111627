import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Button,
  ModalBody,
  Modal,
} from 'reactstrap';
import FoodDetail from './FoodDetail';
import { useDispatch, useSelector } from 'react-redux';
import { setSelectedMainMeal } from '../../_actions/mainMeal.action';
import { GET_ORDERS } from '../../api/APIs';

import {
  setShowPopupAddress,
  setShowPopupTime,
  setOrderUserItems,
} from '../../_actions/global.action';
import { setCustomOverlay, setSignInModal } from '../../_actions/effect.action';
import {
  SELECTED_ADDRESS,
  SELECTED_TIME,
  ORDER_USER,
  USER_LOGIN,
  SIGN_IN_TYPE,
} from '_constants';
import { message } from 'antd';
import moment from 'moment';
import { readAndParseStorage } from '../../_helper';

const Food = (props) => {
  const [modalFoodDetail, setModalFoodDetail] = useState(false);
  const [selectedFoodId, setSelectedFoodId] = useState(null);
  const [products, setProducts] = useState(null);
  const userLogin = useSelector((state) => state.authentication.userLogin);

  const history = useHistory();
  const dispatch = useDispatch();

  const selectedRestaurant = useSelector(
    (state) => state.global.selectedRestaurant,
  );
  const selectedVenue = useSelector((state) => state.global.selectedVenue);
  const selectedAddress = useSelector((state) => state.global.selectedAddress);
  const selectedTime = useSelector((state) => state.global.selectedTime);

  const toggleFoodDetailModal = (foodId) => {
    let selectedAdd = readAndParseStorage(SELECTED_ADDRESS);
    let selectedTimeStorage = readAndParseStorage(SELECTED_TIME);
    if (readAndParseStorage(USER_LOGIN)) {
      if (selectedAdd) {
        if (
          selectedTimeStorage &&
          selectedTimeStorage?.valueDay?.dataSplit.length > 0
        ) {
          setSelectedFoodId(foodId);
          setModalFoodDetail(!modalFoodDetail);
        } else {
          dispatch(setCustomOverlay(true));
          dispatch(setShowPopupTime(true));
        }
      } else {
        dispatch(setCustomOverlay(true));
        dispatch(setShowPopupAddress(true));
      }
    } else {
      dispatch(
        setSignInModal({
          status: true,
          type: SIGN_IN_TYPE.SIGN_IN,
        }),
      );
    }
  };

  useEffect(() => {
    const limit = props.limit;
    if (limit) {
      const crrProduct = props.category.products.slice(0, limit);
      setProducts(crrProduct);
    } else {
      setProducts(props.category.products);
    }
  }, [props]);

  const viewAll = () => {
    const categoryId = props.category.id;
    history.push(`/${selectedRestaurant.id}/category-meal/${categoryId}`);
    dispatch(setSelectedMainMeal(props.category));
  };

  const onClickAddToBag = (val) => {
    if (!userLogin) {
      dispatch(
        setSignInModal({
          status: true,
          type: SIGN_IN_TYPE.SIGN_IN,
        }),
      );
      return;
    }
    let selectedAdd = readAndParseStorage(SELECTED_ADDRESS);
    let selectedTimeStorage = readAndParseStorage(SELECTED_TIME);
    if (selectedAdd) {
      if (selectedTimeStorage) {
        if (val.product_type === 'simple') {
          //Add to cart
          // const userLogin = readAndParseStorage(USER_LOGIN);
          const orderUserStorage = readAndParseStorage(ORDER_USER);
          const activeTab = localStorage.getItem('ACTIVE_TAB');

          let order_items = orderUserStorage
            ? orderUserStorage.order_items
            : [];

          let products = {
            product_id: val.id,
            quantity: 1,
            order_item_options: [],
          };
          order_items.push(products);

          if (orderUserStorage) {
            fetch(GET_ORDERS + orderUserStorage.id, {
              method: 'put',
              headers: {
                'Content-Type': 'application/json',
                'X-Customer-Id': userLogin ? userLogin.id : '',
                'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
                'X-Venue-Id': selectedVenue.id,
                'X-Brand-Id': selectedRestaurant.id,
                'X-Address-Id': selectedAddress.id,
              },
              body: JSON.stringify({
                ...orderUserStorage,
                order_items: order_items,
              }),
            })
              .then((response) => {
                return response.json();
              })
              .then((results) => {
                if (!results.error_message) {
                  localStorage.setItem(ORDER_USER, JSON.stringify(results));
                  dispatch(setOrderUserItems(results.order_items));

                  message.success('Add to Bag Success');
                } else {
                  message.error(results.error_message);
                }
              })
              .catch((err) => {
                if (err) {
                  message.error('Add to bag error');
                }
              });
          } else {
            fetch(GET_ORDERS, {
              method: 'post',
              headers: {
                'Content-Type': 'application/json',
                'X-Customer-Id': userLogin ? userLogin.id : '',
                'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
                'X-Venue-Id': selectedVenue.id,
                'X-Brand-Id': selectedRestaurant.id,
                'X-Address-Id': selectedAddress.id,
              },
              body: JSON.stringify({
                ...orderUserStorage,
                agent: 'store_front',
                time_slot: selectedTime.valueSelectTime.startT
                  ? moment(
                      new Date(selectedTime.valueDay.fullday).setHours(
                        selectedTime.valueSelectTime.startT.split(':')[0],
                        selectedTime.valueSelectTime.startT.split(':')[1],
                      ),
                    )
                      .tz('Asia/Kuala_Lumpur')
                      .format()
                  : null,
                order_type: activeTab,
                lat: selectedVenue.lat,
                lng: selectedVenue.lng,
                order_items: order_items,
              }),
            })
              .then((response) => {
                return response.json();
              })
              .then((results) => {
                if (!results.error_message) {
                  localStorage.setItem(ORDER_USER, JSON.stringify(results));
                  dispatch(setOrderUserItems(results.order_items));
                  message.success('Add to Bag Success');
                } else {
                  message.error(results.error_message);
                }
              })
              .catch((err) => {
                if (err) {
                  message.error('Add to bag error');
                }
              });
          }
        } else {
          toggleFoodDetailModal(val.id);
        }
      } else {
        dispatch(setCustomOverlay(true));
        dispatch(setShowPopupTime(true));
      }
    } else {
      dispatch(setCustomOverlay(true));
      dispatch(setShowPopupAddress(true));
    }
  };

  return (
    <div className="category_meal__item" id={`category${props.category.id}`}>
      {props && props.limit && (
        <>
          <h3 className="category_meal__item_name">{props.category.title}</h3>
          <p className="category_meal__item_description">
            {props.category.description}
          </p>
        </>
      )}
      <Container fluid className="foods">
        <Row>
          {products &&
            products.map((food) => {
              return (
                <Col
                  xs={6}
                  md={props.md ? props.md : 6}
                  className="food"
                  key={food.id}
                >
                  <Card onClick={() => toggleFoodDetailModal(food.id)}>
                    <div
                      className="menu-item__image"
                      style={{
                        backgroundImage: `url("${
                          food.featured_image_512x512_url !== null
                            ? food.featured_image_512x512_url
                            : require('assets/img/default-no-img.jpeg')
                        }")`,
                      }}
                    />
                    <CardBody>
                      <div className="tags">
                        {food.tags.map((tag, index) => {
                          return (
                            <Badge
                              key={index}
                              className="text-uppercase"
                              color="info"
                              pill
                            >
                              {tag.name}
                            </Badge>
                          );
                        })}
                      </div>
                      <div className="food_name">{food.title}</div>
                      <div className="d-flex align-items-center justify-content-between">
                        <div className="food_price">
                          <span className="__currency">RM</span>
                          <span className="__price">{food.price}</span>
                        </div>
                      </div>
                    </CardBody>
                  </Card>
                  <Button
                    color={
                      food.status === 'out_of_stock' ? 'secondary' : 'primary'
                    }
                    className="btn-revert button_add_to_bag"
                    size="sm"
                    type="button"
                    onClick={() => onClickAddToBag(food)}
                    disabled={food.status === 'out_of_stock'}
                  >
                    <i className="ni ni-bag-17" />
                  </Button>
                </Col>
              );
            })}
        </Row>
      </Container>
      {modalFoodDetail && selectedFoodId && (
        <Modal
          size="lg"
          isOpen={modalFoodDetail}
          toggle={toggleFoodDetailModal}
          className="food_review"
          backdropClassName="social_dialog__bg"
          centered={true}
          onClosed={() => setSelectedFoodId(null)}
        >
          <ModalBody>
            <FoodDetail
              foodId={selectedFoodId}
              onClose={toggleFoodDetailModal}
            />
          </ModalBody>
        </Modal>
      )}
      {props && props.limit && (
        <Col className="btn_view">
          <Button
            className="col-12"
            color="primary"
            type="button"
            onClick={viewAll}
          >
            VIEW ALL
          </Button>
        </Col>
      )}
    </div>
  );
};
export default Food;
