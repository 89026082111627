import React, { useState, useEffect, useCallback } from 'react';
import {
  setSignInModal,
  setCustomOverlay,
  setPickRestaurantModal,
  setTooltipOpen,
  setAddAdress,
  setProfileModal,
  setCartModal,
} from '../../_actions/effect.action';
import classNames from 'classnames';
import {
  setSelectedRestaurant,
  setRestaurantsList,
  setSelectedVenue,
  setShowPopupAddress,
  setShowPopupTime,
  setSelectedAddress,
  setSelectedTime,
  setOrderUserItems,
  setGlobalSetting,
  setShowDetail,
  setSelectedOrder,
  setStripeKey,
  setIsAppReady,
  setIsMobile,
} from '../../_actions/global.action';
import { setCartItems } from '../../_actions/cart.action';
import { setUserLogin } from '_actions/authentication.action';
import {
  GET_RESTAURANTS,
  GET_VENUES,
  GET_ORDERS,
  GET_ADDRESSES,
  GET_GLOBAL_SETTING,
  GET_BRAND_USER,
  GET_STRIPE_KEY,
} from '../../api/APIs';
import axios from '../../api/CustomAxios';
// reactstrap components
import {
  Button,
  DropdownMenu,
  DropdownToggle,
  Dropdown,
  NavbarBrand,
  Navbar,
  NavItem,
  Nav,
  Container,
  Tooltip,
} from 'reactstrap';
import DeliveryLocation from 'components/dialog/DeliveryLocation';
import DeliverySaveLocation from '../dialog/DeliverySaveLocation';
import DeliveryTime from 'components/dialog/DeliveryTime';
import { useDispatch, useSelector } from 'react-redux';
import CartDialog from 'components/dialog/CartDialog';
import ProfileDialog from 'components/dialog/ProfileDialog';
import {Modal, message, notification, Spin, Menu, Dropdown as AntDropdown } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import {
  SELECTED_RESTAURANT,
  USER_LOGIN,
  SELECTED_ADDRESS,
  VENUES_DATA,
  SELECTED_TIME,
  ORDER_USER,
  CART,
  SIGN_IN_TYPE,
} from '_constants';
import _ from 'lodash';
import OrderType from '../common/order_type';
import OpNotification from '../common/notification';
import CheckTime from '../common/checkTime';
import { w3cwebsocket as W3CWebSocket } from 'websocket';
import { showError, readAndParseStorage } from '../../_helper/index';

var client;
var moment = require('moment-timezone');

function formatDate() {
  var d = new Date(),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [year, month, day].join('-');
}

const roundTime = (time, minutesToRound, bonusTime) => {
  var s = new Date();
  let [hours, minutes] = time.split(':');
  s.setHours(hours);
  s.setMinutes(parseInt(minutes) + bonusTime);

  hours = s.getHours();
  minutes = s.getMinutes();

  time = hours * 60 + minutes;

  let rounded = Math.round(time / minutesToRound) * minutesToRound;
  let rHr = '' + Math.floor(rounded / 60);

  let rMin = '' + (rounded % 60);

  return rHr.padStart(2, '0') + ':' + rMin.padStart(2, '0');
};

var optionsDayDefault = [
  {
    data: [],
    dataSplit: [],
    value: 'sunday',
    label: 'Sunday',
    fullday: '',
  },
  {
    data: [],
    dataSplit: [],
    value: 'monday',
    label: 'Monday',
    fullday: '',
  },
  {
    data: [],
    dataSplit: [],
    value: 'tuesday',
    label: 'Tuesday',
    fullday: '',
  },
  {
    data: [],
    dataSplit: [],
    value: 'wednesday',
    label: 'Wednesday',
    fullday: '',
  },
  {
    data: [],
    dataSplit: [],
    value: 'thursday',
    label: 'Thursday',
    fullday: '',
  },
  {
    data: [],
    dataSplit: [],
    value: 'friday',
    label: 'Friday',
    fullday: '',
  },
  {
    data: [],
    dataSplit: [],
    value: 'saturday',
    label: 'Saturday',
    fullday: '',
  },
];

const MainNavbar = () => {
  const [cartDropdownOpen, setCartDropdownOpen] = useState(false);
  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const [checkSaveLocation, setCheckSaveLocation] = useState(false);
  const [logoUser, setLogoUser] = useState(null);
  const [lengthOrder, setLengthOrder] = useState(0);
  const [optionsDay] = useState(optionsDayDefault);

  const selectedRestaurant = useSelector(
    (state) => state.global.selectedRestaurant,
  );

  const showPopupAddress = useSelector(
    (state) => state.global.showPopupAddress,
  );
  const selectedAddress = useSelector((state) => state.global.selectedAddress);
  const isAppReady = useSelector((state) => state.global.isAppReady);

  const globalSetting = useSelector((state) => state.global.globalSetting);

  const orderUserItems = useSelector((state) => state.global.orderUserItems);

  const customOverlay = useSelector((state) => state.effect.customOverlay);

  const showPopupTime = useSelector((state) => state.global.showPopupTime);

  const selectedTime = useSelector((state) => state.global.selectedTime);

  const selectedOrder = useSelector((state) => state.global.selectedOrder);

  //const selectedMenu = useSelector((state) => state.global.selectedMenu);

  // const isLogged = useSelector((state) => state.authentication.isLogged);
  const userLogin = useSelector((state) => state.authentication.userLogin);
  const dispatch = useDispatch();

  const tooltipOpen = useSelector((state) => state.effect.tooltipOpen);
  const addAddress = useSelector((state) => state.effect.addAddress);

  useEffect(() => {
    onResize()
    window.addEventListener('resize', onResize)
    return () => {
      window.removeEventListener('resize', onResize)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onResize = () => {
    if (window.innerWidth > 768) {
      dispatch(setIsMobile(false))
    } else {
      dispatch(setIsMobile(true))
    }
  }

  const toggleDropdown = (type, checkSave) => {
    let dataAddress = readAndParseStorage(SELECTED_ADDRESS);
    switch (type) {
      case 'location':
        let equal = _.isEqual(dataAddress, selectedAddress);
        if (!equal) {
          dispatch(setSelectedAddress(dataAddress));
        }
        if (!checkSave) {
          setCheckSaveLocation(false);
        }
        dispatch(setCustomOverlay(!showPopupAddress));
        dispatch(setShowPopupAddress(!showPopupAddress));
        break;
      case 'time':
        if (dataAddress) {
          dispatch(setCustomOverlay(!showPopupTime));
          dispatch(setShowPopupTime(!showPopupTime));
        } else {
          dispatch(setCustomOverlay(!showPopupAddress));
          dispatch(setShowPopupAddress(!showPopupAddress));
        }
        break;
      case 'cart':
        dispatch(setCustomOverlay(!cartDropdownOpen));
        setCartDropdownOpen(!cartDropdownOpen);
        break;
      case 'profile':
        dispatch(setCustomOverlay(!profileDropdownOpen));
        setProfileDropdownOpen(!profileDropdownOpen);
        break;
      default:
        break;
    }
  };

  const selectWeek = useCallback(
    (day) => {
      day.start_time = roundTime(
        day.start_time,
        30,
        globalSetting && globalSetting.factor_time,
      );
      day.end_time = roundTime(day.end_time, 30, 0);
      let listTime = [];

      var start = moment.tz(
        `${formatDate()} ${day.start_time}`,
        'Asia/Kuala_Lumpur',
      );
      var end = moment.tz(
        `${formatDate()} ${day.end_time}`,
        'Asia/Kuala_Lumpur',
      );
      let idx = 0;
      while (start < end) {
        let startT = start.format();
        start = start.add(30, 'minutes');
        let endT = start.format();
        idx++;

        listTime.push({
          id: idx,
          startT: moment.tz(startT, 'Asia/Kuala_Lumpur').format('HH:mm'),
          endT: moment.tz(endT, 'Asia/Kuala_Lumpur').format('HH:mm'),
        });
      }

      switch (day.week_day) {
        case 'sunday':
          optionsDay[0].data.push(day);
          let resultSunday = optionsDay[0].dataSplit.concat(listTime);
          optionsDay[0].dataSplit = resultSunday;
          break;
        case 'monday':
          optionsDay[1].data.push(day);
          let resultMonday = optionsDay[1].dataSplit.concat(listTime);
          optionsDay[1].dataSplit = resultMonday;
          break;
        case 'tuesday':
          optionsDay[2].data.push(day);
          let resultTuesday = optionsDay[2].dataSplit.concat(listTime);
          optionsDay[2].dataSplit = resultTuesday;
          break;
        case 'wednesday':
          optionsDay[3].data.push(day);
          let resultWednesday = optionsDay[3].dataSplit.concat(listTime);
          optionsDay[3].dataSplit = resultWednesday;
          break;
        case 'thursday':
          optionsDay[4].data.push(day);
          let resultThursday = optionsDay[4].dataSplit.concat(listTime);
          optionsDay[4].dataSplit = resultThursday;
          break;
        case 'friday':
          optionsDay[5].data.push(day);
          let resultFriday = optionsDay[5].dataSplit.concat(listTime);
          optionsDay[5].dataSplit = resultFriday;
          break;
        case 'saturday':
          optionsDay[6].data.push(day);
          let resulSaturday = optionsDay[6].dataSplit.concat(listTime);
          optionsDay[6].dataSplit = resulSaturday;
          break;
        default:
          break;
      }
    },
    [optionsDay, globalSetting],
  );

  const onClickDisp = useCallback(
    (data) => {
      dispatch(setSelectedOrder(data));
      dispatch(setShowDetail(true));
    },
    [dispatch],
  );

  useEffect(() => {
    const userLogin = readAndParseStorage(USER_LOGIN);
    axios
      .get(GET_GLOBAL_SETTING)
      .then((res) => {
        dispatch(setGlobalSetting(res.data));
      })
      .catch((error) => {
        if (error) {
          dispatch(setGlobalSetting(null));
        }
      });
    axios
      .get(GET_STRIPE_KEY)
      .then((res) => {
        dispatch(setStripeKey(res.data.stripe_publishable_key));
      })
      .catch((error) => {
        if (error) {
          dispatch(setStripeKey(null));
        }
      });
    if (userLogin) {
      fetch(GET_BRAND_USER + `/${userLogin.id}`, {
        method: 'get',
        headers: {
          'Content-Type': 'application/json',
          'API-KEY': userLogin ? userLogin.api_key : '',
        },
      })
        .then((response) => {
          return response.json();
        })
        .then((results) => {
          if (results.body) {
            localStorage.setItem(USER_LOGIN, JSON.stringify(results.body));
            if (results.body.brand_image) {
              setLogoUser(results.body.brand_image.thumb_256x256_url);
            }
          }
        })
        .catch((err) => {
          showError(err);
        });
      axios
        .get(`${GET_ORDERS}active`)
        .then((res) => {
          let data = res.data;
          if (data) {
            let titleStatus = '';
            titleStatus = OrderType(data);
            OpNotification(data, titleStatus, onClickDisp);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  }, [dispatch, onClickDisp]);

  useEffect(() => {
    const userLogin = readAndParseStorage(USER_LOGIN);
    if (userLogin) {
      // Socket
      var msg = {
        command: 'subscribe',
        identifier: '{"channel": "OrderChannel"}',
      };
      if (client) {
        client.close();
      }
      client = new W3CWebSocket(
        `wss://${window.location.hostname}/api/cable/mobile?api_key=${userLogin.api_key}&customer_id=${userLogin.id}`,
      );

      client.onerror = function () {
        console.log('Connection Error');
      };
      client.onopen = () => {
        client.send(JSON.stringify(msg));
      };
      client.onmessage = (msg) => {
        let parseMess = JSON.parse(msg.data);
        console.log('get socket message: ', parseMess);
        if (parseMess.identifier && parseMess.message) {
          axios
            .get(`${GET_ORDERS}${parseMess.message.payload.id}`)
            .then((res) => {
              let data = res.data;
              if (data) {
                let time = CheckTime(data);
                data.timeDetail = time;
                if (selectedOrder?.id === parseMess.message.payload.id) {
                  dispatch(setSelectedOrder(data));
                }
                let key = `open_${data.id}`;
                if (data.status === 'completed') {
                  notification.close(key);
                } else {
                  let titleStatus = '';
                  titleStatus = OrderType(data);
                  OpNotification(data, titleStatus, onClickDisp);
                }
              } else {
                message.error('Get Order Error');
              }
            })
            .catch((error) => {
              showError(error);
            });
        }
      };
    }
  }, [dispatch, onClickDisp, selectedOrder]);

  useEffect(() => {
    if (!globalSetting) return;
    const dataSelectRes = readAndParseStorage(SELECTED_RESTAURANT);
    const venues_data = readAndParseStorage(VENUES_DATA);
    const userLogin = readAndParseStorage(USER_LOGIN);
    const selectedAdd = readAndParseStorage(SELECTED_ADDRESS);
    const selected_time = readAndParseStorage(SELECTED_TIME);
    let pathNameIdBrand = window.location.hash.split('/')[1];
    axios.get(GET_RESTAURANTS).then(async (res) => {
      const restaurants = res.data;
      dispatch(setRestaurantsList(restaurants));
      if (userLogin) {
        fetch(GET_ORDERS + `cart`, {
          method: 'get',
          headers: {
            'Content-Type': 'application/json',
            'X-Customer-Id': userLogin ? userLogin.id : '',
            'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
          },
        })
          .then((response) => {
            return response.json();
          })
          .then(async (results) => {
            if (results.id) {
              if (results.status === 'failed_checkout') {
                message.error('Failed Checkout');
              }
              localStorage.setItem(ORDER_USER, JSON.stringify(results));
              dispatch(setOrderUserItems(results.order_items));
              //restaurant
              localStorage.setItem('ACTIVE_TAB', results.order_type);
              let venuesApi;

              if (parseInt(pathNameIdBrand)) {
                let crrRestaurant = restaurants.find(
                  (option) => option.id === parseInt(pathNameIdBrand),
                );
                if (crrRestaurant) {
                  dispatch(setSelectedRestaurant(crrRestaurant));
                }
                venuesApi = await axios.get(
                  GET_VENUES + `?brand_id=${parseInt(pathNameIdBrand)}`,
                );

                if (
                  parseInt(pathNameIdBrand) !== results.restaurant_info.brand_id
                ) {
                  dispatch(setSelectedAddress(null));
                  localStorage.removeItem(SELECTED_ADDRESS);

                  dispatch(setSelectedTime(null));
                  localStorage.removeItem(SELECTED_TIME);

                  dispatch(setCartItems([]));
                  localStorage.removeItem(CART);

                  dispatch(setOrderUserItems([]));
                  localStorage.removeItem('ACTIVE_TAB');
                  localStorage.removeItem(ORDER_USER);
                  fetch(GET_ORDERS + 'cart', {
                    method: 'delete',
                    headers: {
                      'Content-Type': 'application/json',
                      'X-Customer-Id': userLogin ? userLogin.id : '',
                      'X-Customer-Api-Key': userLogin ? userLogin.api_key : '',
                    },
                  })
                    .then((response) => {
                      return response.json();
                    })
                    .then((results) => {})
                    .catch((err) => {
                      if (err) {
                        console.log(err);
                      }
                    });
                }
              } else {
                let crrRestaurant = restaurants.find(
                  (option) => option.id === results.restaurant_info.brand_id,
                );
                if (crrRestaurant) {
                  dispatch(setSelectedRestaurant(crrRestaurant));
                }
                venuesApi = await axios.get(
                  GET_VENUES + `?brand_id=${results.restaurant_info.brand_id}`,
                );
              }
              //address
              let crrVenues = venuesApi.data.find(
                (option) => option.id === results.venue_id,
              );
              if (results.order_type === 'delivery') {
                const addressApi = await axios.get(GET_ADDRESSES);
                let crrAddress = addressApi.data.find(
                  (option) => option.id === results.address_id,
                );
                if (crrAddress) {
                  dispatch(setSelectedAddress(crrAddress));
                  dispatch(setSelectedVenue(crrVenues));
                  localStorage.setItem(
                    SELECTED_ADDRESS,
                    JSON.stringify(crrAddress),
                  );
                  localStorage.setItem(VENUES_DATA, JSON.stringify(crrVenues));
                }
              } else {
                if (crrVenues) {
                  dispatch(setSelectedAddress(crrVenues));
                  dispatch(setSelectedVenue(crrVenues));
                  localStorage.setItem(
                    SELECTED_ADDRESS,
                    JSON.stringify(crrVenues),
                  );
                  localStorage.setItem(VENUES_DATA, JSON.stringify(crrVenues));
                }
              }

              // TIME
              for (
                let index = 0;
                index < crrVenues?.operation_times?.length ?? 0;
                index++
              ) {
                selectWeek({ ...crrVenues.operation_times[index] });
              }
              // date now
              let nowTime = moment().tz('Asia/Kuala_Lumpur').format('HH:mm');

              var s = new Date(moment().tz('Asia/Kuala_Lumpur').format());
              let [hours, minutes] = nowTime.split(':');
              s.setHours(hours);
              s.setMinutes(
                parseInt(minutes) +
                  (globalSetting ? globalSetting.min_advance_ordertime : 0),
              );
              let timeUpdate = moment(s).format('HH:mm');

              let dataOptionsSlice1 = optionsDay.slice(
                0,
                moment().tz('Asia/Kuala_Lumpur').day(),
              );
              let dataOptionsSlice2 = optionsDay.slice(
                moment().tz('Asia/Kuala_Lumpur').day(),
                7,
              );
              let dataMerge = dataOptionsSlice2.concat(dataOptionsSlice1);
              let dataFilter = dataMerge[0].dataSplit.filter(
                (option) => option.startT > timeUpdate,
              );

              dataMerge[0].dataSplit = dataFilter;
              dataMerge[0].label = 'Today';
              dataMerge[0].value = 'today';

              for (let index = 0; index < dataMerge.length; index++) {
                dataMerge[index]['fullday'] = moment(new Date())
                  .add('day', index)
                  .tz('Asia/Kuala_Lumpur')
                  .format('YYYY-MM-DD');
              }

              let valueDay;
              let valueSelectTime;
              if (results.time_slot) {
                valueDay = dataMerge.find(
                  (option) =>
                    option.fullday ===
                    moment(results.time_slot)
                      .tz('Asia/Kuala_Lumpur')
                      .format('YYYY-MM-DD'),
                );
                valueSelectTime = valueDay.dataSplit.find(
                  (option) =>
                    option.startT ===
                    moment(results.time_slot)
                      .tz('Asia/Kuala_Lumpur')
                      .format('HH:mm'),
                );
              } else {
                valueDay = dataMerge[0];
                valueSelectTime = { id: 'soon' };
              }

              if (valueSelectTime === undefined) {
                valueSelectTime = { id: 'soon' };
              }
              localStorage.setItem(
                SELECTED_TIME,
                JSON.stringify({
                  dataMerge,
                  valueDay,
                  valueSelectTime,
                }),
              );
              dispatch(
                setSelectedTime({
                  dataMerge,
                  valueDay,
                  valueSelectTime,
                }),
              );

              dispatch(setIsAppReady(true));
            } else {
              localStorage.removeItem(ORDER_USER);
              let crrRestaurant = restaurants.find(
                (option) => option.id === parseInt(pathNameIdBrand),
              );
              if (crrRestaurant) {
                axios
                  .get(GET_VENUES + `?brand_id=${crrRestaurant.id}`)
                  .then((resVenues) => {
                    //venues
                    if (parseInt(pathNameIdBrand) !== dataSelectRes.id) {
                      localStorage.setItem(
                        VENUES_DATA,
                        JSON.stringify(resVenues.data[0]),
                      );
                      dispatch(setSelectedVenue(resVenues.data[0]));

                      // restaurant
                      localStorage.setItem(
                        SELECTED_RESTAURANT,
                        JSON.stringify(crrRestaurant),
                      );
                      dispatch(setSelectedRestaurant(crrRestaurant));

                      dispatch(setSelectedAddress(null));
                      localStorage.removeItem(SELECTED_ADDRESS);

                      dispatch(setSelectedTime(null));
                      localStorage.removeItem(SELECTED_TIME);

                      dispatch(setCartItems([]));
                      localStorage.removeItem(CART);

                      dispatch(setOrderUserItems([]));
                      localStorage.removeItem('ACTIVE_TAB');
                      localStorage.removeItem(ORDER_USER);
                    } else {
                      let crrVenues = venues_data
                        ? venues_data
                        : resVenues.data[0];
                      localStorage.setItem(
                        VENUES_DATA,
                        JSON.stringify(crrVenues),
                      );
                      dispatch(setSelectedVenue(crrVenues));

                      // restaurant
                      localStorage.setItem(
                        SELECTED_RESTAURANT,
                        JSON.stringify(crrRestaurant),
                      );
                      dispatch(setSelectedRestaurant(crrRestaurant));

                      // addres
                      if (selectedAdd) {
                        dispatch(setSelectedAddress(selectedAdd));
                      }
                      // TIME
                      for (
                        let index = 0;
                        index < crrVenues?.operation_times?.length ?? 0;
                        index++
                      ) {
                        selectWeek({ ...crrVenues?.operation_times[index] });
                      }
                      // date now
                      let nowTime = moment()
                        .tz('Asia/Kuala_Lumpur')
                        .format('HH:mm');
                      var s = new Date(
                        moment().tz('Asia/Kuala_Lumpur').format(),
                      );

                      let [hours, minutes] = nowTime.split(':');
                      s.setHours(hours);
                      s.setMinutes(
                        parseInt(minutes) +
                          (globalSetting
                            ? globalSetting.min_advance_ordertime
                            : 0),
                      );
                      let timeUpdate = moment(s).format('HH:mm');

                      let dataOptionsSlice1 = optionsDay.slice(
                        0,
                        moment().tz('Asia/Kuala_Lumpur').day(),
                      );
                      let dataOptionsSlice2 = optionsDay.slice(
                        moment().tz('Asia/Kuala_Lumpur').day(),
                        7,
                      );
                      let dataMerge =
                        dataOptionsSlice2.concat(dataOptionsSlice1);
                      let dataFilter = dataMerge[0].dataSplit.filter(
                        (option) => option.startT > timeUpdate,
                      );

                      dataMerge[0].dataSplit = dataFilter;
                      dataMerge[0].label = 'Today';
                      dataMerge[0].value = 'today';

                      for (let index = 0; index < dataMerge.length; index++) {
                        dataMerge[index]['fullday'] = moment(new Date())
                          .add('day', index)
                          .tz('Asia/Kuala_Lumpur')
                          .format('YYYY-MM-DD');
                      }

                      let valueDay = dataMerge[0];
                      let valueSelectTime = { id: 'soon' };

                      // time
                      if (selected_time) {
                        const dataSplit =
                          selected_time.valueDay.dataSplit.length > 0 &&
                          selected_time.valueDay.dataSplit[0];
                        if (
                          dataSplit &&
                          moment(
                            new Date(selected_time.valueDay.fullday).setHours(
                              dataSplit.startT.split(':')[0],
                              dataSplit.startT.split(':')[1],
                            ),
                          )
                            .tz('Asia/Kuala_Lumpur')
                            .format() >=
                            moment(new Date()).tz('Asia/Kuala_Lumpur').format()
                        ) {
                          dispatch(setSelectedTime(selected_time));
                        } else {
                          dispatch(
                            setSelectedTime({
                              dataMerge,
                              valueDay,
                              valueSelectTime,
                            }),
                          );
                          localStorage.setItem(
                            SELECTED_TIME,
                            JSON.stringify({
                              dataMerge,
                              valueDay,
                              valueSelectTime,
                            }),
                          );
                        }
                      } else {
                        if (selectedAdd) {
                          dispatch(
                            setSelectedTime({
                              dataMerge,
                              valueDay,
                              valueSelectTime,
                            }),
                          );
                          localStorage.setItem(
                            SELECTED_TIME,
                            JSON.stringify({
                              dataMerge,
                              valueDay,
                              valueSelectTime,
                            }),
                          );
                        } else {
                          dispatch(setSelectedTime(null));
                          localStorage.removeItem(SELECTED_TIME);
                        }
                      }
                    }
                  })
                  .catch((error) => {
                    showError(error);
                  })
                  .finally(() => {
                    dispatch(setIsAppReady(true));
                  });
              } else {
                const branchId = dataSelectRes
                  ? dataSelectRes.id
                  : restaurants[0].id;
                axios
                  .get(GET_VENUES + `?brand_id=${branchId}`)
                  .then((resVenues) => {
                    //venues
                    let crrVenues = venues_data
                      ? venues_data
                      : resVenues.data[0];
                    localStorage.setItem(
                      VENUES_DATA,
                      JSON.stringify(crrVenues),
                    );
                    dispatch(setSelectedVenue(crrVenues));

                    let crrRestaurant = dataSelectRes
                      ? dataSelectRes
                      : restaurants[0];
                    localStorage.setItem(
                      SELECTED_RESTAURANT,
                      JSON.stringify(crrRestaurant),
                    );
                    dispatch(setSelectedRestaurant(crrRestaurant));

                    // addres
                    if (selectedAdd) {
                      dispatch(setSelectedAddress(selectedAdd));
                    }

                    // TIME
                    for (
                      let index = 0;
                      index < crrVenues?.operation_times?.length;
                      index++
                    ) {
                      selectWeek({ ...crrVenues?.operation_times[index] });
                    }
                    // date now
                    let nowTime = moment()
                      .tz('Asia/Kuala_Lumpur')
                      .format('HH:mm');
                    var s = new Date(moment().tz('Asia/Kuala_Lumpur').format());

                    let [hours, minutes] = nowTime.split(':');
                    s.setHours(hours);
                    s.setMinutes(
                      parseInt(minutes) +
                        (globalSetting
                          ? globalSetting.min_advance_ordertime
                          : 0),
                    );
                    let timeUpdate = moment(s).format('HH:mm');

                    let dataOptionsSlice1 = optionsDay.slice(
                      0,
                      moment().tz('Asia/Kuala_Lumpur').day(),
                    );
                    let dataOptionsSlice2 = optionsDay.slice(
                      moment().tz('Asia/Kuala_Lumpur').day(),
                      7,
                    );
                    let dataMerge = dataOptionsSlice2.concat(dataOptionsSlice1);
                    let dataFilter = dataMerge[0].dataSplit.filter(
                      (option) => option.startT > timeUpdate,
                    );

                    dataMerge[0].dataSplit = dataFilter;
                    dataMerge[0].label = 'Today';
                    dataMerge[0].value = 'today';

                    for (let index = 0; index < dataMerge.length; index++) {
                      dataMerge[index]['fullday'] = moment(new Date())
                        .add('day', index)
                        .tz('Asia/Kuala_Lumpur')
                        .format('YYYY-MM-DD');
                    }

                    let valueDay = dataMerge[0];
                    let valueSelectTime = { id: 'soon' };

                    // time
                    if (selected_time) {
                      const dataSplit =
                        selected_time.valueDay.dataSplit.length > 0 &&
                        selected_time.valueDay.dataSplit[0];
                      if (
                        dataSplit &&
                        moment(
                          new Date(selected_time.valueDay.fullday).setHours(
                            dataSplit.startT.split(':')[0],
                            dataSplit.startT.split(':')[1],
                          ),
                        )
                          .tz('Asia/Kuala_Lumpur')
                          .format() >=
                          moment(new Date()).tz('Asia/Kuala_Lumpur').format()
                      ) {
                        dispatch(setSelectedTime(selected_time));
                      } else {
                        dispatch(
                          setSelectedTime({
                            dataMerge,
                            valueDay,
                            valueSelectTime,
                          }),
                        );
                        localStorage.setItem(
                          SELECTED_TIME,
                          JSON.stringify({
                            dataMerge,
                            valueDay,
                            valueSelectTime,
                          }),
                        );
                      }
                    } else {
                      dispatch(
                        setSelectedTime({
                          dataMerge,
                          valueDay,
                          valueSelectTime,
                        }),
                      );
                      localStorage.setItem(
                        SELECTED_TIME,
                        JSON.stringify({
                          dataMerge,
                          valueDay,
                          valueSelectTime,
                        }),
                      );
                    }
                  })
                  .catch((error) => {
                    showError(error);
                  })
                  .finally(() => {
                    dispatch(setIsAppReady(true));
                  });
              }
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        if (parseInt(pathNameIdBrand)) {
          let crrRestaurant = restaurants.find(
            (option) => option.id === parseInt(pathNameIdBrand),
          );
          if (crrRestaurant) {
            axios
              .get(GET_VENUES + `?brand_id=${crrRestaurant.id}`)
              .then((resVenues) => {
                //venues
                let crrVenues = resVenues.data[0];
                localStorage.setItem(VENUES_DATA, JSON.stringify(crrVenues));
                dispatch(setSelectedVenue(crrVenues));

                localStorage.setItem(
                  SELECTED_RESTAURANT,
                  JSON.stringify(crrRestaurant),
                );
                dispatch(setSelectedRestaurant(crrRestaurant));

                // addres
                if (selectedAdd) {
                  dispatch(setSelectedAddress(selectedAdd));
                }

                // time
                if (selected_time) {
                  dispatch(setSelectedTime(selected_time));
                }
              })
              .catch((error) => {
                showError(error);
              })
              .finally(() => {
                dispatch(setIsAppReady(true));
              });
          } else {
            axios
              .get(GET_VENUES + `?brand_id=${restaurants[0].id}`)
              .then((resVenues) => {
                //venues
                let crrVenues = venues_data ? venues_data : resVenues.data[0];
                localStorage.setItem(VENUES_DATA, JSON.stringify(crrVenues));
                dispatch(setSelectedVenue(crrVenues));

                // restaurant
                let crrRestaurant = dataSelectRes
                  ? dataSelectRes
                  : restaurants[0];
                localStorage.setItem(
                  SELECTED_RESTAURANT,
                  JSON.stringify(crrRestaurant),
                );
                dispatch(setSelectedRestaurant(crrRestaurant));

                // addres
                if (selectedAdd) {
                  dispatch(setSelectedAddress(selectedAdd));
                }

                // time
                if (selected_time) {
                  dispatch(setSelectedTime(selected_time));
                }
              })
              .catch((error) => {
                showError(error);
              })
              .finally(() => {
                dispatch(setIsAppReady(true));
              });
          }
        } else {
          axios
            .get(GET_VENUES + `?brand_id=${restaurants[0].id}`)
            .then((resVenues) => {
              //venues
              let crrVenues = venues_data ? venues_data : resVenues.data[0];
              localStorage.setItem(VENUES_DATA, JSON.stringify(crrVenues));
              dispatch(setSelectedVenue(crrVenues));

              // restaurant
              let crrRestaurant = dataSelectRes
                ? dataSelectRes
                : restaurants[0];
              localStorage.setItem(
                SELECTED_RESTAURANT,
                JSON.stringify(crrRestaurant),
              );
              dispatch(setSelectedRestaurant(crrRestaurant));

              // addres
              if (selectedAdd) {
                dispatch(setSelectedAddress(selectedAdd));
              }

              // time
              if (selected_time) {
                dispatch(setSelectedTime(selected_time));
              }
            })
            .catch((error) => {
              showError(error);
            })
            .finally(() => {
              dispatch(setIsAppReady(true));
            });
        }
      }
    });
    let isNotFirstLoad = localStorage.getItem('APP__IS_NOT_FIRST_LOAD');
    if (!isNotFirstLoad) {
      dispatch(setTooltipOpen(true));
      setTimeout(() => {
        dispatch(setTooltipOpen(false));
      }, 3000);
    }
    localStorage.setItem('APP__IS_NOT_FIRST_LOAD', true);

    dispatch(setUserLogin(userLogin));
  }, [dispatch, globalSetting, selectWeek, optionsDay]);

  const handleCancel = (props) => {
    if (props.checkSave) {
      setCheckSaveLocation(props.checkSave);
    }
    toggleDropdown('location', props.checkSave);
    dispatch(setAddAdress(!addAddress));
  };

  useEffect(() => {
    let length = 0;
    for (let index = 0; index < orderUserItems.length; index++) {
      length = length + orderUserItems[index].quantity;
    }
    setLengthOrder(length);
  }, [orderUserItems]);

  return (
    <>
      <Navbar
        className="navbar-main navbar-light fixed-top"
        expand="lg"
        id="navbar-main"
      >
        <Container>
          <NavbarBrand
            className="mr-lg-5"
            id="TooltipExample"
            onClick={() => dispatch(setPickRestaurantModal(true))}
          >
            <img
              alt="The Social"
              src={
                selectedRestaurant
                  ? selectedRestaurant.logo_url
                  : require('assets/img/brand/the-social-logo-dark.svg')
              }
            />
            <span />
            <i className="fas fa-chevron-down" />
          </NavbarBrand>

          <Tooltip
            id="top-tooltip"
            placement="bottom"
            isOpen={tooltipOpen}
            target="TooltipExample"
            toggle={() => dispatch(setTooltipOpen(!tooltipOpen))}
          >
            <div className="top-tooltip_content">
              <img
                alt="The Social"
                src={require('assets/img/icons/common/info.svg')}
              />
              <div>
                <p>TAP HERE TO CHANGE THE</p>
                <p>RESTAURANT AT ANY TIME</p>
              </div>
            </div>
          </Tooltip>
          <div>
            {isAppReady ? (
              <>
                {!userLogin ? (
                  <Nav className="right_nav _beforeLogin" navbar>
                    <NavItem className="">
                      <Button
                        color="primary"
                        onClick={() =>
                          dispatch(
                            setSignInModal({
                              status: true,
                              type: SIGN_IN_TYPE.SIGN_UP,
                            }),
                          )
                        }
                      >
                        Create Account
                      </Button>
                    </NavItem>
                    <NavItem className="sign_in_btn">
                      <Button
                        color="default"
                        onClick={() =>
                          dispatch(
                            setSignInModal({
                              status: true,
                              type: SIGN_IN_TYPE.SIGN_IN,
                            }),
                          )
                        }
                      >
                        Sign in
                      </Button>
                    </NavItem>
                  </Nav>
                ) : (
                  <Nav className="right_nav _afterLogin" navbar>
                    <NavItem className="">
                      <Dropdown
                        className="d-none d-lg-block"
                        isOpen={cartDropdownOpen}
                        toggle={() => toggleDropdown('cart')}
                      >
                        <DropdownToggle className="btn-cart">
                          <i className="ni ni-bag-17" />
                        </DropdownToggle>
                        {lengthOrder > 0 ? (
                          <div className="cart-icon__number">{lengthOrder}</div>
                        ) : (
                          ''
                        )}
                        <DropdownMenu className="dialog_lv1 arrow_right">
                          <CartDialog onToggle={() => toggleDropdown('cart')} />
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        color="default"
                        className="d-lg-none btn-cart btn-cart_custom"
                        type="button"
                        onClick={() => {
                          dispatch(setCartModal(true));
                        }}
                      >
                        <i className="ni ni-bag-17" />
                        {lengthOrder > 0 ? (
                          <div className="cart-icon__number">{lengthOrder}</div>
                        ) : (
                          ''
                        )}
                      </Button>
                    </NavItem>
                    <NavItem>
                      <Dropdown
                        className="d-none d-lg-block"
                        isOpen={profileDropdownOpen}
                        toggle={() => toggleDropdown('profile')}
                      >
                        <DropdownToggle className="_avatar btn-cart">
                          <img
                            src={logoUser ? logoUser : 'favicon.ico'}
                            alt="user avatar"
                            style={{ borderRadius: 50 }}
                          />
                        </DropdownToggle>
                        <DropdownMenu className="dialog_lv1 arrow_right">
                          <ProfileDialog
                            onToggle={() => toggleDropdown('profile')}
                          />
                        </DropdownMenu>
                      </Dropdown>
                      <Button
                        color="default"
                        className="d-lg-none _btn_avatar"
                        type="button"
                        onClick={() => {
                          dispatch(setProfileModal(true));
                        }}
                      >
                        <img
                          src={logoUser ? logoUser : 'favicon.ico'}
                          alt="user avatar"
                          style={{ borderRadius: 50 }}
                        />
                      </Button>
                    </NavItem>
                  </Nav>
                )}
              </>
            ) : (
              <Nav className="right_nav">
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 41, color: '#E1AC6F' }}
                      spin
                    />
                  }
                />
              </Nav>
            )}
          </div>
        </Container>
        {
          userLogin ? (
            <Container>
              <div className="secondary_nav">
                <div>
                  <div
                    id="custom-overlay"
                    className={`${classNames({
                      'd-none': !customOverlay,
                    })} display_none_desktop`}
                  />
                  <AntDropdown
                    motion={null}
                    overlayClassName="delivery-location-dropdown"
                    overlay={
                      <Menu>
                        {
                          showPopupAddress &&
                          (<DeliveryLocation
                            onToggle={() => toggleDropdown('location')}
                            saveLocation={checkSaveLocation}
                          />)
                        }
                      </Menu>
                    }
                    visible={showPopupAddress}
                    trigger={['click']}
                    onVisibleChange={() => {
                      if (userLogin) {
                        toggleDropdown('location')
                      } else {
                        dispatch(
                          setSignInModal({
                            status: true,
                            type: SIGN_IN_TYPE.SIGN_IN,
                          }),
                        );
                      }
                    }}
                  >
                    <Button onClick={() => {
                      toggleDropdown('location')
                    }}>
                        <i className="fas fa-map-marker-alt" />{' '}
                        <span className="text_address">
                          {selectedAddress ? selectedAddress.name : `Select address `}
                        </span>
                        <i className="fas fa-chevron-down" />
                    </Button>

                  </AntDropdown>
                  {addAddress && (
                    <Modal
                      title="ADD ADDRESS"
                      className="modalAddress popup-dropdown popup_delivery"
                      centered
                      onCancel={handleCancel}
                      visible={addAddress}
                      footer={null}
                    >
                      <DeliverySaveLocation
                        onSaveLocation={(props) => handleCancel(props)}
                      />
                    </Modal>
                  )}

                  <Dropdown
                    isOpen={showPopupTime}
                    toggle={() => {
                      if (userLogin) {
                        toggleDropdown('time');
                      } else {
                        dispatch(
                          setSignInModal({
                            status: true,
                            type: SIGN_IN_TYPE.SIGN_IN,
                          }),
                        );
                      }
                    }}
                  >
                    <DropdownToggle>
                      <i className="far fa-clock" />{' '}
                      <span className="text_address">
                        {selectedTime &&
                        selectedTime?.valueDay?.dataSplit.length > 0
                          ? selectedTime.valueSelectTime.id === 'soon'
                            ? 'TODAY - AS SOON AS POSSIBLE'
                            : `${selectedTime.valueDay.label} at ${selectedTime.valueSelectTime.startT} - ${selectedTime.valueSelectTime.endT}`
                          : `Select time `}
                      </span>
                      <i className="fas fa-chevron-down" />
                    </DropdownToggle>
                    <DropdownMenu className="arrow_left dialog_lv2">
                      {showPopupTime && (
                        <DeliveryTime onToggle={() => toggleDropdown('time')} />
                      )}
                    </DropdownMenu>
                  </Dropdown>
                </div>
                <div>
                  {/* <Button
                    color="default"
                    className={classnames("btn-menu", {
                      active: selectedMenu === "main",
                    })}
                    type="button"
                    onClick={() => gotoMain()}
                  >
                    MAIN
                  </Button> */}
                  {/* <Button
                    color="default"
                    className={classnames("btn-menu", {
                      active: selectedMenu === "meal-plans",
                    })}
                    type="button"
                    onClick={() => gotoMealPlans()}
                  >
                    MEAL PLANS
                  </Button> */}
                </div>
              </div>
            </Container>
          ) : (
            <div style={{ marginTop: '12px' }} />
          )
        }
      </Navbar>
    </>
  );
};

export default MainNavbar;
