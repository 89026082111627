import {
  SET_CART_MODAL,
  SET_STARTED_MODAL,
  SET_SIGN_IN_MODAL,
  SET_CUSTOM_OVERLAY,
  SET_PICK_RESTAURANT_MODAL,
  SET_TOOLTIP_OPEN,
  SET_BODY_4_DETAIL,
  SET_NOT_LOGGED_IN_MODAL,
  SET_PROFILE_MODAL,
  SET_ADD_ADDRESS,
  SET_SHOW_TERMS,
  SET_REFERRAL,
} from '../_constants/actionType';

export function setStartedModal(startedModal) {
  return {
    type: SET_STARTED_MODAL,
    startedModal,
  };
}

export function setSignInModal(signInModal) {
  return {
    type: SET_SIGN_IN_MODAL,
    signInModal,
  };
}

export function setCustomOverlay(customOverlay) {
  return {
    type: SET_CUSTOM_OVERLAY,
    customOverlay,
  };
}

export function setPickRestaurantModal(pickRestaurantModal) {
  return {
    type: SET_PICK_RESTAURANT_MODAL,
    pickRestaurantModal,
  };
}

export function setNotLoggedInModal(notLoggedInModal) {
  return {
    type: SET_NOT_LOGGED_IN_MODAL,
    notLoggedInModal,
  };
}

export function setProfileModal(profileModal) {
  return {
    type: SET_PROFILE_MODAL,
    profileModal,
  };
}

export function setCartModal(cartModal) {
  return {
    type: SET_CART_MODAL,
    cartModal,
  };
}

export function setTooltipOpen(tooltipOpen) {
  return {
    type: SET_TOOLTIP_OPEN,
    tooltipOpen,
  };
}

export function setBody4Detail(body4detail) {
  return {
    type: SET_BODY_4_DETAIL,
    body4detail,
  };
}

export function setAddAdress(addAddress) {
  return {
    type: SET_ADD_ADDRESS,
    addAddress,
  };
}

export function setShowTerms(showTerms) {
  return {
    type: SET_SHOW_TERMS,
    showTerms,
  };
}

export function setReferral(referral) {
  return {
    type: SET_REFERRAL,
    referral,
  };
}
