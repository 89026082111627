//Effect
export const SET_STARTED_MODAL = 'SET_STARTED_MODAL';
export const SET_SIGN_IN_MODAL = 'SET_SIGN_IN_MODAL';
export const SET_CUSTOM_OVERLAY = 'SET_CUSTOM_OVERLAY';
export const SET_PICK_RESTAURANT_MODAL = 'SET_PICK_RESTAURANT_MODAL';
export const SET_NOT_LOGGED_IN_MODAL = 'SET_NOT_LOGGED_IN_MODAL';
export const SET_TOOLTIP_OPEN = 'SET_TOOLTIP_OPEN';
export const SET_BODY_4_DETAIL = 'SET_BODY_4_DETAIL';
export const SET_PROFILE_MODAL = 'SET_PROFILE_MODAL';
export const SET_CART_MODAL = 'SET_CART_MODAL';
export const SET_ADD_ADDRESS = 'SET_ADD_ADDRESS';
export const SET_SHOW_TERMS = 'SET_SHOW_TERMS';
export const SET_REFERRAL = 'SET_REFERRAL';

//Authen
export const SET_LOGGED_IN = 'SET_LOGGED_IN';
export const SET_USER_LOGIN = 'SET_USER_LOGIN';

//Other
export const SET_APP_READY = 'SET_APP_READY';
export const SET_IS_MOBILE = 'SET_IS_MOBILE';
export const SET_BANNERS = 'SET_BANNERS';

export const SET_SHOW_POPUP_ADDRESS = 'SET_SHOW_POPUP_ADDRESS';
export const SET_SHOW_POPUP_TIME = 'SET_SHOW_POPUP_TIME';
export const SET_ORDER_USER_ITEMS = 'SET_ORDER_USER_ITEMS';

export const SET_SELECTED_RESTAURANT = 'SET_SELECTED_RESTAURANT';
export const SET_SELECTED_ADDRESS = 'SET_SELECTED_ADDRESS';
export const SET_SELECTED_TIME = 'SET_SELECTED_TIME';
export const SET_SELECTED_VENUE = 'SET_SELECTED_VENUE';
export const SET_DELIVERY_VENUES = 'SET_DELIVERY_VENUES';
export const SET_RESTAURANTS_LIST = 'SET_RESTAURANTS_LIST';
export const SET_SELECTED_MENU = 'SET_SELECTED_MENU';

export const SET_LNG_LAT = 'SET_LNG_LAT';

export const SET_SELECTED_MAIN_MEAL = 'SET_SELECTED_MAIN_MEAL';
export const SET_MAIN_MEALS_LIST = 'SET_MAIN_MEALS_LIST';
export const SET_TAGS = 'SET_TAGS';

export const SET_CART_ITEM = 'SET_CART_ITEM';

export const SET_GLOBAL_SETTING = 'SET_GLOBAL_SETTING';
export const SET_STRIPE_KEY = 'SET_STRIPE_KEY';
// Order Detail
export const SET_SHOW_DETAIL = 'SET_SHOW_DETAIL';
export const SET_SELECTED_ORDER = 'SET_SELECTED_ORDER';
export const SET_PROCESSING_PAYMENT = 'SET_PROCESSING_PAYMENT';
