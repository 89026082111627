import { SET_CART_ITEM } from '../_constants/actionType';
import { CART } from '../_constants/index';
import _ from 'lodash';
import { readAndParseStorage } from '../_helper';

const cartObjDefault = {
  restaurant: null,
  deliveryLocation: {
    latitude: null,
    longitude: null,
  },
  deliveryTime: new Date(),
  items: [],
  total: 0,
};
const cartObj = readAndParseStorage(CART) || cartObjDefault;
const initialState = {
  cart: cartObj,
};
export const cart = (state = initialState, action) => {
  switch (action.type) {
    case SET_CART_ITEM:
      const cartTemp = {
        ...state.cart,
        total: _.sumBy(action.items, 'quantity'),
        items: action.items,
      };
      localStorage.setItem(CART, JSON.stringify(cartTemp));
      return {
        ...state,
        cart: cartTemp,
      };
    default:
      return state;
  }
};
