import { CMS_API, REWARD_API } from '../_constants';

export const GET_ALL_MAIN_MEALS = `${CMS_API}/api/mobile/v1/categories`;
export const GET_ALL_TAGS = `${CMS_API}/api/mobile/v1/tags`;
export const GET_RESTAURANTS = `${CMS_API}/api/mobile/v1/brands`;
export const GET_BANNERS = `${CMS_API}/api/banners`;
export const GET_VENUES = `${CMS_API}/api/mobile/v1/venues`;
export const POST_VENUES_PICKUP = `${CMS_API}/api/mobile/v1/venues/pickup`;
export const POST_ADDRESSES = `${CMS_API}/api/mobile/v1/addresses`;
export const GET_ADDRESSES = `${CMS_API}/api/mobile/v1/addresses`;
export const GET_MAIN_MEAL_BY_ID = `${CMS_API}/api/mobile/v1/products`;
export const GET_ALL_MEAL_BY_CATEGORY_ID = `${CMS_API}/api/mobile/v1/products?category_id=`;
export const GET_ORDERS = `${CMS_API}/api/mobile/v1/orders/`;
export const GET_GLOBAL_SETTING = `${CMS_API}/api/mobile/v1/global_settings`;
export const GET_STRIPE_KEY = `${CMS_API}/api/mobile/v1/checkout/keys`;
export const GET_PAYMENT_INTENT = `${CMS_API}/api/mobile/v1/checkout/stripe_payment_intent?order_id=`;
export const GET_PAYMENT_INTENT_STATUS = `${CMS_API}/api/mobile/v1/checkout/stripe_payment_intent/status?order_id=`;
// Login
export const POST_LOGIN = `${REWARD_API}/mobile_api/v2/sign_in`;
export const POST_SIGNUP = `${REWARD_API}/mobile_api/v2/sign_up`;
export const VERIFY_LOGIN = `${REWARD_API}/mobile_api/v2/verify_login`;
export const VERIFY = `${REWARD_API}/mobile_api/v2/verify`;
export const RESEND_CODE = `${REWARD_API}/mobile_api/v2/re_send`;
export const GET_BRAND_USER = `${REWARD_API}/mobile_api/v2/brand_users`;
export const GET_VOUCHERS = `${REWARD_API}/mobile_api/v2/brand_vouchers`;
export const GET_PROMO_CODE = `${REWARD_API}/mobile_api/v2/brand_promotion_codes`;
export const GET_TERMS = `${REWARD_API}/mobile/terms_of_use`;
