import React, { useEffect, useRef } from 'react';
import {
  Row,
  Col,
  Card,
  CardImg,
  CardBody,
  Container,
  Button,
} from 'reactstrap';

// core components
import { useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import { setSelectedMenu } from '../../_actions/global.action';
import Slider from 'react-slick';

const options1 = [
  { value: 'TODAY', label: 'TODAY' },
  { value: 'TOMORROW', label: 'TOMORROW' },
  { value: 'FRIDAY, 17 JUL ', label: 'FRIDAY, 17 JUL ' },
  { value: 'SATURDAY, 18 JUL ', label: 'SATURDAY, 18 JUL ' },
  { value: 'SUNDAY, 19 JUL', label: 'SUNDAY, 19 JUL' },
  { value: 'MONDAY, 20 JUL ', label: 'MONDAY, 20 JUL ' },
  { value: 'TUESDAY, 21 JUL ', label: 'TUESDAY, 21 JUL' },
  { value: 'WEDNESDAY, 22 JUL', label: 'WEDNESDAY, 22 JUL' },
  { value: 'THURSDAY, 23 JUL', label: 'THURSDAY, 23 JUL' },
  { value: 'FRIDAY, 24 JUL', label: 'FRIDAY, 24 JUL' },
];
const settings = {
  className: 'center',
  dots: false,
  arrows: false,
  infinite: false,
  variableWidth: true,
  swipeToSlide: true,
};

const MealPlansDetailLayout = () => {
  const customOverlay = useSelector((state) => state.effect.customOverlay);
  const mainEl = useRef(null);
  const dispatch = useDispatch();
  const userLogin = useSelector((state) => state.authentication.userLogin);

  useEffect(() => {
    dispatch(setSelectedMenu('meal-plans'));
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainEl.current.scrollTop = 0;
  });
  return (
    <>
      <main ref={mainEl}>
        <div className="position-relative">
          <section className={classNames({ 'section': true, 'header_section': true, 'is_logged_in': userLogin})}>
            <div
              id="custom-overlay"
              className={classNames({ 'd-none': !customOverlay })}
            />
            <Container fluid className="p-0">
              <div className="body4detail meal-plans _details">
                <div className="body4Detail__header">
                  <div className="_header">
                    <h1>TODAY’S MEALS</h1>
                    <p>THE SOCIAL — 163 RETAIL PARK</p>
                  </div>
                </div>
                <Container className="body4Detail__body">
                  <Container className="banner-detail">
                    <Row>
                      <Col md="4" className="banner-detail_more_deals">
                        <div className="list">
                          <h2 className="banner-detail_more_deals_title">
                            SCHEDULE YOUR MEALS
                          </h2>
                          <div className="banner-detail_more_deals__list">
                            {options1.map((item, index) => {
                              return (
                                <div
                                  className="custom_control_item_type"
                                  key={index}
                                >
                                  <div className="custom-control custom-radio ">
                                    <input
                                      className="custom-control-input"
                                      id={'radio-meal-plans' + index}
                                      value={'radio-meal-plans' + index}
                                      name="radio-time"
                                      type="radio"
                                    />
                                    <label
                                      className="custom-control-label"
                                      htmlFor={'radio-meal-plans' + index}
                                    >
                                      <span>{item.label}</span>
                                    </label>
                                  </div>
                                </div>
                              );
                            })}
                          </div>
                        </div>
                      </Col>

                      <Col md="8" className="banner-detail_more_deals">
                        <div className="list">
                          <div className="_sliders">
                            <div className="_slider">
                              <h2>BREAKFAST</h2>
                              <p>8 AM - 11 AM</p>
                              <Slider {...settings}>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        CRISP SKIN SALMON TROUT
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        BUTTERMILK CHICKEN BURGER
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        BUTTERMILK CHICKEN BURGER
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        BUTTERMILK CHICKEN BURGER
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                              </Slider>
                            </div>

                            <div className="_slider">
                              <h2>LUNCH</h2>
                              <p>11 AM - 5 PM</p>
                              <Slider {...settings}>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        CRISP SKIN SALMON TROUT
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        BUTTERMILK CHICKEN BURGER
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        BUTTERMILK CHICKEN BURGER
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                                <div className="__food-meal-plans">
                                  <Card>
                                    <CardImg
                                      className="img-fluid"
                                      src="/img/meal-plans/food1.jpg"
                                      alt="Food"
                                    />
                                    <Button
                                      className="_plus"
                                      color="primary"
                                      type="button"
                                    >
                                      <i className="fas fa-plus" />
                                    </Button>
                                    <CardBody>
                                      <div className="food_name">
                                        BUTTERMILK CHICKEN BURGER
                                      </div>
                                    </CardBody>
                                  </Card>
                                </div>
                              </Slider>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Container>
              </div>
            </Container>
          </section>
        </div>
      </main>
    </>
  );
};

export default MealPlansDetailLayout;
