import React from 'react';
import { notification } from 'antd';
import axios from '../../api/CustomAxios';
import { GET_ORDERS } from '../../api/APIs';
import { Button } from 'reactstrap';
import CheckTime from './checkTime';

function Notification(data, titleStatus, onClickDisp) {
  const idOrder = data.id;
  const key = `open_${idOrder}`;

  let time = CheckTime(data);

  const btn = (
    <Button
      className="text-primary _link pl-0 pb-0"
      color="link"
      onClick={() => {
        axios
          .get(`${GET_ORDERS}${idOrder}`)
          .then((res) => {
            let data = res.data;
            data.timeDetail = time;
            onClickDisp(data);
          })
          .catch((error) => {
            console.log(error);
          });
      }}
    >
      VIEW DETAILS
    </Button>
  );
  const closeIcon = (
    <Button className="" color="default" type="button">
      <i className="fas fa-times" />
    </Button>
  );

  notification.open({
    message: time,
    description: titleStatus + ` - ${data.order_no}`,
    btn,
    key,
    placement: 'bottomLeft',
    duration: null,
    closeIcon,
  });
  return '';
}

export default Notification;
