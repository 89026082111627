import React from 'react';
import {
  Row,
  Col,
  Container,
  Button,
  InputGroup,
  Input,
  InputGroupAddon,
  InputGroupText,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';

const BannerDetail = () => {
  const history = useHistory();

  return (
    <>
      <Container className="banner-detail">
        <Row>
          <Col md="7" className="p-0">
            <div className="banner-detail_header">
              <img
                className="img-fluid"
                src="/img/banner/banner1.jpg"
                alt="111"
              />
              <h3>THE SOCIAL GROUP OF RESTAURANTS</h3>
              <h2>SWEET TREAT @ THE SOCIAL,EL MESÓN & LISETTES</h2>
              <Button
                className="close_detail_btn"
                color="default"
                type="button"
                onClick={() => history.goBack()}
              >
                <i className="fas fa-times"></i>
              </Button>
            </div>
            <div className="banner-detail_content">
              Lorem ipsum dolor sit amet, consectetur adipiscing elit.
              Vestibulum ultricies in libero ullamcorper rhoncus. Sed vel sem
              sed dui auctor viverra ac eu arcu. Aenean sed nisi neque. Nulla in
              fermentum tortor. Sed efficitur, nisi bibendum congue commodo,
              lorem arcu imperdiet eros, vitae ultricies orci massa id urna.
              Nunc lacinia dolor ut sem maximus, et ultricies augue pharetra.
              Praesent diam nibh, aliquet nec lobortis id, volutpat id enim. Sed
              placerat eget mi sit amet imperdiet. Ut mollis neque nunc, a
              cursus nibh molestie id. Lorem ipsum dolor sit amet, consectetur
              adipiscing elit. Proin cursus lectus turpis, at lobortis velit
              ultricies non. Aenean rutrum vel sem at tristique. Sed velit
              velit, ultricies vel turpis in, elementum sodales mauris. Praesent
              quam nibh, pellentesque eu porta vel. Lorem ipsum dolor sit amet,
              consectetur adipiscing elit. Vestibulum ultricies in libero
              ullamcorper rhoncus. Sed vel sem sed dui auctor viverra ac eu
              arcu. Aenean sed nisi neque. Nulla in fermentum tortor. Sed
              efficitur, nisi bibendum congue commodo, lorem arcu imperdiet
              eros, vitae ultricies orci massa id urna. Nunc lacinia dolor ut
              sem maximus, et ultricies augue pharetra. Praesent diam nibh,
              aliquet nec lobortis id, volutpat id enim. Sed placerat eget mi
              sit amet imperdiet. Ut mollis neque nunc, a cursus nibh molestie
              id. Lorem ipsum dolor sit amet, consectetur adipiscing elit. Proin
              cursus lectus turpis, at lobortis velit ultricies non. Aenean
              rutrum vel sem at tristique. Sed velit velit, ultricies vel turpis
              in, elementum sodales mauris. Praesent quam nibh, pellentesque eu
              porta vel.
            </div>
          </Col>

          <Col md="5" className="banner-detail_more_deals">
            <div className="list">
              <h2 className="banner-detail_more_deals_title">
                MORE NEWS & DEALS
              </h2>
              <div className="banner-detail_more_deals__list">
                <div className="restaurant__btn">
                  <img alt="..." src="/img/restaurant/1.svg" />
                  <div className="pick_restaurant__text">
                    <div className="custom__btn_name">
                      <h2>1 FOR YOUR DAD 1 FOR YOU</h2>
                      <span>LIMITED TIME DEAL</span>
                    </div>
                    <div>
                      <i className="fas fa-angle-right"></i>
                    </div>
                  </div>
                </div>

                <div className="restaurant__btn">
                  <img alt="..." src="/img/restaurant/1.svg" />
                  <div className="pick_restaurant__text">
                    <div className="custom__btn_name">
                      <h2>1 FOR YOUR DAD 1 FOR YOU</h2>
                      <span>LIMITED TIME DEAL</span>
                    </div>
                    <div>
                      <i className="fas fa-angle-right"></i>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <Button
                  className="text-primary text-center"
                  color="link"
                  href="#pablo"
                  onClick={(e) => e.preventDefault()}
                >
                  VIEW ALL
                </Button>
              </div>
            </div>
            <div className="subscribe">
              <h2>SUBSCRIBE TO OUR NEWSLETTER</h2>
              <h3>we won’t spam your inbox, we promise!</h3>
              <InputGroup>
                <Input placeholder="your email" type="email" />
                <InputGroupAddon addonType="append">
                  <InputGroupText className="text-primary sign-in-btn">
                    SUBSCRIBE
                  </InputGroupText>
                </InputGroupAddon>
              </InputGroup>
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};
export default BannerDetail;
