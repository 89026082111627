import React from 'react';

const FacebookDataDeletionInstructions = () => {
  return (
    <div className={'fb-deletion-instruction'}>
      <h2>Facebook Data Deletion Instructions</h2>
      <p><b>Social Rewards</b> does not save your Facebook personal data on its server.</p>

      <p>However, according to Facebook policy, we have to provide User Data Deletion Callback URL or Data Deletion Instructions URL.</p>

      <p>If you want to delete your data from <b>Social Rewards</b> App, you can remove your information by following these steps:</p>

      <p>1. Go to your Facebook Account’s Setting & Privacy. Click “Settings”</p>

      <p>2. Look for “Apps and Websites” and you will see all of the apps and websites you linked with your Facebook.</p>

      <p>3. Search and Click “<b>Social Rewards</b>” in the search bar.</p>

      <p>4. Scroll and click “Remove”.</p>

      <p>5. Congratulations, you have successfully removed your app activities and data from the <b>Social Rewards</b> App</p>
    </div>
  )
};

export default FacebookDataDeletionInstructions
