import React, { useEffect, useState } from 'react';
import {Button, Modal, Radio, Space} from 'antd'
import {Col, Row} from "reactstrap";
import { DownOutlined } from '@ant-design/icons';

const DeliveryVenueSelect = (props) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [selectedVenue, setSelectedVenue] = useState(null)

  useEffect(() => {
    if (!modalVisible) {
      const venue = props.venues?.find((it) => it.id === props.selectedVenueId) ?? null
      setSelectedVenue(venue)
    }
  }, [props.venues, props.selectedVenueId, modalVisible])

  const onChangeVenue = (e) => {
    const id = e.target.valueChecked
    const venue = props.venues?.find((it) => it.id === id)
    setSelectedVenue(venue)
  }

  const handleUpdateSelectedVenue = () => {
    if (props.onChange) {
      props.onChange(selectedVenue?.id)
    }
    setModalVisible(false)
  }

  return (
    <>
      <label>
        Closest Venue
      </label>
      <Button
        block
        size="large"
        className="delivery-venue-select__btn"
        onClick={() => setModalVisible(true)}
      >
        <span>
          { selectedVenue?.name }
        </span>
        <span style={{marginLeft: '8px'}}>
          ({ selectedVenue?.distance }KM)
        </span>
        <DownOutlined style={{float: 'right'}}/>
      </Button>
      <Modal
        className="delivery-venue-select__modal"
        centered
        footer={null}
        title="SELECT CLOSEST VENUE"
        visible={modalVisible}
        closable
        onCancel={() => { setModalVisible(false) }}
      >
        <Radio.Group
          className="col-12 radio_pick_up p-0"
          onChange={onChangeVenue}
          value={selectedVenue?.id}
        >
          <Space direction="vertical" className="col-12 p-0">
            {props.venues &&
            props.venues.map((value) => {
              return (
                <Radio
                  value={value.id}
                  valueChecked={value.id}
                  className="col-12 pb-2 pl-0 pr-0 m-0"
                  key={value.id}
                >
                  <Row className="m-0">
                    <Col xs="8" className="pl-0 pr-1">
                      <b>{value.name}</b>
                      <div className="detail_location text_over_ellip">
                        {value.address}
                      </div>
                    </Col>
                    <Col
                      xs="4"
                      className="pr-0 pl-0 text-right range"
                    >
                      <b>{value.distance}KM</b>
                    </Col>
                  </Row>
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
        <Button
          block
          type="button"
          color="primary"
          size="large"
          className="update-delivery-venue-btn"
          style={{marginTop: '16px'}}
          onClick={handleUpdateSelectedVenue}
        >
          UPDATE
        </Button>
      </Modal>
    </>
  )
};

export default DeliveryVenueSelect;
